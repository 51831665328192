import React, { useEffect, useState, useCallback, useRef } from "react";
import { auth, db } from "./firebase";
import { ref, push, set, update, remove, get, query, orderByChild, equalTo } from "firebase/database";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./LocationPage.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Set default Leaflet icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the current user's ID

function LocationPage() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [shareableUrl, setShareableUrl] = useState(null);
  const [locationId, setLocationId] = useState(localStorage.getItem("locationId"));
  const [countdown, setCountdown] = useState(null);
  const countdownInterval = useRef(null);

  const clearLocalStorage = () => {
    console.log("Clearing localStorage for locationId and expirationTime...");
    localStorage.removeItem("locationId");
    localStorage.removeItem("expirationTime");
    console.log("localStorage after clearing:", {
      locationId: localStorage.getItem("locationId"),
      expirationTime: localStorage.getItem("expirationTime"),
    });
  };

  const updateLocationInDatabase = useCallback(
    (position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });

      if (locationId) {
        const locationRef = ref(db, `user_locations/${locationId}`);
        update(locationRef, {
          latitude,
          longitude,
          timestamp: Date.now(),
          userId: userId,
        });
      }
    },
    [locationId]
  );

  useEffect(() => {
    const initializeLocationInDatabase = async (latitude, longitude) => {
      try {
        if (!locationId) {
          const expirationTime = Date.now() + 30 * 60 * 1000;
          const newLocationRef = push(ref(db, "user_locations"));
          await set(newLocationRef, {
            latitude,
            longitude,
            timestamp: Date.now(),
            expirationTime,
            userId: userId,
          });

          const locId = newLocationRef.key;
          clearLocalStorage();
          setLocationId(locId);
          localStorage.setItem("locationId", locId);
          localStorage.setItem("expirationTime", expirationTime);
          setShareableUrl(`${window.location.origin}/location/${locId}`);
          startCountdown(expirationTime);
        } else {
          setShareableUrl(`${window.location.origin}/location/${locationId}`);
          navigator.geolocation.watchPosition(
            updateLocationInDatabase,
            (err) => {
              console.error("Error watching location:", err);
            }
          );
        }
      } catch (err) {
        console.error("Error initializing location in Realtime Database:", err);
        setError("Failed to initialize location. Please try again.");
      }
    };

    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            initializeLocationInDatabase(latitude, longitude);
          },
          (err) => {
            setError("Location access denied. Please enable location permissions.");
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();

    const storedExpirationTime = localStorage.getItem("expirationTime");
    if (storedExpirationTime) {
      console.log("Setting countdown from localStorage expiration time...");
      startCountdown(Number(storedExpirationTime));
    }

    return () => {
      clearInterval(countdownInterval.current);
    };
  }, [locationId, updateLocationInDatabase]);

  const startCountdown = (expirationTime) => {
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current);
    }

    countdownInterval.current = setInterval(() => {
      const remainingTime = expirationTime - Date.now();
      if (remainingTime <= 0) {
        clearInterval(countdownInterval.current);
        setCountdown("Expired");
        clearLocalStorage();
      } else {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        setCountdown(`${minutes}m ${seconds}s`);
      }
    }, 1000);
  };

  const expirePreviousLocations = async (newLocId) => {
    try {
      const locationsRef = ref(db, "user_locations");
      
      // Query locations for the current user
      const userLocationsQuery = query(locationsRef, orderByChild("userId"), equalTo(userId));
      
      // Get the snapshot of matching locations
      const snapshot = await get(userLocationsQuery);
  
      snapshot.forEach((childSnapshot) => {
        const locationKey = childSnapshot.key;
  
        // Only delete if it's not the newly created locationId (newLocId)
        if (locationKey !== newLocId) {
          console.log("Removing previous location with key:", locationKey);
          remove(ref(db, `user_locations/${locationKey}`));
        }
      });
    } catch (err) {
      console.error("Error expiring previous locations:", err);
    }
  };

  const generateNewUrl = async () => {
    try {
      // Step 1: Create the new location first
      const expirationTime = Date.now() + 30 * 60 * 1000; // Set expiration 30 minutes from now
      const newLocationRef = push(ref(db, "user_locations"));
      
      // Set the new location data in the database
      await set(newLocationRef, {
        latitude: location?.latitude,
        longitude: location?.longitude,
        timestamp: Date.now(),
        expirationTime,
        userId: userId,
      });
  
      const newLocId = newLocationRef.key;
  
      // Step 2: Update locationId and localStorage with the new location ID and expiration time
      clearLocalStorage(); // Clear previous references from localStorage
      setLocationId(newLocId); // Update state immediately with the new location ID
      setShareableUrl(`${window.location.origin}/location/${newLocId}`); // Update the shareable URL
  
      // Update localStorage with the new values
      localStorage.setItem("locationId", newLocId);
      localStorage.setItem("expirationTime", expirationTime);
  
      // Step 3: Start the countdown for the new expiration time
      startCountdown(expirationTime);
  
      // Step 4: Expire all previous locations for the user, except the newly created one
      await expirePreviousLocations(newLocId); // Pass newLocId as a parameter to prevent it from being deleted
  
    } catch (err) {
      console.error("Error generating new URL:", err);
      setError("Failed to generate new URL.");
    }
  };
  
  

  return (
    <div className="lp-container">
      <h2 className="lp-h2">Location Page</h2>
      <h3 className="lp-h3">Share your location with a link for 30 minutes</h3>
      {location ? (
        <>
          <MapContainer
            center={[location.latitude, location.longitude]}
            zoom={13}
            style={{ height: "70%", width: "100%", margin: "1rem 0" }}
            dragging={true}
            zoomControl={true}
            scrollWheelZoom={true}
            doubleClickZoom={true}
            touchZoom={true}
          >
            <TileLayer
              url="https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
              attribution='&copy; OpenStreetMap contributors'
            />
            <Marker position={[location.latitude, location.longitude]}>
              <Popup>Your Current Location</Popup>
            </Marker>
          </MapContainer>
        </>
      ) : (
        <p className="location-text">Loading location...</p>
      )}
      {shareableUrl && (
        <>
          <p className="shareable-url">
            Shareable URL: <a href={shareableUrl}>{shareableUrl}</a>
          </p>
          <p className="countdown">Expires in: {countdown}</p>
        </>
      )}
      <button onClick={generateNewUrl} className="lp-button">
        Generate New URL
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default LocationPage;


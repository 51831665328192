import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  CircleF,
  MarkerF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import "./Map.css";

const MIN_DISTANCE = 100; // Define a minimum distance in meters
const GOOGLE_API_KEY = "AIzaSyBGeDv6MVSrDfuvcB58eUMglzxR4093h4g";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 30.285,
  lng: -97.7335,
};

function InputAutocomplete({ label, placeholder, onPlaceSelected }) {
  const autocompleteRef = useRef(null); // Reference for the Autocomplete instance
  const inputRef = useRef(null); // Reference for the input field itself
  const inputId = label.toLowerCase().replace(" ", "-"); // Generate a unique id based on the label

  useEffect(() => {
    // Initialize Google Places Autocomplete
    if (window.google && window.google.maps && window.google.maps.places) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      // Attach the event listener
      autocompleteRef.current.addListener("place_changed", handlePlaceChanged);
    }
  }, []);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const details = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      onPlaceSelected(details);
    }
  };

  return (
    <div className="input-container">
      <label htmlFor={inputId}>{label}</label>
      <input
        id={inputId}
        ref={inputRef} // Attach the ref to the input field
        className="input"
        placeholder={placeholder || ""}
      />
    </div>
  );
}

function Map() {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null); // New state for live location
  const [following, setFollowing] = useState(false); // New following state
  const [heading, setHeading] = useState(null); // New state for heading

  const coordinates = [
    { lat: 30.3488641004719, lng: -97.6857651034018 },
    { lat: 30.3485064226573, lng: -97.686436920839 },
    { lat: 30.2970832524609, lng: -97.6881771810341 },
    { lat: 30.2969739355435, lng: -97.6882169823329 },
    { lat: 30.2959309290445, lng: -97.6883068416292 },
    { lat: 30.3001944348157, lng: -97.6897782444599 },
    { lat: 30.2983279626378, lng: -97.6915857664027 },
    { lat: 30.3014583183806, lng: -97.694609731908 },
    { lat: 30.2917223604364, lng: -97.694905054034 },
    { lat: 30.2923807318744, lng: -97.6949223795655 },
    { lat: 30.2924060538526, lng: -97.6949230459321 },
    { lat: 30.2910357573929, lng: -97.6950197298368 },
    { lat: 30.2917197572688, lng: -97.6950377302624 },
    { lat: 30.2924161116741, lng: -97.6950560553439 },
    { lat: 30.2946505679762, lng: -97.6951286752854 },
    { lat: 30.2988789825328, lng: -97.6952751214408 },
    { lat: 30.2943743599055, lng: -97.6956712932673 },
    { lat: 30.2929259964371, lng: -97.695671829611 },
    { lat: 30.2908801169638, lng: -97.6956974472935 },
    { lat: 30.2943736700364, lng: -97.6957037171689 },
    { lat: 30.2908698110667, lng: -97.695723856156 },
    { lat: 30.292913151996, lng: -97.6957305470631 },
    { lat: 30.2929067297756, lng: -97.6957599057891 },
    { lat: 30.291587317381, lng: -97.695769001703 },
    { lat: 30.2915287780087, lng: -97.6958338845503 },
    { lat: 30.2915848024098, lng: -97.6959016800302 },
    { lat: 30.2928273508758, lng: -97.6961388144021 },
    { lat: 30.2906178082449, lng: -97.696319760585 },
    { lat: 30.2914282843489, lng: -97.6965861504276 },
    { lat: 30.2914902011239, lng: -97.6965878421397 },
    { lat: 30.2921093688733, lng: -97.6966047592606 },
    { lat: 30.2928559057396, lng: -97.6966421248727 },
    { lat: 30.2945687118678, lng: -97.6966688580987 },
    { lat: 30.2947452069747, lng: -97.6966753295772 },
    { lat: 30.2937564171297, lng: -97.6966761573298 },
    { lat: 30.2915184568123, lng: -97.6967213610315 },
    { lat: 30.2921376245619, lng: -97.6967382781523 },
    { lat: 30.2904313486973, lng: -97.6969146107075 },
    { lat: 30.2944565698411, lng: -97.6970236496594 },
    { lat: 30.2944564175004, lng: -97.6970283468348 },
    { lat: 30.2944491051486, lng: -97.6972538112574 },
    { lat: 30.2999971143285, lng: -97.6973715862562 },
    { lat: 30.2937333514672, lng: -97.6973796607767 },
    { lat: 30.2929138138366, lng: -97.6974004176625 },
    { lat: 30.2913129045704, lng: -97.6974028445562 },
    { lat: 30.2937312545888, lng: -97.6974436156356 },
    { lat: 30.2943275134901, lng: -97.6974671614849 },
    { lat: 30.2912047781316, lng: -97.6974673841592 },
    { lat: 30.2944420974781, lng: -97.697469881329 },
    { lat: 30.2943266727953, lng: -97.6974930829346 },
    { lat: 30.2944411834341, lng: -97.6974980643818 },
    { lat: 30.2975002337496, lng: -97.6975108116531 },
    { lat: 30.2930251143193, lng: -97.6975933327198 },
    { lat: 30.2975004115435, lng: -97.6975961125248 },
    { lat: 30.2937264869767, lng: -97.6976075283361 },
    { lat: 30.2979524453306, lng: -97.6976084969896 },
    { lat: 30.2938238667711, lng: -97.6976106099702 },
    { lat: 30.2957587772436, lng: -97.6976113839041 },
    { lat: 30.2946176200158, lng: -97.6976367510034 },
    { lat: 30.2959034599473, lng: -97.6976808648003 },
    { lat: 30.2959034599473, lng: -97.6976808648003 },
    { lat: 30.2994160889261, lng: -97.6976850627144 },
    { lat: 30.2970649616152, lng: -97.6977132463313 },
    { lat: 30.2991296117107, lng: -97.697801838796 },
    { lat: 30.2994196705836, lng: -97.697817808559 },
    { lat: 30.2928926652202, lng: -97.6981150397117 },
    { lat: 30.293007178628, lng: -97.6981199351315 },
    { lat: 30.2935976674894, lng: -97.6982343483338 },
    { lat: 30.2956874321034, lng: -97.6982427562574 },
    { lat: 30.2944160939077, lng: -97.6982487213145 },
    { lat: 30.2956862033929, lng: -97.6982980483169 },
    { lat: 30.2957995281341, lng: -97.698356754959 },
    { lat: 30.3000840253219, lng: -97.698400585992 },
    { lat: 30.2989274101765, lng: -97.6984148313184 },
    { lat: 30.2999691349751, lng: -97.698427101578 },
    { lat: 30.3000837171759, lng: -97.6984287814465 },
    { lat: 30.2964019599778, lng: -97.6984345888138 },
    { lat: 30.2944101144648, lng: -97.698435934361 },
    { lat: 30.294591753485, lng: -97.6984430111968 },
    { lat: 30.2947993409365, lng: -97.6984510990091 },
    { lat: 30.2998195418826, lng: -97.6985061357182 },
    { lat: 30.2983934731579, lng: -97.6985874053942 },
    { lat: 30.2973439618567, lng: -97.6986283825792 },
    { lat: 30.2998591873379, lng: -97.6986401328077 },
    { lat: 30.2929870367988, lng: -97.698686327488 },
    { lat: 30.2929843899154, lng: -97.6987094877233 },
    { lat: 30.2901838739535, lng: -97.6987440250305 },
    { lat: 30.3039037756921, lng: -97.6988758831968 },
    { lat: 30.3039037756921, lng: -97.6988758831968 },
    { lat: 30.3037725105961, lng: -97.6988767719723 },
    { lat: 30.2929621558166, lng: -97.698917181411 },
    { lat: 30.2936935611552, lng: -97.6990587960132 },
    { lat: 30.2937000503683, lng: -97.6990677798518 },
    { lat: 30.2937117743829, lng: -97.6990698487948 },
    { lat: 30.2911960953171, lng: -97.6991172704356 },
    { lat: 30.2912122551553, lng: -97.6991234857568 },
    { lat: 30.2957661435548, lng: -97.699149466558 },
    { lat: 30.2963818073517, lng: -97.6991600842569 },
    { lat: 30.2963818073517, lng: -97.6991600842569 },
    { lat: 30.2956504687769, lng: -97.6991707375295 },
    { lat: 30.2913559187189, lng: -97.6991812922061 },
    { lat: 30.2913880154792, lng: -97.6991944748016 },
    { lat: 30.2937414089595, lng: -97.6992093233801 },
    { lat: 30.2984906736613, lng: -97.6992646768708 },
    { lat: 30.2985151528541, lng: -97.6992656828638 },
    { lat: 30.2985396320466, lng: -97.6992666888568 },
    { lat: 30.298825297052, lng: -97.6992793632433 },
    { lat: 30.2988967127942, lng: -97.6992828108962 },
    { lat: 30.3000585919337, lng: -97.6992962899731 },
    { lat: 30.2994979793519, lng: -97.699311837527 },
    { lat: 30.300057205103, lng: -97.6993385883578 },
    { lat: 30.2974352724816, lng: -97.6993618066769 },
    { lat: 30.2974351750068, lng: -97.6993710668236 },
    { lat: 30.2920448499675, lng: -97.6995694957475 },
    { lat: 30.292370497847, lng: -97.6997756457337 },
    { lat: 30.2957251480736, lng: -97.6999329332534 },
    { lat: 30.2926578156178, lng: -97.6999529907842 },
    { lat: 30.2957196957465, lng: -97.7000187087626 },
    { lat: 30.2958070673082, lng: -97.7000234572125 },
    { lat: 30.293217558909, lng: -97.7000457756551 },
    { lat: 30.2967669013244, lng: -97.7000556212835 },
    { lat: 30.2933649235847, lng: -97.7000854506734 },
    { lat: 30.2964083763339, lng: -97.7001081690374 },
    { lat: 30.2916952963946, lng: -97.7001320124763 },
    { lat: 30.2999325043123, lng: -97.7001477751603 },
    { lat: 30.2901349082408, lng: -97.7001551552099 },
    { lat: 30.2988512433137, lng: -97.7001579031193 },
    { lat: 30.2990334506197, lng: -97.7001610446157 },
    { lat: 30.2904040596602, lng: -97.7001667316014 },
    { lat: 30.2931916119031, lng: -97.7001750413854 },
    { lat: 30.2955980482642, lng: -97.7001866847614 },
    { lat: 30.2943670733086, lng: -97.7001898280099 },
    { lat: 30.2932758203106, lng: -97.7001977129514 },
    { lat: 30.2943670733089, lng: -97.7001983265701 },
    { lat: 30.295596309967, lng: -97.7002284039265 },
    { lat: 30.2935341091372, lng: -97.7002586546765 },
    { lat: 30.2943097776239, lng: -97.700266383313 },
    { lat: 30.2882684560071, lng: -97.700270941469 },
    { lat: 30.2956507773623, lng: -97.7002978832406 },
    { lat: 30.2951162624132, lng: -97.7003474326271 },
    { lat: 30.2966611827596, lng: -97.7004057568923 },
    { lat: 30.2967659047344, lng: -97.7004076269224 },
    { lat: 30.2974173957486, lng: -97.7004190171358 },
    { lat: 30.2974667188618, lng: -97.7004196881961 },
    { lat: 30.2988379066404, lng: -97.700437307198 },
    { lat: 30.3001393866895, lng: -97.7004390113798 },
    { lat: 30.2991780238978, lng: -97.7004396528176 },
    { lat: 30.29665905869, lng: -97.7004766216562 },
    { lat: 30.296658031769, lng: -97.7005234322107 },
    { lat: 30.2966575183087, lng: -97.7005468374878 },
    { lat: 30.2966572615784, lng: -97.7005585401265 },
    { lat: 30.2966570048483, lng: -97.700570242765 },
    { lat: 30.2924815011824, lng: -97.7005732595513 },
    { lat: 30.2915385579475, lng: -97.7007283998033 },
    { lat: 30.2900695425505, lng: -97.7008024142202 },
    { lat: 30.2914174825132, lng: -97.7008290694415 },
    { lat: 30.2930997915987, lng: -97.7008490292905 },
    { lat: 30.2900604260262, lng: -97.7008753464315 },
    { lat: 30.2915320738899, lng: -97.7008902987927 },
    { lat: 30.293294994638, lng: -97.7009051501513 },
    { lat: 30.293337047497, lng: -97.7009116748063 },
    { lat: 30.2987242646221, lng: -97.7009145249154 },
    { lat: 30.2934370307245, lng: -97.7009241727042 },
    { lat: 30.2999147299369, lng: -97.7009332967623 },
    { lat: 30.2986081111486, lng: -97.7009626570546 },
    { lat: 30.2956990730756, lng: -97.7009709457783 },
    { lat: 30.2930721830457, lng: -97.7009778325561 },
    { lat: 30.2943248880306, lng: -97.700999953695 },
    { lat: 30.2943767881926, lng: -97.7010029868186 },
    { lat: 30.2956990730772, lng: -97.7010139200947 },
    { lat: 30.2947400893267, lng: -97.7010242186845 },
    { lat: 30.2947919894887, lng: -97.7010272518082 },
    { lat: 30.2948438896507, lng: -97.7010302849318 },
    { lat: 30.293267386085, lng: -97.7010339534169 },
    { lat: 30.2972886469929, lng: -97.7010408554403 },
    { lat: 30.2933247509295, lng: -97.7010436219027 },
    { lat: 30.2933997383502, lng: -97.7010529953262 },
    { lat: 30.2987199031256, lng: -97.7010555467997 },
    { lat: 30.2901567569448, lng: -97.7010562915397 },
    { lat: 30.2956990730788, lng: -97.7010568944111 },
    { lat: 30.290037231304, lng: -97.7010608459902 },
    { lat: 30.2904474536984, lng: -97.7010624578201 },
    { lat: 30.2907658358569, lng: -97.7010692113654 },
    { lat: 30.3000252615634, lng: -97.7010702613939 },
    { lat: 30.2972879585065, lng: -97.7010728701116 },
    { lat: 30.2972872700201, lng: -97.7011048847827 },
    { lat: 30.2974020684559, lng: -97.7011249187248 },
    { lat: 30.2974510268412, lng: -97.7011269307107 },
    { lat: 30.2974999852264, lng: -97.7011289426968 },
    { lat: 30.2974999852264, lng: -97.7011289426968 },
    { lat: 30.2975979019968, lng: -97.7011329666687 },
    { lat: 30.297646860382, lng: -97.7011349786547 },
    { lat: 30.2944229133786, lng: -97.7011385671689 },
    { lat: 30.2977937355378, lng: -97.7011410146125 },
    { lat: 30.2946824141886, lng: -97.7011537327873 },
    { lat: 30.2981364442342, lng: -97.7011550985143 },
    { lat: 30.2986020362067, lng: -97.7011590803935 },
    { lat: 30.298716632003, lng: -97.7011613132129 },
    { lat: 30.2989096103344, lng: -97.7011826113467 },
    { lat: 30.2989581994465, lng: -97.7011837841848 },
    { lat: 30.2991525558953, lng: -97.7011884755378 },
    { lat: 30.2973427770626, lng: -97.7011888829373 },
    { lat: 30.2957080730826, lng: -97.7012102409626 },
    { lat: 30.3001126758039, lng: -97.7012151257272 },
    { lat: 30.298656776807, lng: -97.7012428828605 },
    { lat: 30.298656776807, lng: -97.7012428828605 },
    { lat: 30.2974959217462, lng: -97.7012615773887 },
    { lat: 30.290003512814, lng: -97.7012792742548 },
    { lat: 30.2987128662254, lng: -97.7013106111198 },
    { lat: 30.2988100444498, lng: -97.7013129567964 },
    { lat: 30.2990529900106, lng: -97.7013188209875 },
    { lat: 30.2992959355715, lng: -97.7013246851786 },
    { lat: 30.300107985617, lng: -97.7013477362173 },
    { lat: 30.2928258810179, lng: -97.7013511808129 },
    { lat: 30.2999000620135, lng: -97.7014019492306 },
    { lat: 30.2915320739103, lng: -97.7014501356553 },
    { lat: 30.2928214822604, lng: -97.7014564046013 },
    { lat: 30.2921201008664, lng: -97.7014764787844 },
    { lat: 30.2915318120199, lng: -97.7014803668547 },
    { lat: 30.3012483844599, lng: -97.7014932577078 },
    { lat: 30.292821482263, lng: -97.7015242095647 },
    { lat: 30.2943172969157, lng: -97.7015378992221 },
    { lat: 30.2922334464414, lng: -97.7015418248807 },
    { lat: 30.2942025447555, lng: -97.7015419869508 },
    { lat: 30.2929360736389, lng: -97.7015581120408 },
    { lat: 30.2928214822641, lng: -97.7015581120463 },
    { lat: 30.2915284969283, lng: -97.7015615866683 },
    { lat: 30.2956990730973, lng: -97.7015647131953 },
    { lat: 30.3012465560121, lng: -97.701572338199 },
    { lat: 30.2915273918977, lng: -97.7015886599396 },
    { lat: 30.2955844817246, lng: -97.701621460753 },
    { lat: 30.2943146223621, lng: -97.7016395323917 },
    { lat: 30.2955844817255, lng: -97.7016480234369 },
    { lat: 30.2941994269903, lng: -97.7016604621882 },
    { lat: 30.2899915973719, lng: -97.7016938591746 },
    { lat: 30.2907084293148, lng: -97.7017201834756 },
    { lat: 30.2907382973125, lng: -97.7017212803214 },
    { lat: 30.290857769303, lng: -97.7017256677049 },
    { lat: 30.2915230739224, lng: -97.7017344762078 },
    { lat: 30.2931427995956, lng: -97.7017727711753 },
    { lat: 30.2941920657007, lng: -97.7017745192079 },
    { lat: 30.2933474504355, lng: -97.701781522681 },
    { lat: 30.2922301206305, lng: -97.7017879355316 },
    { lat: 30.2956990731058, lng: -97.7018013625617 },
    { lat: 30.3033587976303, lng: -97.7018021179068 },
    { lat: 30.2944100172601, lng: -97.7018247433432 },
    { lat: 30.2972700233339, lng: -97.7018604257522 },
    { lat: 30.2972693588867, lng: -97.7018893292487 },
    { lat: 30.2929850832159, lng: -97.7018988311397 },
    { lat: 30.2921138803866, lng: -97.7019089141416 },
    { lat: 30.293240896766, lng: -97.7019097705219 },
    { lat: 30.297775401089, lng: -97.7019209269771 },
    { lat: 30.2979467522933, lng: -97.7019267951604 },
    { lat: 30.2944594086895, lng: -97.7019589348346 },
    { lat: 30.2945116717238, lng: -97.7019604521459 },
    { lat: 30.2946161977923, lng: -97.7019634867685 },
    { lat: 30.2946684608266, lng: -97.7019650040798 },
    { lat: 30.2947207238609, lng: -97.7019665213911 },
    { lat: 30.2922276262723, lng: -97.7019725185197 },
    { lat: 30.2956990731128, lng: -97.701993240762 },
    { lat: 30.2941894820121, lng: -97.7020059175461 },
    { lat: 30.2972660725446, lng: -97.7020365689261 },
    { lat: 30.297576184257, lng: -97.7020468806371 },
    { lat: 30.297723056718, lng: -97.7020519105086 },
    { lat: 30.297772014205, lng: -97.7020535871324 },
    { lat: 30.2913951919772, lng: -97.7020629116303 },
    { lat: 30.2915065985532, lng: -97.7020738413855 },
    { lat: 30.2920255558496, lng: -97.7020900587751 },
    { lat: 30.2989354441294, lng: -97.7020935733734 },
    { lat: 30.2922536092778, lng: -97.7020950831949 },
    { lat: 30.2923100395502, lng: -97.7020954496226 },
    { lat: 30.2998760644848, lng: -97.7021265613055 },
    { lat: 30.2963004757462, lng: -97.7021615772305 },
    { lat: 30.2956990731191, lng: -97.7021704983088 },
    { lat: 30.2943040733956, lng: -97.7022490153477 },
    { lat: 30.2962979209161, lng: -97.7022573834851 },
    { lat: 30.2956990731257, lng: -97.7023477558555 },
    { lat: 30.2898521340387, lng: -97.7023644658976 },
    { lat: 30.2972584991935, lng: -97.7023887303343 },
    { lat: 30.2998695796791, lng: -97.7024264840487 },
    { lat: 30.2898434502814, lng: -97.7024286556975 },
    { lat: 30.2986870545807, lng: -97.702434793841 },
    { lat: 30.2962928112559, lng: -97.7024489959944 },
    { lat: 30.2898954302659, lng: -97.7024542715983 },
    { lat: 30.2899580751549, lng: -97.7024564726318 },
    { lat: 30.2899893975992, lng: -97.7024575731485 },
    { lat: 30.2998684988783, lng: -97.7024764711725 },
    { lat: 30.2906158464884, lng: -97.7024795834837 },
    { lat: 30.2913814825811, lng: -97.7025085277937 },
    { lat: 30.2941804820332, lng: -97.7025371704508 },
    { lat: 30.2962902564257, lng: -97.702544802249 },
    { lat: 30.2914387782709, lng: -97.7025748828766 },
    { lat: 30.2899859227107, lng: -97.7025902222876 },
    { lat: 30.2906123715998, lng: -97.7026122326226 },
    { lat: 30.2907063389333, lng: -97.7026155341728 },
    { lat: 30.2943151132819, lng: -97.7026451039244 },
    { lat: 30.2943341457917, lng: -97.7026454950023 },
    { lat: 30.2943531783013, lng: -97.7026458860804 },
    { lat: 30.2943722108111, lng: -97.7026462771585 },
    { lat: 30.2944102758307, lng: -97.7026470593145 },
    { lat: 30.2944673733599, lng: -97.7026482325486 },
    { lat: 30.2945244708893, lng: -97.7026494057826 },
    { lat: 30.2945530196539, lng: -97.7026499923998 },
    { lat: 30.2947909260258, lng: -97.7026548808751 },
    { lat: 30.2948289910452, lng: -97.7026556630312 },
    { lat: 30.2950767271486, lng: -97.7026676537315 },
    { lat: 30.298566786311, lng: -97.7026956896063 },
    { lat: 30.2956934914258, lng: -97.7027062352881 },
    { lat: 30.2957177534248, lng: -97.7027078015529 },
    { lat: 30.2942377777267, lng: -97.7027098827081 },
    { lat: 30.2957671615861, lng: -97.7027108895604 },
    { lat: 30.296661019209, lng: -97.7027348329231 },
    { lat: 30.292846094155, lng: -97.7027449932996 },
    { lat: 30.292846094155, lng: -97.7027449932996 },
    { lat: 30.2976593193563, lng: -97.7027533659955 },
    { lat: 30.2980020190489, lng: -97.7027639286402 },
    { lat: 30.2986794889552, lng: -97.7027847045842 },
    { lat: 30.2989691008501, lng: -97.7027950872676 },
    { lat: 30.299113769401, lng: -97.7028001104739 },
    { lat: 30.2992102151017, lng: -97.7028034592782 },
    { lat: 30.2973067771285, lng: -97.7028088825358 },
    { lat: 30.2998609332718, lng: -97.7028263810394 },
    { lat: 30.2929748846261, lng: -97.7028267020207 },
    { lat: 30.2930296016985, lng: -97.7028279362377 },
    { lat: 30.2931390358435, lng: -97.7028304046717 },
    { lat: 30.2931937529161, lng: -97.7028316388887 },
    { lat: 30.2932484699884, lng: -97.7028328731057 },
    { lat: 30.2933031870611, lng: -97.7028341073225 },
    { lat: 30.2933579041335, lng: -97.7028353415395 },
    { lat: 30.2935494138872, lng: -97.7028396612991 },
    { lat: 30.2935767724234, lng: -97.7028402784075 },
    { lat: 30.2936041309596, lng: -97.7028408955159 },
    { lat: 30.2936314894961, lng: -97.7028415126245 },
    { lat: 30.2913634826011, lng: -97.7029640030008 },
    { lat: 30.291363482602, lng: -97.7029900276869 },
    { lat: 30.2898130743078, lng: -97.7031163348174 },
    { lat: 30.2916485108613, lng: -97.703135266272 },
    { lat: 30.2919914674574, lng: -97.7031539598287 },
    { lat: 30.2922253179645, lng: -97.7031738950017 },
    { lat: 30.2884740470768, lng: -97.7032074369286 },
    { lat: 30.294295073433, lng: -97.7032323709645 },
    { lat: 30.2898765513897, lng: -97.7032719677026 },
    { lat: 30.2899076914674, lng: -97.703272729279 },
    { lat: 30.2941804820604, lng: -97.70328765961 },
    { lat: 30.2905927731763, lng: -97.7032894839609 },
    { lat: 30.290623913254, lng: -97.7032902455373 },
    { lat: 30.2906861934092, lng: -97.7032917686902 },
    { lat: 30.2907484735645, lng: -97.7032932918431 },
    { lat: 30.2897557786284, lng: -97.7033353827682 },
    { lat: 30.2941804820634, lng: -97.70337059257 },
    { lat: 30.2898741320098, lng: -97.7034046460158 },
    { lat: 30.292883627027, lng: -97.7034103756473 },
    { lat: 30.2929366250535, lng: -97.70341206348 },
    { lat: 30.2930956191332, lng: -97.7034171269778 },
    { lat: 30.2932016151863, lng: -97.7034205026429 },
    { lat: 30.2905903537962, lng: -97.703422162274 },
    { lat: 30.2906526339514, lng: -97.7034236854269 },
    { lat: 30.2933341102526, lng: -97.7034247222245 },
    { lat: 30.2908083343398, lng: -97.7034274933091 },
    { lat: 30.2908706144953, lng: -97.7034290164621 },
    { lat: 30.2941804820664, lng: -97.70345352553 },
    { lat: 30.2965970249368, lng: -97.7034681470525 },
    { lat: 30.292933475137, lng: -97.7035447253788 },
    { lat: 30.2930394711901, lng: -97.7035481010441 },
    { lat: 30.2931454672432, lng: -97.7035514767093 },
    { lat: 30.2931984652698, lng: -97.7035531645419 },
    { lat: 30.2933044613228, lng: -97.7035565402071 },
    { lat: 30.2948259806126, lng: -97.703588331309 },
    { lat: 30.2949405093129, lng: -97.7035927204625 },
    { lat: 30.2948247442149, lng: -97.7036316052821 },
    { lat: 30.294939272915, lng: -97.7036359944357 },
    { lat: 30.2966764945426, lng: -97.7036433983854 },
    { lat: 30.2949380365174, lng: -97.7036792684088 },
    { lat: 30.2949368001196, lng: -97.7037225423819 },
    { lat: 30.2949343273243, lng: -97.7038090903283 },
    { lat: 30.2948181879691, lng: -97.7038470174619 },
    { lat: 30.2949326515297, lng: -97.7038532841161 },
    { lat: 30.2914569220736, lng: -97.7039357067636 },
    { lat: 30.2914554473533, lng: -97.7040035440036 },
    { lat: 30.294151501335, lng: -97.7040248520658 },
    { lat: 30.2914547099933, lng: -97.7040374626237 },
    { lat: 30.2927608492663, lng: -97.7040548398314 },
    { lat: 30.2941492994016, lng: -97.704063385922 },
    { lat: 30.292760492914, lng: -97.7040860207546 },
    { lat: 30.291452497913, lng: -97.704139218484 },
    { lat: 30.2955347781874, lng: -97.7041696101746 },
    { lat: 30.2927594238572, lng: -97.7041795635242 },
    { lat: 30.2928746670544, lng: -97.7041979244345 },
    { lat: 30.2932353681829, lng: -97.704209712035 },
    { lat: 30.2942548329478, lng: -97.704226285172 },
    { lat: 30.2904968657009, lng: -97.7042489833196 },
    { lat: 30.2900542124776, lng: -97.7042852156025 },
    { lat: 30.2955167991263, lng: -97.7042984413304 },
    { lat: 30.2941927777935, lng: -97.7043073823127 },
    { lat: 30.2929744923166, lng: -97.7043339515523 },
    { lat: 30.2932321359798, lng: -97.7043423712669 },
    { lat: 30.2932836647125, lng: -97.7043440552099 },
    { lat: 30.2970103413248, lng: -97.7043665161941 },
    { lat: 30.2941304405414, lng: -97.704367334387 },
    { lat: 30.2914492714712, lng: -97.7043710344755 },
    { lat: 30.2942844667145, lng: -97.704379153568 },
    { lat: 30.2921742457878, lng: -97.7043814045808 },
    { lat: 30.2943591425903, lng: -97.7043834951848 },
    { lat: 30.2901368065357, lng: -97.7043912841612 },
    { lat: 30.2954951871462, lng: -97.7044163895661 },
    { lat: 30.2901961941213, lng: -97.7044655186332 },
    { lat: 30.2954691233424, lng: -97.7044782911036 },
    { lat: 30.2920569449107, lng: -97.7045249258665 },
    { lat: 30.2920555767503, lng: -97.7045981225811 },
    { lat: 30.2942320734968, lng: -97.7046501885097 },
    { lat: 30.2971713208701, lng: -97.7046915506906 },
    { lat: 30.2971713208701, lng: -97.7046915506906 },
    { lat: 30.2971713208701, lng: -97.7046915506906 },
    { lat: 30.2971860950345, lng: -97.7047210990154 },
    { lat: 30.2921660368259, lng: -97.7048205848686 },
    { lat: 30.2920471741709, lng: -97.7049658862456 },
    { lat: 30.2921778647232, lng: -97.7049688400767 },
    { lat: 30.2922592217206, lng: -97.7049725867765 },
    { lat: 30.2973226117125, lng: -97.7049863035114 },
    { lat: 30.2928482651815, lng: -97.7049996651328 },
    { lat: 30.2930951582125, lng: -97.7050015259703 },
    { lat: 30.2935838353282, lng: -97.7050065666809 },
    { lat: 30.294252712531, lng: -97.7050240251294 },
    { lat: 30.2943449628849, lng: -97.7050255650901 },
    { lat: 30.2946934080078, lng: -97.7050277149122 },
    { lat: 30.294402639357, lng: -97.7050288354284 },
    { lat: 30.2945215337958, lng: -97.7050321380458 },
    { lat: 30.2946390792286, lng: -97.7050330249016 },
    { lat: 30.2913847783707, lng: -97.7050363822664 },
    { lat: 30.2918459891973, lng: -97.7050998556921 },
    { lat: 30.2921597521193, lng: -97.7051008219002 },
    { lat: 30.2914423824289, lng: -97.7051023782537 },
    { lat: 30.292846073786, lng: -97.7051297381883 },
    { lat: 30.2929361100484, lng: -97.705132239973 },
    { lat: 30.2974078165088, lng: -97.7051658421619 },
    { lat: 30.2982266143074, lng: -97.705305545453 },
    { lat: 30.3069601366872, lng: -97.7054736170451 },
    { lat: 30.2907490742102, lng: -97.7054883975783 },
    { lat: 30.2907490742122, lng: -97.7055433189239 },
    { lat: 30.2982337770516, lng: -97.7056978817719 },
    { lat: 30.3014041771631, lng: -97.705751160218 },
    { lat: 30.2907419519844, lng: -97.7057659442714 },
    { lat: 30.3012823713726, lng: -97.7058342095665 },
    { lat: 30.2941042404225, lng: -97.7060422261515 },
    { lat: 30.2912959268001, lng: -97.706079196732 },
    { lat: 30.2920138506865, lng: -97.7062135110504 },
    { lat: 30.2921283800035, lng: -97.7062178791741 },
    { lat: 30.2934999729116, lng: -97.7062547114328 },
    { lat: 30.2912898054535, lng: -97.7062817115158 },
    { lat: 30.2941028671317, lng: -97.7063519057254 },
    { lat: 30.2914022862011, lng: -97.7063538592461 },
    { lat: 30.2926957775134, lng: -97.7063596049931 },
    { lat: 30.292007700295, lng: -97.7064298000792 },
    { lat: 30.2934958548841, lng: -97.706485321431 },
    { lat: 30.2928062005519, lng: -97.7065131681572 },
    { lat: 30.2913961648544, lng: -97.7065563740299 },
    { lat: 30.2920036000341, lng: -97.706573992765 },
    { lat: 30.2910930911338, lng: -97.7066238600635 },
    { lat: 30.2920015499037, lng: -97.7066460891078 },
    { lat: 30.2928020904169, lng: -97.7066625032528 },
    { lat: 30.2912113878451, lng: -97.7066999115714 },
    { lat: 30.2919994997732, lng: -97.7067181854507 },
    { lat: 30.2921140290902, lng: -97.7067225535744 },
    { lat: 30.2926855021758, lng: -97.7067329427321 },
    { lat: 30.2926855021758, lng: -97.7067329427321 },
    { lat: 30.2941011505183, lng: -97.7067390051929 },
    { lat: 30.2912961990092, lng: -97.7067405236638 },
    { lat: 30.2941008071956, lng: -97.7068164250864 },
    { lat: 30.291949905164, lng: -97.7068549068521 },
    { lat: 30.2921099288293, lng: -97.7068667462602 },
    { lat: 30.2934889915049, lng: -97.7068696714279 },
    { lat: 30.2927959252143, lng: -97.7068865058962 },
    { lat: 30.2941004638729, lng: -97.7068938449798 },
    { lat: 30.2920507783087, lng: -97.7069308817627 },
    { lat: 30.2933730519732, lng: -97.7069437973736 },
    { lat: 30.293487618829, lng: -97.7069465414273 },
    { lat: 30.2926152735941, lng: -97.7069466345649 },
    { lat: 30.2926617599893, lng: -97.7069537863154 },
    { lat: 30.2926806532801, lng: -97.7069555464336 },
    { lat: 30.2993221970311, lng: -97.7070067085385 },
    { lat: 30.2933030314105, lng: -97.7070162030744 },
    { lat: 30.2933740327033, lng: -97.7070212745915 },
    { lat: 30.2940994822126, lng: -97.7070490247607 },
    { lat: 30.2940492853582, lng: -97.7070490248119 },
    { lat: 30.294024186931, lng: -97.7070490248131 },
    { lat: 30.289406974746, lng: -97.7071003494019 },
    { lat: 30.2975240125314, lng: -97.7071187948187 },
    { lat: 30.2901732998844, lng: -97.7071737888532 },
    { lat: 30.2981436856023, lng: -97.7071809447506 },
    { lat: 30.2909099723122, lng: -97.70726878827 },
    { lat: 30.2916962864643, lng: -97.707372536298 },
    { lat: 30.2917459357504, lng: -97.7073793747818 },
    { lat: 30.2889487272975, lng: -97.7073992766316 },
    { lat: 30.292391376471, lng: -97.7074682750712 },
    { lat: 30.2893523955234, lng: -97.7074714200841 },
    { lat: 30.2916994415531, lng: -97.7077652169712 },
    { lat: 30.2946509374269, lng: -97.7077664844065 },
    { lat: 30.2917500249972, lng: -97.707770995227 },
    { lat: 30.2952628642796, lng: -97.7078342480851 },
    { lat: 30.3021537327526, lng: -97.7079261507879 },
    { lat: 30.2860845610575, lng: -97.708092803876 },
    { lat: 30.3023499388554, lng: -97.7082487024157 },
    { lat: 30.2868646944914, lng: -97.7082618327389 },
    { lat: 30.2875056610507, lng: -97.7084448615058 },
    { lat: 30.287528298955, lng: -97.7084660388973 },
    { lat: 30.2915751679548, lng: -97.7087090368007 },
    { lat: 30.2915785028841, lng: -97.7087172153166 },
    { lat: 30.2979255760896, lng: -97.7088620179452 },
    { lat: 30.3086917751211, lng: -97.7090008804456 },
    { lat: 30.2907248134987, lng: -97.7090168309895 },
    { lat: 30.2880667049632, lng: -97.7090215330432 },
    { lat: 30.2906294749813, lng: -97.7090904618563 },
    { lat: 30.2978287772561, lng: -97.7091448809334 },
    { lat: 30.2964644342792, lng: -97.7093729781163 },
    { lat: 30.296378873252, lng: -97.7094612619324 },
    { lat: 30.3002766609432, lng: -97.7096464083118 },
    { lat: 30.2987943787416, lng: -97.7096505004486 },
    { lat: 30.2967422801244, lng: -97.7098414651253 },
    { lat: 30.2912462276959, lng: -97.7098661515788 },
    { lat: 30.2913250991179, lng: -97.7099838114001 },
    { lat: 30.2948878898486, lng: -97.7099926534317 },
    { lat: 30.2986895900247, lng: -97.7100766455435 },
    { lat: 30.2991770656632, lng: -97.7101865032178 },
    { lat: 30.2935938873029, lng: -97.7102539947091 },
    { lat: 30.2950439686516, lng: -97.7103363588282 },
    { lat: 30.2935483885275, lng: -97.7104081272722 },
    { lat: 30.2935721757193, lng: -97.7104464841135 },
    { lat: 30.2917784129647, lng: -97.7104934231794 },
    { lat: 30.3119485095687, lng: -97.710767831546 },
    { lat: 30.2856425458076, lng: -97.7108384508591 },
    { lat: 30.2992932540004, lng: -97.7109366684196 },
    { lat: 30.2997087256648, lng: -97.7110357256031 },
    { lat: 30.2993542152024, lng: -97.7110573592663 },
    { lat: 30.288069883075, lng: -97.7111691570077 },
    { lat: 30.2994151764045, lng: -97.711178050113 },
    { lat: 30.2917250505091, lng: -97.7112522067595 },
    { lat: 30.2971973827706, lng: -97.7112681248675 },
    { lat: 30.3010973120603, lng: -97.7112850081214 },
    { lat: 30.3009641622131, lng: -97.7112985637408 },
    { lat: 30.3012205899977, lng: -97.711400459307 },
    { lat: 30.3011984422712, lng: -97.7114461843723 },
    { lat: 30.3028477707473, lng: -97.7114618641168 },
    { lat: 30.2969309668018, lng: -97.7114776325516 },
    { lat: 30.3011857766846, lng: -97.7115388801758 },
    { lat: 30.2968444354619, lng: -97.7115746367746 },
    { lat: 30.3044665761539, lng: -97.7116903758874 },
    { lat: 30.2963129831401, lng: -97.7117365301968 },
    { lat: 30.3045372072091, lng: -97.7119203896115 },
    { lat: 30.3104737748784, lng: -97.7119393796283 },
    { lat: 30.3125217068367, lng: -97.7119775900311 },
    { lat: 30.3062122202432, lng: -97.7119903939233 },
    { lat: 30.301992765863, lng: -97.71207451913 },
    { lat: 30.3072626273805, lng: -97.7121418823184 },
    { lat: 30.3020568948414, lng: -97.712166076755 },
    { lat: 30.2855198433846, lng: -97.7121782501526 },
    { lat: 30.2864584259399, lng: -97.7121921349548 },
    { lat: 30.2872395457717, lng: -97.7123151459072 },
    { lat: 30.3007948285009, lng: -97.7123932291484 },
    { lat: 30.3009052731344, lng: -97.7124286107538 },
    { lat: 30.3009052731344, lng: -97.7124286107538 },
    { lat: 30.2990663507265, lng: -97.7124312136061 },
    { lat: 30.3118103022127, lng: -97.7124364336119 },
    { lat: 30.3035460910788, lng: -97.7124590104027 },
    { lat: 30.3054278686958, lng: -97.7125165816402 },
    { lat: 30.3035617762591, lng: -97.7126458797857 },
    { lat: 30.2894387220446, lng: -97.7127111990478 },
    { lat: 30.3006238405732, lng: -97.7129130322462 },
    { lat: 30.2905956008207, lng: -97.7129427227346 },
    { lat: 30.30148951286, lng: -97.7129569921845 },
    { lat: 30.3120637174569, lng: -97.7129595331614 },
    { lat: 30.2981997431886, lng: -97.7129646354894 },
    { lat: 30.2904886495466, lng: -97.7129927886686 },
    { lat: 30.2853206758474, lng: -97.7129991932914 },
    { lat: 30.2962377755302, lng: -97.7130128469821 },
    { lat: 30.2905140986928, lng: -97.7130467994383 },
    { lat: 30.3003393207199, lng: -97.7130753914754 },
    { lat: 30.294558547307, lng: -97.7130857798052 },
    { lat: 30.2963372349061, lng: -97.7131072894589 },
    { lat: 30.3084885381591, lng: -97.7131300580845 },
    { lat: 30.2944641139702, lng: -97.7131464869531 },
    { lat: 30.3130829414641, lng: -97.7131725894056 },
    { lat: 30.2906158952778, lng: -97.7132628425172 },
    { lat: 30.300443315835, lng: -97.7132771061081 },
    { lat: 30.3042070041793, lng: -97.7133018773416 },
    { lat: 30.2906413444239, lng: -97.7133168532869 },
    { lat: 30.2906667935702, lng: -97.7133708640567 },
    { lat: 30.3008691785618, lng: -97.7134068870311 },
    { lat: 30.3005189486457, lng: -97.7134238076593 },
    { lat: 30.310123612704, lng: -97.7134707470058 },
    { lat: 30.2876388261408, lng: -97.7135209090359 },
    { lat: 30.2876855166837, lng: -97.7135287165578 },
    { lat: 30.2877322072264, lng: -97.7135365240797 },
    { lat: 30.3113374467611, lng: -97.7135481381241 },
    { lat: 30.3123533554733, lng: -97.713561378299 },
    { lat: 30.3038086081145, lng: -97.7135670872315 },
    { lat: 30.2907685901552, lng: -97.7135869071357 },
    { lat: 30.300604035558, lng: -97.7135888469043 },
    { lat: 30.3006134896593, lng: -97.7136071845981 },
    { lat: 30.2935039854058, lng: -97.7136131308381 },
    { lat: 30.3101964374598, lng: -97.7136228493209 },
    { lat: 30.3020358232111, lng: -97.7136287536087 },
    { lat: 30.2986627855137, lng: -97.7137037467617 },
    { lat: 30.311293240112, lng: -97.7137367733461 },
    { lat: 30.2956473351074, lng: -97.713817060678 },
    { lat: 30.3104017749643, lng: -97.7139148791401 },
    { lat: 30.2966591365289, lng: -97.7140556626415 },
    { lat: 30.2911574189425, lng: -97.7140634951304 },
    { lat: 30.2851596891866, lng: -97.7141302976612 },
    { lat: 30.3106390601219, lng: -97.7142886334043 },
    { lat: 30.3096235622583, lng: -97.7143145811832 },
    { lat: 30.2947873217445, lng: -97.7143489337726 },
    { lat: 30.2863149413938, lng: -97.7143955209348 },
    { lat: 30.2864073000853, lng: -97.7145499940629 },
    { lat: 30.3088280578155, lng: -97.7145708629281 },
    { lat: 30.3087133557862, lng: -97.7146050655669 },
    { lat: 30.2961432714822, lng: -97.7146760796239 },
    { lat: 30.3087716041771, lng: -97.7147263549194 },
    { lat: 30.2940600918081, lng: -97.7147275896852 },
    { lat: 30.305895492511, lng: -97.7147522742806 },
    { lat: 30.3117521027366, lng: -97.7147541199281 },
    { lat: 30.3058522604207, lng: -97.7147790990125 },
    { lat: 30.3043949766332, lng: -97.7148208852833 },
    { lat: 30.2961237955685, lng: -97.7148602051444 },
    { lat: 30.2982680016912, lng: -97.7149459553038 },
    { lat: 30.2972926348657, lng: -97.7150366750672 },
    { lat: 30.3140845203894, lng: -97.7151057685235 },
    { lat: 30.3131130144087, lng: -97.7151706195754 },
    { lat: 30.312209501448, lng: -97.7152612875361 },
    { lat: 30.2870967962351, lng: -97.715276997484 },
    { lat: 30.307526028275, lng: -97.7153025125045 },
    { lat: 30.3004738112934, lng: -97.7153123486089 },
    { lat: 30.2918569858554, lng: -97.7153352658268 },
    { lat: 30.3046597286759, lng: -97.7153438631059 },
    { lat: 30.3111480547873, lng: -97.7153471639949 },
    { lat: 30.3004800573145, lng: -97.7154157809698 },
    { lat: 30.3047036715478, lng: -97.7154317488366 },
    { lat: 30.2954502099169, lng: -97.7154553671699 },
    { lat: 30.309710186339, lng: -97.7154669990943 },
    { lat: 30.2964203565136, lng: -97.715473287775 },
    { lat: 30.2954614651638, lng: -97.7154787867376 },
    { lat: 30.2849273919615, lng: -97.7155525902687 },
    { lat: 30.2946001325449, lng: -97.7156186634607 },
    { lat: 30.3076477755692, lng: -97.715710378826 },
    { lat: 30.3053090683051, lng: -97.7158168386868 },
    { lat: 30.2882023800335, lng: -97.7158325409493 },
    { lat: 30.3040191213747, lng: -97.7158936529401 },
    { lat: 30.3038379750305, lng: -97.7159029766572 },
    { lat: 30.3037700451515, lng: -97.7159064730511 },
    { lat: 30.3037474018584, lng: -97.7159076385158 },
    { lat: 30.3037247585655, lng: -97.7159088039804 },
    { lat: 30.2880827110295, lng: -97.7159099131536 },
    { lat: 30.3048487761254, lng: -97.7159173789095 },
    { lat: 30.2968671573666, lng: -97.7159608673916 },
    { lat: 30.3050451941628, lng: -97.7159693521093 },
    { lat: 30.304909606237, lng: -97.7159793795183 },
    { lat: 30.2881984057004, lng: -97.7159870976011 },
    { lat: 30.2975918604496, lng: -97.7160271346266 },
    { lat: 30.2975717031783, lng: -97.7160285933775 },
    { lat: 30.2975313886353, lng: -97.7160315108791 },
    { lat: 30.3036845591562, lng: -97.7160437307201 },
    { lat: 30.2880787366963, lng: -97.7160644698054 },
    { lat: 30.3095140095822, lng: -97.7160780393241 },
    { lat: 30.2985463984218, lng: -97.7160910333576 },
    { lat: 30.2996638746797, lng: -97.7161352706868 },
    { lat: 30.297418820939, lng: -97.7161662569809 },
    { lat: 30.3084323366148, lng: -97.716170377308 },
    { lat: 30.3036067763797, lng: -97.7162121288959 },
    { lat: 30.2895197700647, lng: -97.7163150810007 },
    { lat: 30.3124987746424, lng: -97.7163718784274 },
    { lat: 30.3126172774534, lng: -97.7163722943519 },
    { lat: 30.3125765621481, lng: -97.7163981585839 },
    { lat: 30.3141113679184, lng: -97.7164142900762 },
    { lat: 30.2990840678803, lng: -97.7165381523312 },
    { lat: 30.3006367769799, lng: -97.7167048789165 },
    { lat: 30.286827929454, lng: -97.7167110377634 },
    { lat: 30.2868046310833, lng: -97.7167258946962 },
    { lat: 30.3055345323838, lng: -97.7167269789392 },
    { lat: 30.3072751957272, lng: -97.7167680924903 },
    { lat: 30.2923660140994, lng: -97.7167747483703 },
    { lat: 30.3052328008654, lng: -97.7167778575423 },
    { lat: 30.3052402006014, lng: -97.7167918641834 },
    { lat: 30.3053815833801, lng: -97.7168051891498 },
    { lat: 30.2925166315703, lng: -97.7168350930434 },
    { lat: 30.307449033559, lng: -97.7168422106355 },
    { lat: 30.3055629205758, lng: -97.7168610720651 },
    { lat: 30.2922841363389, lng: -97.7168701836541 },
    { lat: 30.3073487410794, lng: -97.7169149226862 },
    { lat: 30.2925430097984, lng: -97.7169703091586 },
    { lat: 30.2982656698852, lng: -97.717046843893 },
    { lat: 30.2894129718096, lng: -97.7172579273363 },
    { lat: 30.2977859868038, lng: -97.7173417274393 },
    { lat: 30.3084456767209, lng: -97.7173922016017 },
    { lat: 30.2930187237397, lng: -97.7174552894953 },
    { lat: 30.2974656756984, lng: -97.7175377072698 },
    { lat: 30.3037794041227, lng: -97.7175643355934 },
    { lat: 30.2930747040691, lng: -97.7175710866775 },
    { lat: 30.3062081049444, lng: -97.7176295130668 },
    { lat: 30.3146366483137, lng: -97.7176346107521 },
    { lat: 30.3011770178707, lng: -97.717645724585 },
    { lat: 30.3021530888183, lng: -97.717673874495 },
    { lat: 30.30612391871, lng: -97.7176818728004 },
    { lat: 30.306095856632, lng: -97.7176993260448 },
    { lat: 30.3039809807474, lng: -97.7177174570399 },
    { lat: 30.3012232058219, lng: -97.7177374212392 },
    { lat: 30.3105533769332, lng: -97.7177524982514 },
    { lat: 30.30110883159, lng: -97.7177737372521 },
    { lat: 30.2852155435426, lng: -97.7178005514309 },
    { lat: 30.3057937760089, lng: -97.7178118783924 },
    { lat: 30.2889585901429, lng: -97.7178318136137 },
    { lat: 30.2889497972013, lng: -97.7178374768645 },
    { lat: 30.305870967133, lng: -97.7178404179977 },
    { lat: 30.2873569248952, lng: -97.7179175641065 },
    { lat: 30.2889187793218, lng: -97.7179333791751 },
    { lat: 30.2873341070685, lng: -97.7179390397088 },
    { lat: 30.3013262876803, lng: -97.7179472305797 },
    { lat: 30.2845345613447, lng: -97.7180299103034 },
    { lat: 30.2873257796378, lng: -97.7180323792272 },
    { lat: 30.3072092861781, lng: -97.7180344986179 },
    { lat: 30.3074467045186, lng: -97.718040088912 },
    { lat: 30.2966866920144, lng: -97.7180417788856 },
    { lat: 30.313500713525, lng: -97.7181227807576 },
    { lat: 30.3042106472761, lng: -97.7181847639337 },
    { lat: 30.2845510797946, lng: -97.7182511647965 },
    { lat: 30.2864605135673, lng: -97.7182581120253 },
    { lat: 30.3150556756266, lng: -97.7182852130733 },
    { lat: 30.3150349701895, lng: -97.7182978200135 },
    { lat: 30.284430268204, lng: -97.7183330944993 },
    { lat: 30.2916005957805, lng: -97.7183433905042 },
    { lat: 30.3066327245201, lng: -97.7183768321193 },
    { lat: 30.3031749411146, lng: -97.7184645774679 },
    { lat: 30.3090748367593, lng: -97.7185341378851 },
    { lat: 30.3090209098393, lng: -97.7185679275389 },
    { lat: 30.2970614758296, lng: -97.7185697558298 },
    { lat: 30.2877611837047, lng: -97.718586559734 },
    { lat: 30.3145452079612, lng: -97.7186131563553 },
    { lat: 30.3145378749173, lng: -97.7186179731589 },
    { lat: 30.3064563898209, lng: -97.7186306839743 },
    { lat: 30.3145158757852, lng: -97.7186324235697 },
    { lat: 30.3144938766533, lng: -97.7186468739806 },
    { lat: 30.2957160913977, lng: -97.7186773381373 },
    { lat: 30.3043295716526, lng: -97.7186918185475 },
    { lat: 30.2968085035508, lng: -97.7187293985375 },
    { lat: 30.3044858191369, lng: -97.7187416203224 },
    { lat: 30.2895187632299, lng: -97.718789718411 },
    { lat: 30.2857750829665, lng: -97.7188317320335 },
    { lat: 30.3124412068074, lng: -97.7188347516217 },
    { lat: 30.2857599942794, lng: -97.7188410488437 },
    { lat: 30.2894076582851, lng: -97.7188642092294 },
    { lat: 30.3110449566525, lng: -97.7188892457959 },
    { lat: 30.2906958288326, lng: -97.7189560980302 },
    { lat: 30.2894864211057, lng: -97.7189647448076 },
    { lat: 30.2908824511251, lng: -97.7189879097836 },
    { lat: 30.3137947744846, lng: -97.7190178777063 },
    { lat: 30.3137947744846, lng: -97.7190178777063 },
    { lat: 30.2937335895484, lng: -97.7190273709386 },
    { lat: 30.2907888749129, lng: -97.7190480659227 },
    { lat: 30.2921396355894, lng: -97.719118225305 },
    { lat: 30.2869009300013, lng: -97.7191395260674 },
    { lat: 30.3057196587247, lng: -97.7191428339063 },
    { lat: 30.3155294262736, lng: -97.7191611355333 },
    { lat: 30.3018337768343, lng: -97.7191618782472 },
    { lat: 30.307898441659, lng: -97.7191862984776 },
    { lat: 30.3016212420065, lng: -97.7192070969183 },
    { lat: 30.3056179331572, lng: -97.719211424588 },
    { lat: 30.2849944460343, lng: -97.7192222978299 },
    { lat: 30.2948678725288, lng: -97.7192350069211 },
    { lat: 30.3018094069746, lng: -97.7192513836851 },
    { lat: 30.3018661546234, lng: -97.7193776627742 },
    { lat: 30.3048546080543, lng: -97.7194776205808 },
    { lat: 30.2898349276802, lng: -97.7195095345104 },
    { lat: 30.3048806191873, lng: -97.7195263314743 },
    { lat: 30.289815682306, lng: -97.7196736897698 },
    { lat: 30.3128730690267, lng: -97.7197089244404 },
    { lat: 30.2924344537877, lng: -97.7197176372375 },
    { lat: 30.3128330411872, lng: -97.7197356096679 },
    { lat: 30.2940707070656, lng: -97.7197443521925 },
    { lat: 30.2940579719966, lng: -97.7197524406827 },
    { lat: 30.2911510781231, lng: -97.7197526175922 },
    { lat: 30.3069669537255, lng: -97.7197722655146 },
    { lat: 30.3068661471837, lng: -97.7198353812851 },
    { lat: 30.3115576949289, lng: -97.7199186663348 },
    { lat: 30.3069907758525, lng: -97.7199628777993 },
    { lat: 30.3115918774806, lng: -97.7199872943707 },
    { lat: 30.2972856771918, lng: -97.7199973571624 },
    { lat: 30.3042652565939, lng: -97.7200612002608 },
    { lat: 30.2974820645249, lng: -97.7201336520796 },
    { lat: 30.3038708367659, lng: -97.7201442124936 },
    { lat: 30.2943007783478, lng: -97.7201743783583 },
    { lat: 30.3115546843509, lng: -97.7201782308002 },
    { lat: 30.2987105365926, lng: -97.7201979494613 },
    { lat: 30.3128856315512, lng: -97.7202142646846 },
    { lat: 30.2974597777302, lng: -97.7202238781937 },
    { lat: 30.2974597777302, lng: -97.7202238781937 },
    { lat: 30.3038278924469, lng: -97.7203238749619 },
    { lat: 30.2902440424671, lng: -97.7203345286218 },
    { lat: 30.2901873527384, lng: -97.7203708980611 },
    { lat: 30.2901684561624, lng: -97.7203830212076 },
    { lat: 30.2866261777878, lng: -97.7203938988879 },
    { lat: 30.3061567877713, lng: -97.720418910327 },
    { lat: 30.284148641107, lng: -97.7204212922036 },
    { lat: 30.3085773253953, lng: -97.7204273186778 },
    { lat: 30.3063735738805, lng: -97.7204317130459 },
    { lat: 30.313274168374, lng: -97.7204688715385 },
    { lat: 30.3187627735655, lng: -97.7205163770934 },
    { lat: 30.2866018758037, lng: -97.7205357349591 },
    { lat: 30.305938112976, lng: -97.7205607886231 },
    { lat: 30.2927844650848, lng: -97.7205612897045 },
    { lat: 30.310697400328, lng: -97.7205640611085 },
    { lat: 30.2849388977551, lng: -97.72056687405 },
    { lat: 30.3133313277363, lng: -97.7205839186118 },
    { lat: 30.2840725143245, lng: -97.7206095738525 },
    { lat: 30.292680658043, lng: -97.7206179271251 },
    { lat: 30.288585779485, lng: -97.7206378785181 },
    { lat: 30.314820453514, lng: -97.7206417185757 },
    { lat: 30.3054438670561, lng: -97.7207221849243 },
    { lat: 30.3054669740427, lng: -97.7207712454027 },
    { lat: 30.302504565673, lng: -97.7207983025265 },
    { lat: 30.3074848138836, lng: -97.7208050605633 },
    { lat: 30.3055007633857, lng: -97.7208445452154 },
    { lat: 30.3025329394973, lng: -97.7208614420711 },
    { lat: 30.2860188112929, lng: -97.7208871911234 },
    { lat: 30.2963347779759, lng: -97.7209168780755 },
    { lat: 30.289673166091, lng: -97.7209518367738 },
    { lat: 30.3055567644309, lng: -97.7209603450193 },
    { lat: 30.3028018150908, lng: -97.7209666367091 },
    { lat: 30.2896458427647, lng: -97.7210018260432 },
    { lat: 30.316266896967, lng: -97.7210182876034 },
    { lat: 30.2906207450301, lng: -97.7210700452702 },
    { lat: 30.3121387983086, lng: -97.7210853429456 },
    { lat: 30.3104721778448, lng: -97.7211157505164 },
    { lat: 30.2877750208818, lng: -97.7212473855844 },
    { lat: 30.2852316537642, lng: -97.7212492113121 },
    { lat: 30.3045511219239, lng: -97.7212525488092 },
    { lat: 30.3191248815743, lng: -97.7213184649708 },
    { lat: 30.3101093454464, lng: -97.7213442337727 },
    { lat: 30.2852888766171, lng: -97.72136489193 },
    { lat: 30.3100091805616, lng: -97.7214073453388 },
    { lat: 30.304673572568, lng: -97.7215201374459 },
    { lat: 30.3016745273121, lng: -97.7215341475048 },
    { lat: 30.2869707576131, lng: -97.7215598203077 },
    { lat: 30.2869762729546, lng: -97.7215718468147 },
    { lat: 30.3187118963159, lng: -97.7216164121341 },
    { lat: 30.2853228809015, lng: -97.7217468065533 },
    { lat: 30.2846300056347, lng: -97.7217954994512 },
    { lat: 30.3187760106644, lng: -97.7218392628258 },
    { lat: 30.2934038218682, lng: -97.721843255859 },
    { lat: 30.2877959962759, lng: -97.7218642279217 },
    { lat: 30.2993861005666, lng: -97.7218966196617 },
    { lat: 30.3167169738297, lng: -97.721922461476 },
    { lat: 30.3177611371222, lng: -97.7219284787589 },
    { lat: 30.2933827785916, lng: -97.7219338779646 },
    { lat: 30.3092150279989, lng: -97.7219487551564 },
    { lat: 30.310925441658, lng: -97.7220003248679 },
    { lat: 30.3187231391158, lng: -97.7220047540352 },
    { lat: 30.3187231391158, lng: -97.7220047540352 },
    { lat: 30.2994656881637, lng: -97.7220642841739 },
    { lat: 30.297034936433, lng: -97.722077246063 },
    { lat: 30.3109057751623, lng: -97.7220913770808 },
    { lat: 30.2873698161499, lng: -97.7221388160027 },
    { lat: 30.3034706518318, lng: -97.7221391314162 },
    { lat: 30.3059917761273, lng: -97.7221408773054 },
    { lat: 30.3126716251795, lng: -97.7221881735162 },
    { lat: 30.3080820580411, lng: -97.7222086238957 },
    { lat: 30.3177983390136, lng: -97.7222223024807 },
    { lat: 30.2970238601761, lng: -97.7222328074799 },
    { lat: 30.2996854846556, lng: -97.722251839735 },
    { lat: 30.3141391087916, lng: -97.7222672845425 },
    { lat: 30.3189865703146, lng: -97.7222889158417 },
    { lat: 30.3200517904255, lng: -97.7223244087852 },
    { lat: 30.3153702018115, lng: -97.7223604985046 },
    { lat: 30.2857707646459, lng: -97.7223694422455 },
    { lat: 30.3031587600652, lng: -97.7224884054217 },
    { lat: 30.2986086037146, lng: -97.7225461795184 },
    { lat: 30.2986499575073, lng: -97.7225532679426 },
    { lat: 30.3051759561047, lng: -97.7225782303115 },
    { lat: 30.2955571622796, lng: -97.7226519187059 },
    { lat: 30.2955720959737, lng: -97.7226837470796 },
    { lat: 30.3098670763511, lng: -97.7226865411241 },
    { lat: 30.3021295013124, lng: -97.7227669239638 },
    { lat: 30.3099447360579, lng: -97.7227883514415 },
    { lat: 30.3099229008428, lng: -97.7228024603504 },
    { lat: 30.2974135628139, lng: -97.7228449111054 },
    { lat: 30.3021685702725, lng: -97.7228498820675 },
    { lat: 30.3148820565007, lng: -97.7228725775111 },
    { lat: 30.31133777511, lng: -97.7229778768393 },
    { lat: 30.3191825419889, lng: -97.722985815161 },
    { lat: 30.3144265767173, lng: -97.7230353606313 },
    { lat: 30.2966518543142, lng: -97.7230491749017 },
    { lat: 30.2878042494996, lng: -97.7230493121838 },
    { lat: 30.2966384422245, lng: -97.7230528808744 },
    { lat: 30.2966250301346, lng: -97.7230565868471 },
    { lat: 30.296598205955, lng: -97.7230639987925 },
    { lat: 30.2964760921859, lng: -97.7230894290044 },
    { lat: 30.3092179744203, lng: -97.7232575690781 },
    { lat: 30.3151945341434, lng: -97.7232751342799 },
    { lat: 30.3182830455357, lng: -97.7232911423384 },
    { lat: 30.3148425519821, lng: -97.7233499168121 },
    { lat: 30.2447677533465, lng: -97.7233515586555 },
    { lat: 30.3086356579638, lng: -97.7233897790675 },
    { lat: 30.2914028013341, lng: -97.7234470129188 },
    { lat: 30.3065741752386, lng: -97.7234966392723 },
    { lat: 30.2948630456269, lng: -97.7235239573833 },
    { lat: 30.2448035355282, lng: -97.7235739192927 },
    { lat: 30.2947635549774, lng: -97.7235898067085 },
    { lat: 30.3159159424416, lng: -97.7235939176568 },
    { lat: 30.306629155271, lng: -97.7236116900631 },
    { lat: 30.294827974813, lng: -97.7237203596532 },
    { lat: 30.2948440797717, lng: -97.7237529978895 },
    { lat: 30.3197437984467, lng: -97.7237695885164 },
    { lat: 30.3134692405072, lng: -97.7238090292426 },
    { lat: 30.3119540268448, lng: -97.7238195636703 },
    { lat: 30.3117517750613, lng: -97.7238733765965 },
    { lat: 30.31967177351, lng: -97.7238913762097 },
    { lat: 30.3062094794301, lng: -97.7238967611388 },
    { lat: 30.3061335075674, lng: -97.7239420852638 },
    { lat: 30.3004506478048, lng: -97.7239852480012 },
    { lat: 30.306240243962, lng: -97.7240277086502 },
    { lat: 30.3196942173907, lng: -97.7240760336565 },
    { lat: 30.3135038564788, lng: -97.7242013167323 },
    { lat: 30.3026826407104, lng: -97.7242074113305 },
    { lat: 30.3135074253202, lng: -97.7242081798878 },
    { lat: 30.3028193219165, lng: -97.7242197319739 },
    { lat: 30.302706845775, lng: -97.7242583131497 },
    { lat: 30.2960074141563, lng: -97.7242590021384 },
    { lat: 30.2960211963543, lng: -97.7242860829445 },
    { lat: 30.2960487607502, lng: -97.7243402445565 },
    { lat: 30.3075107570287, lng: -97.7243548243686 },
    { lat: 30.3188099765328, lng: -97.7243607175948 },
    { lat: 30.2943694034408, lng: -97.7245787652128 },
    { lat: 30.2943411201353, lng: -97.724603535065 },
    { lat: 30.2909797791601, lng: -97.7246203774117 },
    { lat: 30.2875307803873, lng: -97.7246329667411 },
    { lat: 30.2942986951772, lng: -97.7246406898432 },
    { lat: 30.2963402213266, lng: -97.7249568883616 },
    { lat: 30.2936757452434, lng: -97.7250198686837 },
    { lat: 30.287999923602, lng: -97.7250201691378 },
    { lat: 30.2877309844449, lng: -97.7250436869864 },
    { lat: 30.3213154565404, lng: -97.7250601885625 },
    { lat: 30.3112723998362, lng: -97.7250800047862 },
    { lat: 30.2964098004278, lng: -97.7251065974707 },
    { lat: 30.293564920863, lng: -97.7251169260637 },
    { lat: 30.3120956177386, lng: -97.7251453080795 },
    { lat: 30.3098932619499, lng: -97.7251846094858 },
    { lat: 30.3119600670597, lng: -97.7251994072614 },
    { lat: 30.3097954066086, lng: -97.7252457690769 },
    { lat: 30.3109101762198, lng: -97.7253085836321 },
    { lat: 30.3071701560612, lng: -97.7254737820029 },
    { lat: 30.3075505839553, lng: -97.7254920369592 },
    { lat: 30.3073007520851, lng: -97.7255020386599 },
    { lat: 30.3111360980708, lng: -97.7255024892766 },
    { lat: 30.3071833211463, lng: -97.7255078395004 },
    { lat: 30.2932918143338, lng: -97.7255125221702 },
    { lat: 30.3034267767549, lng: -97.7255788765735 },
    { lat: 30.3111914179441, lng: -97.7256149836949 },
    { lat: 30.2926358160669, lng: -97.7256708495602 },
    { lat: 30.3014446969415, lng: -97.7256844602026 },
    { lat: 30.3112748655737, lng: -97.7257087334781 },
    { lat: 30.3013978719603, lng: -97.7257094199104 },
    { lat: 30.3175245075362, lng: -97.7257584500021 },
    { lat: 30.2955236740737, lng: -97.7257687092645 },
    { lat: 30.2832361289879, lng: -97.7257898343908 },
    { lat: 30.3049525017549, lng: -97.7258523557645 },
    { lat: 30.2954478518862, lng: -97.7258716717345 },
    { lat: 30.3217286037075, lng: -97.7259166633085 },
    { lat: 30.2905449347497, lng: -97.7259393939232 },
    { lat: 30.3047619774056, lng: -97.7259808153692 },
    { lat: 30.2883144230119, lng: -97.7259961989314 },
    { lat: 30.2905841719149, lng: -97.7260226868397 },
    { lat: 30.3046984692891, lng: -97.7260236352374 },
    { lat: 30.3025207908192, lng: -97.7261114019407 },
    { lat: 30.2946665137345, lng: -97.7261731498132 },
    { lat: 30.3168187782072, lng: -97.7261979891139 },
    { lat: 30.3168187782072, lng: -97.7261979891139 },
    { lat: 30.3168187782072, lng: -97.7261979891139 },
    { lat: 30.3168055948154, lng: -97.7262061691706 },
    { lat: 30.3080031889437, lng: -97.7262294733315 },
    { lat: 30.2947202526381, lng: -97.7262903658914 },
    { lat: 30.3167283009659, lng: -97.7264006806348 },
    { lat: 30.3167338328055, lng: -97.726414310629 },
    { lat: 30.2920677838156, lng: -97.726545166832 },
    { lat: 30.3038681854524, lng: -97.7266755304556 },
    { lat: 30.3035523680271, lng: -97.7267052221109 },
    { lat: 30.2937146838859, lng: -97.7267062438737 },
    { lat: 30.2955869246026, lng: -97.7267343404266 },
    { lat: 30.2907956730053, lng: -97.7267487627354 },
    { lat: 30.2938147786826, lng: -97.7267803767377 },
    { lat: 30.2909471156909, lng: -97.7267931463173 },
    { lat: 30.3200360017279, lng: -97.7268213248274 },
    { lat: 30.3188283697223, lng: -97.7268374594077 },
    { lat: 30.3073390420433, lng: -97.7268409331575 },
    { lat: 30.2967155274859, lng: -97.7268512749654 },
    { lat: 30.2956278621148, lng: -97.7268582978006 },
    { lat: 30.3199713441451, lng: -97.7268650829914 },
    { lat: 30.2967540578679, lng: -97.7268684760258 },
    { lat: 30.3050710051091, lng: -97.726869700062 },
    { lat: 30.2909863528558, lng: -97.7268764392337 },
    { lat: 30.2910255900209, lng: -97.7269597321502 },
    { lat: 30.2910353993122, lng: -97.7269805553794 },
    { lat: 30.3107040567202, lng: -97.7269833967572 },
    { lat: 30.3029387165044, lng: -97.7269835688242 },
    { lat: 30.3087170623588, lng: -97.72704033833 },
    { lat: 30.3199279651738, lng: -97.7270459477466 },
    { lat: 30.295020778456, lng: -97.7270503766125 },
    { lat: 30.3066644936271, lng: -97.7270812477239 },
    { lat: 30.3031356987104, lng: -97.7271211141591 },
    { lat: 30.3088098027104, lng: -97.7271335229149 },
    { lat: 30.3068342944951, lng: -97.727166767828 },
    { lat: 30.3084836933757, lng: -97.7271842317682 },
    { lat: 30.2929878728227, lng: -97.7271914410621 },
    { lat: 30.3067355810111, lng: -97.7272295855025 },
    { lat: 30.3067355810111, lng: -97.7272295855025 },
    { lat: 30.2931078571804, lng: -97.7272697897586 },
    { lat: 30.2904383383552, lng: -97.7272942818343 },
    { lat: 30.3082083187271, lng: -97.7273510861965 },
    { lat: 30.3192918932254, lng: -97.727529997855 },
    { lat: 30.2923503872949, lng: -97.7277144841801 },
    { lat: 30.290364079133, lng: -97.7277379273794 },
    { lat: 30.3040472932189, lng: -97.7277392832697 },
    { lat: 30.3096517579226, lng: -97.7278289910749 },
    { lat: 30.3094057092663, lng: -97.7278380727654 },
    { lat: 30.3112421120915, lng: -97.7279413978001 },
    { lat: 30.3071227688537, lng: -97.7280416372547 },
    { lat: 30.3111487753324, lng: -97.7280808755784 },
    { lat: 30.3072788246832, lng: -97.7281396884193 },
    { lat: 30.3014344287441, lng: -97.7281976873567 },
    { lat: 30.3029337283185, lng: -97.7283106767671 },
    { lat: 30.2893214545902, lng: -97.7283410547573 },
    { lat: 30.2893214545902, lng: -97.7283410547573 },
    { lat: 30.2892839896062, lng: -97.7283644703734 },
    { lat: 30.3030102861407, lng: -97.7284130781926 },
    { lat: 30.2893547308033, lng: -97.7284710569933 },
    { lat: 30.3087446331423, lng: -97.7284813007513 },
    { lat: 30.2880916086718, lng: -97.7284832153692 },
    { lat: 30.3177060938506, lng: -97.728491448783 },
    { lat: 30.2982068093527, lng: -97.7285318869157 },
    { lat: 30.2892355546684, lng: -97.7285536077231 },
    { lat: 30.30050771471, lng: -97.7286122156004 },
    { lat: 30.322206293692, lng: -97.7286261377562 },
    { lat: 30.3045810473208, lng: -97.7286262809969 },
    { lat: 30.2917364901632, lng: -97.7286408676987 },
    { lat: 30.3061168900621, lng: -97.7287120948481 },
    { lat: 30.2882405473685, lng: -97.7287138754126 },
    { lat: 30.2919449597118, lng: -97.7287229867416 },
    { lat: 30.2919128348229, lng: -97.7287450610302 },
    { lat: 30.3058712006815, lng: -97.7288226875947 },
    { lat: 30.3001369166984, lng: -97.7288431656284 },
    { lat: 30.278723221667, lng: -97.7289090622173 },
    { lat: 30.2786104593855, lng: -97.7289626705186 },
    { lat: 30.3075968533628, lng: -97.729002750586 },
    { lat: 30.3098030568538, lng: -97.7290042882023 },
    { lat: 30.3097641520477, lng: -97.7290287999759 },
    { lat: 30.3077318668736, lng: -97.7290658883415 },
    { lat: 30.2961158015181, lng: -97.7290962981485 },
    { lat: 30.3076734780957, lng: -97.7291048141952 },
    { lat: 30.2909981185346, lng: -97.7291518703369 },
    { lat: 30.3017347772164, lng: -97.7291563757648 },
    { lat: 30.2910124375899, lng: -97.7291826392549 },
    { lat: 30.2818185983972, lng: -97.7292479099161 },
    { lat: 30.3033064363763, lng: -97.7292611794157 },
    { lat: 30.2910840328668, lng: -97.7293364838442 },
    { lat: 30.2974137699078, lng: -97.7293915405251 },
    { lat: 30.319279453831, lng: -97.7294168031489 },
    { lat: 30.2432248462923, lng: -97.7294763433672 },
    { lat: 30.290435725517, lng: -97.7295298783369 },
    { lat: 30.2989273060915, lng: -97.7295682097654 },
    { lat: 30.3049171376401, lng: -97.7295937916401 },
    { lat: 30.288997610805, lng: -97.7295964144227 },
    { lat: 30.3182156465658, lng: -97.7296206692436 },
    { lat: 30.2985976752311, lng: -97.7296975317353 },
    { lat: 30.2953046175656, lng: -97.7297351603525 },
    { lat: 30.3203622675131, lng: -97.7297582983001 },
    { lat: 30.2987883090463, lng: -97.7298046492795 },
    { lat: 30.292353726712, lng: -97.7299102330013 },
    { lat: 30.2926143432696, lng: -97.7299143083222 },
    { lat: 30.3120851019454, lng: -97.7299148639802 },
    { lat: 30.2940679061418, lng: -97.7299219767095 },
    { lat: 30.3065331536807, lng: -97.7299270692721 },
    { lat: 30.3120547410387, lng: -97.7299379364605 },
    { lat: 30.3199695568626, lng: -97.7299965587369 },
    { lat: 30.2942580358831, lng: -97.7300368904226 },
    { lat: 30.2925682681773, lng: -97.730087344114 },
    { lat: 30.2943035535448, lng: -97.7301276700153 },
    { lat: 30.3038204715804, lng: -97.7301770960016 },
    { lat: 30.2978658990282, lng: -97.730232023968 },
    { lat: 30.2943589754028, lng: -97.7302502968785 },
    { lat: 30.297832542132, lng: -97.7302529537861 },
    { lat: 30.3036730242307, lng: -97.730254506766 },
    { lat: 30.3095331728486, lng: -97.7303525716714 },
    { lat: 30.2449008560158, lng: -97.7303938348647 },
    { lat: 30.2892908104463, lng: -97.730408212955 },
    { lat: 30.2448277884224, lng: -97.7304343781821 },
    { lat: 30.2448277884224, lng: -97.7304343781821 },
    { lat: 30.3055148772915, lng: -97.7304737753598 },
    { lat: 30.3054937969515, lng: -97.7304868250947 },
    { lat: 30.2955050950669, lng: -97.730535956136 },
    { lat: 30.2990587481114, lng: -97.7306325073323 },
    { lat: 30.2971946572831, lng: -97.7306658642444 },
    { lat: 30.3105192011731, lng: -97.7307178760108 },
    { lat: 30.3187166998297, lng: -97.7307279425638 },
    { lat: 30.3008877571806, lng: -97.7307324806512 },
    { lat: 30.3069421945166, lng: -97.7307815781694 },
    { lat: 30.2418118717045, lng: -97.7307874727337 },
    { lat: 30.3124087751843, lng: -97.7308078748391 },
    { lat: 30.2901964024715, lng: -97.7308259044768 },
    { lat: 30.3027671829453, lng: -97.730848388983 },
    { lat: 30.2442311808134, lng: -97.7308742395186 },
    { lat: 30.2455895225185, lng: -97.7308839338875 },
    { lat: 30.3027033578491, lng: -97.7308888450496 },
    { lat: 30.2452086160143, lng: -97.7309041707014 },
    { lat: 30.2929641239933, lng: -97.7309066729745 },
    { lat: 30.2970061349719, lng: -97.7309303051071 },
    { lat: 30.2431382076005, lng: -97.7309470012473 },
    { lat: 30.2930945053859, lng: -97.7309824386041 },
    { lat: 30.3026167771112, lng: -97.7310193752587 },
    { lat: 30.2917962029652, lng: -97.7310791348662 },
    { lat: 30.2918163343788, lng: -97.7311201231437 },
    { lat: 30.304222766107, lng: -97.7311540445342 },
    { lat: 30.2919964648418, lng: -97.7312184609282 },
    { lat: 30.2944812722096, lng: -97.7312246745712 },
    { lat: 30.3042007768088, lng: -97.7312443751265 },
    { lat: 30.3042007768088, lng: -97.7312443751265 },
    { lat: 30.3042007768088, lng: -97.7312443751265 },
    { lat: 30.3042007768088, lng: -97.7312443751265 },
    { lat: 30.3042787644985, lng: -97.7312698446119 },
    { lat: 30.3042787644985, lng: -97.7312698446119 },
    { lat: 30.2945792556701, lng: -97.7313132238591 },
    { lat: 30.2408485564255, lng: -97.7313681011492 },
    { lat: 30.2433872467596, lng: -97.7313845011116 },
    { lat: 30.2919572542745, lng: -97.7314070410862 },
    { lat: 30.299704913967, lng: -97.7314272202274 },
    { lat: 30.3176852129555, lng: -97.73143242298 },
    { lat: 30.2996809175852, lng: -97.7314413307765 },
    { lat: 30.3074707667485, lng: -97.7314622921128 },
    { lat: 30.2921172533237, lng: -97.7314643905933 },
    { lat: 30.3114079146155, lng: -97.7314927659554 },
    { lat: 30.2961241262699, lng: -97.731497337756 },
    { lat: 30.2920957791928, lng: -97.7315548756325 },
    { lat: 30.2894139374306, lng: -97.7315834899791 },
    { lat: 30.272439757557, lng: -97.7315878082461 },
    { lat: 30.3072967762168, lng: -97.7316493748763 },
    { lat: 30.3014362798987, lng: -97.7316907947964 },
    { lat: 30.298116778018, lng: -97.7317033753055 },
    { lat: 30.2936206759854, lng: -97.7317829952871 },
    { lat: 30.24456493175, lng: -97.7318145701063 },
    { lat: 30.2459900577287, lng: -97.7318200459809 },
    { lat: 30.2445382496313, lng: -97.7318309509015 },
    { lat: 30.2935367153783, lng: -97.7318374656009 },
    { lat: 30.303071991611, lng: -97.7318744577031 },
    { lat: 30.2893928814321, lng: -97.7318967112822 },
    { lat: 30.3032551215063, lng: -97.7319108179234 },
    { lat: 30.2460974159656, lng: -97.7319617588344 },
    { lat: 30.2713534653597, lng: -97.732010411737 },
    { lat: 30.2462237068048, lng: -97.732035671274 },
    { lat: 30.2463217881881, lng: -97.7320588777062 },
    { lat: 30.24165656149, lng: -97.7321028512265 },
    { lat: 30.2910112383039, lng: -97.7321262122423 },
    { lat: 30.245699813837, lng: -97.7321714568291 },
    { lat: 30.3167017743929, lng: -97.7321758742915 },
    { lat: 30.2970864544065, lng: -97.7322051658692 },
    { lat: 30.2707696739615, lng: -97.732235734752 },
    { lat: 30.2947384967576, lng: -97.7322507574209 },
    { lat: 30.2438227823976, lng: -97.7322720013793 },
    { lat: 30.3159457962268, lng: -97.7323378387403 },
    { lat: 30.2704193991226, lng: -97.7323709285612 },
    { lat: 30.2703610199828, lng: -97.7323934608627 },
    { lat: 30.3150797408611, lng: -97.7324132018459 },
    { lat: 30.3062569594221, lng: -97.7324204240503 },
    { lat: 30.3002790626991, lng: -97.7324222626128 },
    { lat: 30.3004293712056, lng: -97.7324782264477 },
    { lat: 30.2893509119224, lng: -97.7325105156554 },
    { lat: 30.300128500207, lng: -97.7325177919225 },
    { lat: 30.3137267273339, lng: -97.7325229839328 },
    { lat: 30.3135859426716, lng: -97.7325344332941 },
    { lat: 30.2453089426052, lng: -97.7325671549393 },
    { lat: 30.3077377761637, lng: -97.7325673746266 },
    { lat: 30.3002818814173, lng: -97.7325718061797 },
    { lat: 30.301900899516, lng: -97.7326300899532 },
    { lat: 30.3114718407733, lng: -97.7326606134577 },
    { lat: 30.2986505427643, lng: -97.7327191015726 },
    { lat: 30.3019785626742, lng: -97.7327318887498 },
    { lat: 30.3092947758648, lng: -97.732738374509 },
    { lat: 30.2986882795391, lng: -97.7327792619472 },
    { lat: 30.2985494842441, lng: -97.7327816691572 },
    { lat: 30.2961615974024, lng: -97.7328265200374 },
    { lat: 30.2985792380372, lng: -97.7328461356992 },
    { lat: 30.2937636734099, lng: -97.7328528983972 },
    { lat: 30.2751589143276, lng: -97.7329531268906 },
    { lat: 30.3109221432873, lng: -97.7329957795606 },
    { lat: 30.3014218048384, lng: -97.7330804587384 },
    { lat: 30.3051248461339, lng: -97.7330920061658 },
    { lat: 30.2895609597289, lng: -97.7332048008812 },
    { lat: 30.3110517151437, lng: -97.7332235752105 },
    { lat: 30.2444237941803, lng: -97.7332646024875 },
    { lat: 30.3066217764096, lng: -97.7333143744945 },
    { lat: 30.2423918254256, lng: -97.7333240644495 },
    { lat: 30.2425489330725, lng: -97.7333750845168 },
    { lat: 30.2988232522496, lng: -97.7334007022886 },
    { lat: 30.2976905042961, lng: -97.7334018910769 },
    { lat: 30.2976705698653, lng: -97.7334129864487 },
    { lat: 30.3110315010942, lng: -97.7334292943357 },
    { lat: 30.2930162413208, lng: -97.7334659356042 },
    { lat: 30.308214776103, lng: -97.7334673743797 },
    { lat: 30.2952040300584, lng: -97.7334720189388 },
    { lat: 30.3082931275652, lng: -97.733491300191 },
    { lat: 30.297648778175, lng: -97.7335033748801 },
    { lat: 30.2435216456917, lng: -97.7335075985015 },
    { lat: 30.3004514928093, lng: -97.7335201789691 },
    { lat: 30.309833257698, lng: -97.7335244879358 },
    { lat: 30.2951102087477, lng: -97.7335332282544 },
    { lat: 30.2464135683865, lng: -97.7335626548868 },
    { lat: 30.2436811594328, lng: -97.7335710692057 },
    { lat: 30.2926717791543, lng: -97.7336068750941 },
    { lat: 30.2951712211014, lng: -97.7336483943207 },
    { lat: 30.2381917710959, lng: -97.7336671745183 },
    { lat: 30.3041409497753, lng: -97.733724549742 },
    { lat: 30.2999447653646, lng: -97.733828984204 },
    { lat: 30.2477157709719, lng: -97.7338554106363 },
    { lat: 30.3112856121173, lng: -97.7338738792952 },
    { lat: 30.2385411875587, lng: -97.7338966944467 },
    { lat: 30.315991845537, lng: -97.7339107353818 },
    { lat: 30.2918854041479, lng: -97.7339400907735 },
    { lat: 30.2917767341782, lng: -97.7339869111915 },
    { lat: 30.296753579869, lng: -97.7340052167631 },
    { lat: 30.3072380672126, lng: -97.7340090525368 },
    { lat: 30.2965489624958, lng: -97.7340509409619 },
    { lat: 30.2477719386668, lng: -97.73405901849 },
    { lat: 30.2477719386668, lng: -97.73405901849 },
    { lat: 30.2448667071172, lng: -97.734069115157 },
    { lat: 30.2965808025571, lng: -97.7341168598285 },
    { lat: 30.2967912026432, lng: -97.7341328688889 },
    { lat: 30.3088294720114, lng: -97.7341580272603 },
    { lat: 30.2365585686099, lng: -97.734171266767 },
    { lat: 30.2936202503398, lng: -97.7341961714256 },
    { lat: 30.2936202503398, lng: -97.7341961714256 },
    { lat: 30.2936202503398, lng: -97.7341961714256 },
    { lat: 30.3071675152738, lng: -97.7342048451446 },
    { lat: 30.2993518269663, lng: -97.7342233636114 },
    { lat: 30.2680270527391, lng: -97.7342654558202 },
    { lat: 30.2935226456869, lng: -97.7342868043206 },
    { lat: 30.2475901505078, lng: -97.7343109140288 },
    { lat: 30.27540759986, lng: -97.7343520456891 },
    { lat: 30.2795355171761, lng: -97.7343586855172 },
    { lat: 30.3117010337543, lng: -97.7343596970959 },
    { lat: 30.2476048864721, lng: -97.7343698578737 },
    { lat: 30.2944145154539, lng: -97.7343830966733 },
    { lat: 30.2709798629529, lng: -97.7343838161303 },
    { lat: 30.2910958911584, lng: -97.7344061455136 },
    { lat: 30.2993000892865, lng: -97.7344079684696 },
    { lat: 30.3028113799931, lng: -97.7345563747408 },
    { lat: 30.304437046525, lng: -97.734589133718 },
    { lat: 30.2893640393169, lng: -97.7346054390728 },
    { lat: 30.3027107727191, lng: -97.734619363826 },
    { lat: 30.316044390014, lng: -97.7346214408009 },
    { lat: 30.2669937438553, lng: -97.7346658222641 },
    { lat: 30.295727992187, lng: -97.734676932853 },
    { lat: 30.2789486493275, lng: -97.7347129112693 },
    { lat: 30.3044737818532, lng: -97.7347816751978 },
    { lat: 30.2477300381344, lng: -97.7347973126209 },
    { lat: 30.3044818360617, lng: -97.734798589033 },
    { lat: 30.2376947216654, lng: -97.7348655799385 },
    { lat: 30.2912359632533, lng: -97.7348754293262 },
    { lat: 30.2725742814358, lng: -97.7349011437527 },
    { lat: 30.2982848187366, lng: -97.7349040014298 },
    { lat: 30.297076610284, lng: -97.7349339993409 },
    { lat: 30.2983687780858, lng: -97.7349343744892 },
    { lat: 30.301258842543, lng: -97.7349523979883 },
    { lat: 30.2740036466313, lng: -97.7349685572521 },
    { lat: 30.2740680299849, lng: -97.7349789722027 },
    { lat: 30.2969775112399, lng: -97.7349961147505 },
    { lat: 30.2699606865689, lng: -97.7350123273004 },
    { lat: 30.2660356780644, lng: -97.7350152647317 },
    { lat: 30.2659504230165, lng: -97.7350457129659 },
    { lat: 30.2679004962818, lng: -97.7350805173994 },
    { lat: 30.2951719494498, lng: -97.7350919299021 },
    { lat: 30.2658257404179, lng: -97.735093077186 },
    { lat: 30.2938869393126, lng: -97.7350948345892 },
    { lat: 30.2679056602033, lng: -97.7350974937879 },
    { lat: 30.2680292356362, lng: -97.7351043479628 },
    { lat: 30.2351934931609, lng: -97.73515054308 },
    { lat: 30.2950672455976, lng: -97.7352088191631 },
    { lat: 30.2981611851095, lng: -97.7352473127334 },
    { lat: 30.2415966693342, lng: -97.7352548556344 },
    { lat: 30.2710580722698, lng: -97.7352708493144 },
    { lat: 30.2998484092065, lng: -97.7352900303149 },
    { lat: 30.2366329269974, lng: -97.7353041836024 },
    { lat: 30.3158109356984, lng: -97.7353480815476 },
    { lat: 30.2651804232309, lng: -97.7353493947814 },
    { lat: 30.3032420246245, lng: -97.7353511012208 },
    { lat: 30.3013550629517, lng: -97.7354087805291 },
    { lat: 30.3013645663652, lng: -97.7354282789091 },
    { lat: 30.267090557305, lng: -97.7354381982668 },
    { lat: 30.3013740697788, lng: -97.7354477772891 },
    { lat: 30.3013835731923, lng: -97.7354672756691 },
    { lat: 30.3031747771632, lng: -97.7354698741244 },
    { lat: 30.2712422474779, lng: -97.7354915951316 },
    { lat: 30.3032526232473, lng: -97.7354959180665 },
    { lat: 30.2669910919217, lng: -97.7355148555504 },
    { lat: 30.3049049689674, lng: -97.7355175402271 },
    { lat: 30.2998342151783, lng: -97.7355358836641 },
    { lat: 30.2961053262651, lng: -97.7355570458631 },
    { lat: 30.3031530694066, lng: -97.7355602952887 },
    { lat: 30.2660943904062, lng: -97.735567243703 },
    { lat: 30.2894297528313, lng: -97.7355893754526 },
    { lat: 30.3065696762335, lng: -97.7356067223694 },
    { lat: 30.2670161049417, lng: -97.7356084526378 },
    { lat: 30.2671405833446, lng: -97.7356253924416 },
    { lat: 30.2976726653352, lng: -97.7356337770351 },
    { lat: 30.2661208481675, lng: -97.7356634537249 },
    { lat: 30.3049000527976, lng: -97.7356741283342 },
    { lat: 30.3006516492221, lng: -97.7356983163256 },
    { lat: 30.2610250629496, lng: -97.7356993414312 },
    { lat: 30.2610250629496, lng: -97.7356993414312 },
    { lat: 30.3064154435434, lng: -97.7357016979887 },
    { lat: 30.2474882205593, lng: -97.735703356778 },
    { lat: 30.2474882205593, lng: -97.735703356778 },
    { lat: 30.2944370446781, lng: -97.7357270429108 },
    { lat: 30.2641168909711, lng: -97.7357480655082 },
    { lat: 30.2640969474084, lng: -97.7357555443449 },
    { lat: 30.2609917854449, lng: -97.7357848760755 },
    { lat: 30.2893300005735, lng: -97.7358035647478 },
    { lat: 30.240707159672, lng: -97.7358066588824 },
    { lat: 30.3005834741662, lng: -97.735829757916 },
    { lat: 30.3183859623259, lng: -97.7358406781184 },
    { lat: 30.2407256130973, lng: -97.7358424359261 },
    { lat: 30.2692846090546, lng: -97.7358822473355 },
    { lat: 30.2933228906618, lng: -97.7359479069257 },
    { lat: 30.3022683554163, lng: -97.7359719434832 },
    { lat: 30.2663671053576, lng: -97.7359891245122 },
    { lat: 30.2694507816998, lng: -97.736021561951 },
    { lat: 30.2632819513175, lng: -97.7360712661943 },
    { lat: 30.2967872141583, lng: -97.7360732213322 },
    { lat: 30.2969269600706, lng: -97.7361125528906 },
    { lat: 30.2969385167984, lng: -97.7361433141758 },
    { lat: 30.2986747780701, lng: -97.7361538741712 },
    { lat: 30.2692987838292, lng: -97.7361538755847 },
    { lat: 30.2662474229669, lng: -97.7361707128751 },
    { lat: 30.2693718584447, lng: -97.7361944120809 },
    { lat: 30.2428827407587, lng: -97.7361955420585 },
    { lat: 30.295083999259, lng: -97.7362411202686 },
    { lat: 30.295083999259, lng: -97.7362411202686 },
    { lat: 30.2751099707628, lng: -97.7362516226442 },
    { lat: 30.2968565545257, lng: -97.7362577890436 },
    { lat: 30.2999418995621, lng: -97.7362591893052 },
    { lat: 30.294882116398, lng: -97.7362945024439 },
    { lat: 30.3054799363789, lng: -97.7363005947962 },
    { lat: 30.3018607774513, lng: -97.7363113739784 },
    { lat: 30.2380583155301, lng: -97.7363140591566 },
    { lat: 30.2347957473872, lng: -97.7363266553126 },
    { lat: 30.2781510952136, lng: -97.7363272250095 },
    { lat: 30.3019497071354, lng: -97.7363298907607 },
    { lat: 30.2641457558337, lng: -97.7363365388151 },
    { lat: 30.2651520187233, lng: -97.7363718752232 },
    { lat: 30.2713741202695, lng: -97.7363741830494 },
    { lat: 30.2684534398871, lng: -97.7363743016133 },
    { lat: 30.2684182514153, lng: -97.7363863569243 },
    { lat: 30.2683654687074, lng: -97.7364044398908 },
    { lat: 30.271504277154, lng: -97.7364076086496 },
    { lat: 30.3128723068008, lng: -97.7364139891478 },
    { lat: 30.26417393191, lng: -97.7364333940587 },
    { lat: 30.26417393191, lng: -97.7364333940587 },
    { lat: 30.3052671273442, lng: -97.7364371612301 },
    { lat: 30.3036167588207, lng: -97.736511092631 },
    { lat: 30.2653116673591, lng: -97.736512173233 },
    { lat: 30.2959876598823, lng: -97.7365183184963 },
    { lat: 30.2369285011416, lng: -97.736530197084 },
    { lat: 30.2344799308431, lng: -97.73653103472 },
    { lat: 30.2482777070374, lng: -97.7365539391242 },
    { lat: 30.2620009290807, lng: -97.7365559132171 },
    { lat: 30.3128260604357, lng: -97.7365739771107 },
    { lat: 30.2483086567243, lng: -97.736599074078 },
    { lat: 30.267283002571, lng: -97.7366068170634 },
    { lat: 30.2905825695119, lng: -97.7366091831663 },
    { lat: 30.2961641281856, lng: -97.7366171239717 },
    { lat: 30.2617844133002, lng: -97.7366352620756 },
    { lat: 30.3009686102178, lng: -97.7366360498152 },
    { lat: 30.2345383304767, lng: -97.7366449412488 },
    { lat: 30.2616870212971, lng: -97.7366701389448 },
    { lat: 30.2674222536025, lng: -97.7366771082332 },
    { lat: 30.2675712602955, lng: -97.7366852332808 },
    { lat: 30.2643848512773, lng: -97.7366909288734 },
    { lat: 30.2674322367216, lng: -97.7367138203417 },
    { lat: 30.2372918332791, lng: -97.7367200236713 },
    { lat: 30.299015931748, lng: -97.7367244059973 },
    { lat: 30.248424059981, lng: -97.73673220902 },
    { lat: 30.2674378030467, lng: -97.736732526388 },
    { lat: 30.280223719111, lng: -97.7367343052279 },
    { lat: 30.2704374871139, lng: -97.7367437163783 },
    { lat: 30.2704430084761, lng: -97.7367633659282 },
    { lat: 30.237537978161, lng: -97.7368033953076 },
    { lat: 30.2673997842256, lng: -97.7368153755114 },
    { lat: 30.2885516236156, lng: -97.7368328041182 },
    { lat: 30.2441817101775, lng: -97.7368919078384 },
    { lat: 30.2664615763913, lng: -97.7369334506953 },
    { lat: 30.2663418761432, lng: -97.736936126868 },
    { lat: 30.3002658863516, lng: -97.7369398586954 },
    { lat: 30.2731087524091, lng: -97.7370178316435 },
    { lat: 30.2439170967324, lng: -97.7370321622304 },
    { lat: 30.3043518518143, lng: -97.7370339861985 },
    { lat: 30.2438937954367, lng: -97.7370471054534 },
    { lat: 30.3043298280013, lng: -97.7370483605199 },
    { lat: 30.2664989621056, lng: -97.7370671329199 },
    { lat: 30.2982599788802, lng: -97.7370859867787 },
    { lat: 30.2665414857815, lng: -97.7370909546051 },
    { lat: 30.2665096437382, lng: -97.7371053278412 },
    { lat: 30.27637261256, lng: -97.7371197944157 },
    { lat: 30.3003769675368, lng: -97.7371732310578 },
    { lat: 30.2352422018103, lng: -97.7372171622068 },
    { lat: 30.3137790070879, lng: -97.7372211204743 },
    { lat: 30.2352361090771, lng: -97.7372215240502 },
    { lat: 30.2492240596108, lng: -97.7372257363275 },
    { lat: 30.2665457280851, lng: -97.7372297257479 },
    { lat: 30.2753870884487, lng: -97.7373394461239 },
    { lat: 30.3136349450197, lng: -97.7373530059326 },
    { lat: 30.2597743407192, lng: -97.7373662349807 },
    { lat: 30.2942742589668, lng: -97.7373727538677 },
    { lat: 30.2686549336663, lng: -97.7374007059646 },
    { lat: 30.2596828821786, lng: -97.7374008849506 },
    { lat: 30.2717804342557, lng: -97.7374018119231 },
    { lat: 30.2595923458075, lng: -97.737435044382 },
    { lat: 30.2985073139493, lng: -97.7374360144426 },
    { lat: 30.2686662887073, lng: -97.7374394753115 },
    { lat: 30.3013537462693, lng: -97.7374423417143 },
    { lat: 30.2654641554923, lng: -97.7374442814197 },
    { lat: 30.2595319882267, lng: -97.7374578173362 },
    { lat: 30.2686776437485, lng: -97.7374782446583 },
    { lat: 30.2942199231616, lng: -97.7375022581531 },
    { lat: 30.2685691227966, lng: -97.737520855154 },
    { lat: 30.3035142158205, lng: -97.7375538921438 },
    { lat: 30.3135396467891, lng: -97.7375973176534 },
    { lat: 30.2942785400099, lng: -97.7375986133789 },
    { lat: 30.2675767432046, lng: -97.7376950624691 },
    { lat: 30.2675874729756, lng: -97.7377348926717 },
    { lat: 30.2587775184673, lng: -97.7377424792645 },
    { lat: 30.303175799113, lng: -97.7377784130725 },
    { lat: 30.2462412453295, lng: -97.7377915986264 },
    { lat: 30.2744956557174, lng: -97.737829369978 },
    { lat: 30.2677344206481, lng: -97.7378347923169 },
    { lat: 30.2916923035803, lng: -97.7378408768993 },
    { lat: 30.2646277848062, lng: -97.7378413753892 },
    { lat: 30.2584757305635, lng: -97.7378563440357 },
    { lat: 30.2584455517732, lng: -97.7378677305129 },
    { lat: 30.2899483132295, lng: -97.737883894576 },
    { lat: 30.2583851941924, lng: -97.7378905034671 },
    { lat: 30.2898637075156, lng: -97.7378924247853 },
    { lat: 30.2805611412035, lng: -97.737892828611 },
    { lat: 30.2995297779667, lng: -97.7379223736899 },
    { lat: 30.2667543888341, lng: -97.7379532429551 },
    { lat: 30.2677689806346, lng: -97.7379604683953 },
    { lat: 30.3028812614272, lng: -97.7379771373028 },
    { lat: 30.2900851604729, lng: -97.7380033068212 },
    { lat: 30.2636760640322, lng: -97.7380139267273 },
    { lat: 30.289958251902, lng: -97.7380161021353 },
    { lat: 30.2638856133544, lng: -97.7380534138776 },
    { lat: 30.3027634463529, lng: -97.738056626995 },
    { lat: 30.2445337798931, lng: -97.7380615773375 },
    { lat: 30.2565314722042, lng: -97.7380644742281 },
    { lat: 30.2667929027107, lng: -97.7380863423561 },
    { lat: 30.2564203435734, lng: -97.7380997288823 },
    { lat: 30.2637466173941, lng: -97.7381028643682 },
    { lat: 30.2765259366744, lng: -97.738126490467 },
    { lat: 30.2503474849127, lng: -97.7381392177757 },
    { lat: 30.2397792875244, lng: -97.7381584979834 },
    { lat: 30.2397792875244, lng: -97.7381584979834 },
    { lat: 30.2699299095288, lng: -97.7381809826003 },
    { lat: 30.2807680750601, lng: -97.7382001165679 },
    { lat: 30.2667062553161, lng: -97.7382045472987 },
    { lat: 30.2806584158617, lng: -97.738238628729 },
    { lat: 30.2494631764866, lng: -97.7382391245569 },
    { lat: 30.2637363827195, lng: -97.7382453006109 },
    { lat: 30.2700998026738, lng: -97.7382573869917 },
    { lat: 30.2990861047775, lng: -97.7383040687762 },
    { lat: 30.2699648065413, lng: -97.7383073615261 },
    { lat: 30.2598234295077, lng: -97.7383253395307 },
    { lat: 30.3009342323046, lng: -97.7383499031369 },
    { lat: 30.2600731030029, lng: -97.7383705201773 },
    { lat: 30.2503814165521, lng: -97.738384574201 },
    { lat: 30.3121646650396, lng: -97.7384109755805 },
    { lat: 30.2989810977454, lng: -97.7384127713235 },
    { lat: 30.3121128202903, lng: -97.7384476420987 },
    { lat: 30.2689462661315, lng: -97.7384480887475 },
    { lat: 30.265868744788, lng: -97.7384650784459 },
    { lat: 30.2567883698131, lng: -97.7384805095581 },
    { lat: 30.2658798036257, lng: -97.7385049227795 },
    { lat: 30.2947218918707, lng: -97.7385173470475 },
    { lat: 30.2797301309973, lng: -97.7385406240863 },
    { lat: 30.3028853178775, lng: -97.7385414793616 },
    { lat: 30.2616584013196, lng: -97.7385541048557 },
    { lat: 30.2565714350607, lng: -97.738559120657 },
    { lat: 30.2629177125526, lng: -97.7385606309451 },
    { lat: 30.2720991660897, lng: -97.7385681347988 },
    { lat: 30.269158117676, lng: -97.7385946748848 },
    { lat: 30.2660158983796, lng: -97.7385946847891 },
    { lat: 30.265999026525, lng: -97.7386008495058 },
    { lat: 30.3030506741484, lng: -97.7386013888113 },
    { lat: 30.2560727511409, lng: -97.7386025713587 },
    { lat: 30.2459527135506, lng: -97.7386130221135 },
    { lat: 30.3019867775108, lng: -97.7386288733957 },
    { lat: 30.3020893385045, lng: -97.7386366216807 },
    { lat: 30.2593594138838, lng: -97.738640294688 },
    { lat: 30.3020650991222, lng: -97.738652928175 },
    { lat: 30.2616904322305, lng: -97.7386703460418 },
    { lat: 30.249635911818, lng: -97.7386828171981 },
    { lat: 30.2721336875836, lng: -97.7386946275423 },
    { lat: 30.2618282584589, lng: -97.738712456435 },
    { lat: 30.2486129981093, lng: -97.7387214236559 },
    { lat: 30.2497626482618, lng: -97.7387554626061 },
    { lat: 30.2972136916368, lng: -97.7387554952459 },
    { lat: 30.2971841057394, lng: -97.7387605464979 },
    { lat: 30.2617837853983, lng: -97.7387908752896 },
    { lat: 30.2486378619618, lng: -97.7387995671783 },
    { lat: 30.261229917395, lng: -97.7388041591285 },
    { lat: 30.3015689575826, lng: -97.7388155609027 },
    { lat: 30.2497519038869, lng: -97.7388200854129 },
    { lat: 30.3015508644896, lng: -97.7388269134322 },
    { lat: 30.2431087890636, lng: -97.7388448761719 },
    { lat: 30.267913519227, lng: -97.7388517991559 },
    { lat: 30.2680383381392, lng: -97.738864071081 },
    { lat: 30.2680399806498, lng: -97.7388650263897 },
    { lat: 30.2611478898312, lng: -97.7389378886672 },
    { lat: 30.2680027841845, lng: -97.7389483749518 },
    { lat: 30.2681582211584, lng: -97.7389606548599 },
    { lat: 30.2650155117281, lng: -97.7389852394909 },
    { lat: 30.2577678548186, lng: -97.7390220053982 },
    { lat: 30.2918921370486, lng: -97.7390259016943 },
    { lat: 30.2613154346291, lng: -97.7390342750529 },
    { lat: 30.2670476726391, lng: -97.7390365306568 },
    { lat: 30.2917816999369, lng: -97.7390800170721 },
    { lat: 30.2896909466087, lng: -97.7390954191252 },
    { lat: 30.2670684440228, lng: -97.7391150724356 },
    { lat: 30.2768061142613, lng: -97.739133254747 },
    { lat: 30.2670736368686, lng: -97.7391347078803 },
    { lat: 30.3033470726956, lng: -97.7391677216658 },
    { lat: 30.2993304525433, lng: -97.739173832227 },
    { lat: 30.2670840225605, lng: -97.7391739787697 },
    { lat: 30.2993377151324, lng: -97.7391896994026 },
    { lat: 30.2603455495805, lng: -97.7392156128634 },
    { lat: 30.2574183426792, lng: -97.7392320458365 },
    { lat: 30.2671047939443, lng: -97.7392525205485 },
    { lat: 30.2573567829892, lng: -97.7392549641647 },
    { lat: 30.2576820741933, lng: -97.7392732130717 },
    { lat: 30.2576820741933, lng: -97.7392732130717 },
    { lat: 30.3012549183894, lng: -97.7392901550655 },
    { lat: 30.2778452626877, lng: -97.739301729863 },
    { lat: 30.2476418242991, lng: -97.7393346107126 },
    { lat: 30.2701717837732, lng: -97.7393353747511 },
    { lat: 30.2703288313964, lng: -97.7393480442748 },
    { lat: 30.3033054470097, lng: -97.7393490906437 },
    { lat: 30.2571895966738, lng: -97.7394565596972 },
    { lat: 30.2660332603253, lng: -97.7395351891493 },
    { lat: 30.2660384531772, lng: -97.7395548246166 },
    { lat: 30.2411960185843, lng: -97.7395693884273 },
    { lat: 30.2823679249711, lng: -97.7395808811126 },
    { lat: 30.2824822509973, lng: -97.7396139511402 },
    { lat: 30.2836537811404, lng: -97.7396278740301 },
    { lat: 30.2836537811404, lng: -97.7396278740301 },
    { lat: 30.282512862402, lng: -97.739633073269 },
    { lat: 30.3003397778707, lng: -97.7396503732209 },
    { lat: 30.2663376420357, lng: -97.7396713552698 },
    { lat: 30.2982867604868, lng: -97.7396882140888 },
    { lat: 30.2692357839698, lng: -97.7396953747066 },
    { lat: 30.2662364109112, lng: -97.7397083435686 },
    { lat: 30.3024221972773, lng: -97.7397087857253 },
    { lat: 30.2477511094147, lng: -97.7397233659378 },
    { lat: 30.2573776709402, lng: -97.7397774170824 },
    { lat: 30.2802504973201, lng: -97.7398004662737 },
    { lat: 30.3024863054695, lng: -97.7398474493255 },
    { lat: 30.2651462275028, lng: -97.7398827759246 },
    { lat: 30.3037139882621, lng: -97.7399168632561 },
    { lat: 30.2652765543657, lng: -97.7399177752718 },
    { lat: 30.2714451732524, lng: -97.7399197710838 },
    { lat: 30.2684215401988, lng: -97.7399233225343 },
    { lat: 30.2800162489425, lng: -97.7399531866877 },
    { lat: 30.258174888129, lng: -97.739954123812 },
    { lat: 30.2986288256063, lng: -97.7400114327349 },
    { lat: 30.2653754261387, lng: -97.7400215829751 },
    { lat: 30.2580759520076, lng: -97.7400280596914 },
    { lat: 30.2470891859217, lng: -97.7400280958974 },
    { lat: 30.2653435210907, lng: -97.7400331848118 },
    { lat: 30.2683716665538, lng: -97.7400806742921 },
    { lat: 30.2985392560673, lng: -97.7400944306842 },
    { lat: 30.2620730125361, lng: -97.7400955305535 },
    { lat: 30.2672534391997, lng: -97.7401296530296 },
    { lat: 30.2437808202667, lng: -97.7401323043519 },
    { lat: 30.2914464175965, lng: -97.7401657859728 },
    { lat: 30.2918861696233, lng: -97.7401809179076 },
    { lat: 30.2703433242512, lng: -97.740184666003 },
    { lat: 30.267270894391, lng: -97.7401856550908 },
    { lat: 30.2940923156911, lng: -97.7401919429298 },
    { lat: 30.2672825311848, lng: -97.7402229897983 },
    { lat: 30.2644537397468, lng: -97.7402236676581 },
    { lat: 30.2513150404188, lng: -97.7402379800805 },
    { lat: 30.2704900957609, lng: -97.7402822088504 },
    { lat: 30.3037687370724, lng: -97.7402830344802 },
    { lat: 30.2674252286258, lng: -97.7402899559389 },
    { lat: 30.2706112631885, lng: -97.7403043829259 },
    { lat: 30.2443133402099, lng: -97.7403062454508 },
    { lat: 30.2705590114055, lng: -97.7403229396356 },
    { lat: 30.2991098524992, lng: -97.7403382551494 },
    { lat: 30.2644691976035, lng: -97.7403572597052 },
    { lat: 30.2673907843561, lng: -97.7403748746262 },
    { lat: 30.2644009620332, lng: -97.7403826043479 },
    { lat: 30.2382934134274, lng: -97.7403992921135 },
    { lat: 30.2382934134274, lng: -97.7403992921135 },
    { lat: 30.2664013850724, lng: -97.740403714184 },
    { lat: 30.2335957909874, lng: -97.7404288762335 },
    { lat: 30.2991905153689, lng: -97.7404376166354 },
    { lat: 30.2395462474744, lng: -97.7404627780015 },
    { lat: 30.250654858196, lng: -97.7404827354624 },
    { lat: 30.2988997781856, lng: -97.7405548730653 },
    { lat: 30.2465449324286, lng: -97.7405603130109 },
    { lat: 30.3040559940953, lng: -97.7405826830244 },
    { lat: 30.3040878085915, lng: -97.740644619747 },
    { lat: 30.2664735052762, lng: -97.740651245023 },
    { lat: 30.2663532515418, lng: -97.7406550183372 },
    { lat: 30.2696200070436, lng: -97.7406769281353 },
    { lat: 30.2663642555111, lng: -97.7406930467536 },
    { lat: 30.3089167762286, lng: -97.740716872542 },
    { lat: 30.2633317851646, lng: -97.7407168747361 },
    { lat: 30.2805232998173, lng: -97.7407307279661 },
    { lat: 30.2455047680414, lng: -97.7407645030159 },
    { lat: 30.2665262605474, lng: -97.7407708827373 },
    { lat: 30.2665090146498, lng: -97.7407773906238 },
    { lat: 30.269706363343, lng: -97.7407837659112 },
    { lat: 30.2429967671577, lng: -97.7407844323705 },
    { lat: 30.2623898130278, lng: -97.7408305613756 },
    { lat: 30.2622764741223, lng: -97.7408553322617 },
    { lat: 30.3020205483168, lng: -97.7408585285379 },
    { lat: 30.2803417818346, lng: -97.7408653738816 },
    { lat: 30.302026982823, lng: -97.7408725745914 },
    { lat: 30.2804494176955, lng: -97.7408961872708 },
    { lat: 30.2804314472866, lng: -97.7409026402819 },
    { lat: 30.2804134768776, lng: -97.740909093293 },
    { lat: 30.2804134768776, lng: -97.740909093293 },
    { lat: 30.2945693532305, lng: -97.7409215350559 },
    { lat: 30.2984177436944, lng: -97.7409260957042 },
    { lat: 30.3020527208469, lng: -97.740928758806 },
    { lat: 30.2983824926331, lng: -97.7409477499285 },
    { lat: 30.2623024479418, lng: -97.7409523282245 },
    { lat: 30.2983589919256, lng: -97.7409621860781 },
    { lat: 30.265565285465, lng: -97.7409802112385 },
    { lat: 30.280313559267, lng: -97.7409885681773 },
    { lat: 30.3019514956151, lng: -97.7409909669291 },
    { lat: 30.2624335444586, lng: -97.7409938709048 },
    { lat: 30.2401766574533, lng: -97.7410111574373 },
    { lat: 30.2960884725138, lng: -97.7410232249465 },
    { lat: 30.2686438032115, lng: -97.7410255243957 },
    { lat: 30.2774772175269, lng: -97.7410547489718 },
    { lat: 30.2626032839648, lng: -97.7410669675834 },
    { lat: 30.2935223832364, lng: -97.7410721137815 },
    { lat: 30.2979509949369, lng: -97.7410767025845 },
    { lat: 30.29229458755, lng: -97.7410778555237 },
    { lat: 30.2621934297017, lng: -97.7410785624338 },
    { lat: 30.2934560210416, lng: -97.7410796313768 },
    { lat: 30.2625357531898, lng: -97.7410929409608 },
    { lat: 30.2979216538699, lng: -97.7410961495717 },
    { lat: 30.2688274787336, lng: -97.7410991899153 },
    { lat: 30.262518870496, lng: -97.7410994343051 },
    { lat: 30.2431346531526, lng: -97.7411004880847 },
    { lat: 30.2932569344565, lng: -97.7411021841625 },
    { lat: 30.2979040492297, lng: -97.7411078177639 },
    { lat: 30.2624851051086, lng: -97.7411124209937 },
    { lat: 30.2814586734609, lng: -97.7411159491408 },
    { lat: 30.2456197886531, lng: -97.7411218754851 },
    { lat: 30.291987779562, lng: -97.7411533732494 },
    { lat: 30.2814702366804, lng: -97.7411618619145 },
    { lat: 30.2716674785154, lng: -97.7411716200233 },
    { lat: 30.2796348615426, lng: -97.7412086147231 },
    { lat: 30.297960965045, lng: -97.7412230086637 },
    { lat: 30.2718041113225, lng: -97.7412324317723 },
    { lat: 30.2676803134293, lng: -97.7412357417441 },
    { lat: 30.2795568711419, lng: -97.7412387656538 },
    { lat: 30.2919338440928, lng: -97.7412540783918 },
    { lat: 30.2676855010603, lng: -97.7412551953559 },
    { lat: 30.2498157480141, lng: -97.7412696985452 },
    { lat: 30.2645692771096, lng: -97.7412697685234 },
    { lat: 30.2920492734048, lng: -97.7412762697332 },
    { lat: 30.2904667798649, lng: -97.7412793732913 },
    { lat: 30.2645743059385, lng: -97.7412893971748 },
    { lat: 30.2645743059385, lng: -97.7412893971748 },
    { lat: 30.2676958763218, lng: -97.7412941025793 },
    { lat: 30.2676958763218, lng: -97.7412941025793 },
    { lat: 30.267576009976, lng: -97.741294337249 },
    { lat: 30.2717016246232, lng: -97.741297377615 },
    { lat: 30.2677010639528, lng: -97.7413135561911 },
    { lat: 30.2675811976068, lng: -97.7413137908607 },
    { lat: 30.2420552666723, lng: -97.7413250118349 },
    { lat: 30.2644644459697, lng: -97.7413271268013 },
    { lat: 30.2677062515836, lng: -97.7413330098028 },
    { lat: 30.2675863852375, lng: -97.7413332444725 },
    { lat: 30.2675863852375, lng: -97.7413332444725 },
    { lat: 30.2905278670315, lng: -97.7413410248486 },
    { lat: 30.2891340463312, lng: -97.7413436606051 },
    { lat: 30.2677114392145, lng: -97.7413524634146 },
    { lat: 30.264618498062, lng: -97.7413526635976 },
    { lat: 30.2675915728685, lng: -97.7413526980842 },
    { lat: 30.2644745036279, lng: -97.7413663841039 },
    { lat: 30.2645944212546, lng: -97.7413679117798 },
    { lat: 30.2677157068084, lng: -97.7413713628948 },
    { lat: 30.2677166268453, lng: -97.7413719170263 },
    { lat: 30.2675967604993, lng: -97.741372151696 },
    { lat: 30.2717221122879, lng: -97.74137283217 },
    { lat: 30.2794539118995, lng: -97.7413826562889 },
    { lat: 30.3012265931787, lng: -97.7413881620311 },
    { lat: 30.2900779731909, lng: -97.7413913338419 },
    { lat: 30.2676019481302, lng: -97.7413916053078 },
    { lat: 30.2644845612859, lng: -97.7414056414064 },
    { lat: 30.2899312209397, lng: -97.7414084214391 },
    { lat: 30.2787274407319, lng: -97.7414084508693 },
    { lat: 30.2899165457145, lng: -97.7414101301989 },
    { lat: 30.2787111166424, lng: -97.7414145117942 },
    { lat: 30.2647920986113, lng: -97.7414242414691 },
    { lat: 30.2786621443737, lng: -97.7414326945689 },
    { lat: 30.2763552746159, lng: -97.7414337614971 },
    { lat: 30.2896574821683, lng: -97.7414347870544 },
    { lat: 30.2676787843387, lng: -97.7414548743437 },
    { lat: 30.267851068672, lng: -97.7414601871262 },
    { lat: 30.2979468179968, lng: -97.7414651063154 },
    { lat: 30.2677842972299, lng: -97.741485145386 },
    { lat: 30.2717647561169, lng: -97.7415254117016 },
    { lat: 30.2979835975432, lng: -97.7415406807143 },
    { lat: 30.2787461044488, lng: -97.7415408682161 },
    { lat: 30.2717717244615, lng: -97.7415493861202 },
    { lat: 30.2786971321801, lng: -97.7415590509908 },
    { lat: 30.2880298329619, lng: -97.741589353248 },
    { lat: 30.2667426335202, lng: -97.7415941233869 },
    { lat: 30.2717856611503, lng: -97.7415973349574 },
    { lat: 30.2879349631054, lng: -97.741597531688 },
    { lat: 30.2954501404951, lng: -97.7416047509636 },
    { lat: 30.2877831713349, lng: -97.7416106171922 },
    { lat: 30.2400295454368, lng: -97.7416154673713 },
    { lat: 30.2666281464991, lng: -97.7416158636157 },
    { lat: 30.2635323316388, lng: -97.7416300089709 },
    { lat: 30.2666338405907, lng: -97.7416357929321 },
    { lat: 30.2709461076422, lng: -97.7416572305758 },
    { lat: 30.2402065770307, lng: -97.7416728982118 },
    { lat: 30.2708949924945, lng: -97.7416742689602 },
    { lat: 30.2871677611394, lng: -97.7416766800594 },
    { lat: 30.2636741875473, lng: -97.7417040611339 },
    { lat: 30.2868937406199, lng: -97.7417066823178 },
    { lat: 30.244052935702, lng: -97.7417160068426 },
    { lat: 30.2868024004468, lng: -97.7417166830705 },
    { lat: 30.266836667883, lng: -97.741731924435 },
    { lat: 30.2666623110483, lng: -97.7417354395145 },
    { lat: 30.293377378778, lng: -97.7417361013134 },
    { lat: 30.2711141162233, lng: -97.7417392988401 },
    { lat: 30.2865512149707, lng: -97.7417441851408 },
    { lat: 30.2710970778408, lng: -97.7417449783016 },
    { lat: 30.2338163499778, lng: -97.7417472355942 },
    { lat: 30.266786521471, lng: -97.7417526159173 },
    { lat: 30.2507164910344, lng: -97.7417531236187 },
    { lat: 30.2667881862522, lng: -97.7417535579188 },
    { lat: 30.3046377004296, lng: -97.7417539430014 },
    { lat: 30.271045962693, lng: -97.7417620166859 },
    { lat: 30.2509364484965, lng: -97.7417645232447 },
    { lat: 30.2933017793268, lng: -97.7417698730328 },
    { lat: 30.2933017793268, lng: -97.7417698730328 },
    { lat: 30.2864373928412, lng: -97.7417748321246 },
    { lat: 30.251050875135, lng: -97.7417823947046 },
    { lat: 30.2638417979445, lng: -97.7417830423182 },
    { lat: 30.2709266940147, lng: -97.7418017729161 },
    { lat: 30.2850037809549, lng: -97.7418103734222 },
    { lat: 30.2968058167102, lng: -97.7418159452739 },
    { lat: 30.28655550217, lng: -97.7418220031827 },
    { lat: 30.2669587131065, lng: -97.7418224163103 },
    { lat: 30.2697615630734, lng: -97.7418229133715 },
    { lat: 30.2855947394157, lng: -97.7418261673859 },
    { lat: 30.2669252821654, lng: -97.7418362106319 },
    { lat: 30.2669085666946, lng: -97.7418431077925 },
    { lat: 30.244066488085, lng: -97.7418433991924 },
    { lat: 30.2864425443257, lng: -97.7418514604143 },
    { lat: 30.2405344993032, lng: -97.7418517854863 },
    { lat: 30.2933309396709, lng: -97.7418574276737 },
    { lat: 30.2933309396709, lng: -97.7418574276737 },
    { lat: 30.2507826292992, lng: -97.7418614533447 },
    { lat: 30.2850651561636, lng: -97.7418716366782 },
    { lat: 30.296717012847, lng: -97.7418731295823 },
    { lat: 30.2507640653186, lng: -97.7418766420567 },
    { lat: 30.2829216472247, lng: -97.7419034137915 },
    { lat: 30.2829216472247, lng: -97.7419034137915 },
    { lat: 30.2934312996738, lng: -97.7419089517737 },
    { lat: 30.2827178244874, lng: -97.7419185412694 },
    { lat: 30.2777809450237, lng: -97.741927575701 },
    { lat: 30.2625830259487, lng: -97.7419324562342 },
    { lat: 30.2841929988912, lng: -97.7419404065289 },
    { lat: 30.269795644729, lng: -97.741945939811 },
    { lat: 30.2817694334406, lng: -97.7419727490121 },
    { lat: 30.2628682997154, lng: -97.7419753393591 },
    { lat: 30.2627182678808, lng: -97.7419821544551 },
    { lat: 30.2628342229216, lng: -97.7419866982915 },
    { lat: 30.2700422308666, lng: -97.7419898460729 },
    { lat: 30.2698092773911, lng: -97.7419951503869 },
    { lat: 30.2776049253901, lng: -97.7419974325314 },
    { lat: 30.270870170142, lng: -97.7420091656382 },
    { lat: 30.2776102661301, lng: -97.7420165297198 },
    { lat: 30.2627289040373, lng: -97.7420188975339 },
    { lat: 30.2626096163397, lng: -97.7420243139313 },
    { lat: 30.2807014965945, lng: -97.7420340496857 },
    { lat: 30.2659551648757, lng: -97.7420377086704 },
    { lat: 30.2709999659678, lng: -97.7420382907527 },
    { lat: 30.2663619019302, lng: -97.7420383568983 },
    { lat: 30.2663537402552, lng: -97.742041117465 },
    { lat: 30.2659386749607, lng: -97.7420432861422 },
    { lat: 30.2659221850457, lng: -97.7420488636141 },
    { lat: 30.2827066244449, lng: -97.7420523430673 },
    { lat: 30.2663129318797, lng: -97.7420549202995 },
    { lat: 30.2626202524962, lng: -97.7420610570101 },
    { lat: 30.2626202524962, lng: -97.7420610570101 },
    { lat: 30.2698297263845, lng: -97.7420689662506 },
    { lat: 30.2515280608619, lng: -97.7420705008592 },
    { lat: 30.2701290668512, lng: -97.7420968706497 },
    { lat: 30.2460178860553, lng: -97.7420995316177 },
    { lat: 30.2699017839267, lng: -97.7421118740734 },
    { lat: 30.2699017839267, lng: -97.7421118740734 },
    { lat: 30.2806031950436, lng: -97.742112776233 },
    { lat: 30.2941677138009, lng: -97.7421418536018 },
    { lat: 30.2941677138009, lng: -97.7421418536018 },
    { lat: 30.2944115670217, lng: -97.7421459167673 },
    { lat: 30.2627636964946, lng: -97.7421482676329 },
    { lat: 30.2807338427082, lng: -97.7421508687882 },
    { lat: 30.2699738777507, lng: -97.7421547005557 },
    { lat: 30.2435857890901, lng: -97.7421658753228 },
    { lat: 30.2798895467628, lng: -97.7421680772981 },
    { lat: 30.2659374948252, lng: -97.7421819063748 },
    { lat: 30.23620303131, lng: -97.7421855790684 },
    { lat: 30.2943433820092, lng: -97.7421898167089 },
    { lat: 30.2798098651304, lng: -97.742194637845 },
    { lat: 30.2698651594287, lng: -97.7421955632611 },
    { lat: 30.2797939288039, lng: -97.7421999499544 },
    { lat: 30.2688342721215, lng: -97.7422006931317 },
    { lat: 30.2688342721215, lng: -97.7422006931317 },
    { lat: 30.2460783505072, lng: -97.74221221136 },
    { lat: 30.2363493342292, lng: -97.742214410466 },
    { lat: 30.2904441037827, lng: -97.7422399059636 },
    { lat: 30.2963621198052, lng: -97.7422707116632 },
    { lat: 30.2688542618864, lng: -97.742276404351 },
    { lat: 30.2892444673519, lng: -97.7422795362998 },
    { lat: 30.2892043157627, lng: -97.7422822492469 },
    { lat: 30.294387696043, lng: -97.7423131286078 },
    { lat: 30.2767327106486, lng: -97.7423170752629 },
    { lat: 30.2691540404405, lng: -97.7423295634799 },
    { lat: 30.2798096913474, lng: -97.7423327784407 },
    { lat: 30.2691033924036, lng: -97.7423490434958 },
    { lat: 30.2766226422426, lng: -97.7423566558121 },
    { lat: 30.2690696270457, lng: -97.7423620301731 },
    { lat: 30.2647774790447, lng: -97.7423723830734 },
    { lat: 30.2766276555007, lng: -97.7423757385321 },
    { lat: 30.2798368970375, lng: -97.7424062434371 },
    { lat: 30.2427000450708, lng: -97.7424086089009 },
    { lat: 30.2649515155548, lng: -97.7424089952918 },
    { lat: 30.2797167873769, lng: -97.7424118886137 },
    { lat: 30.2649346383319, lng: -97.7424153242508 },
    { lat: 30.2948005090823, lng: -97.7424715096064 },
    { lat: 30.2689657841231, lng: -97.7424718740289 },
    { lat: 30.2960556552964, lng: -97.7424750213445 },
    { lat: 30.2789969402967, lng: -97.7424855554409 },
    { lat: 30.269088868487, lng: -97.7424944381516 },
    { lat: 30.2679217723292, lng: -97.7425200127771 },
    { lat: 30.2797538868513, lng: -97.7425305092799 },
    { lat: 30.267928937993, lng: -97.7425451759173 },
    { lat: 30.2957070341645, lng: -97.7425543029257 },
    { lat: 30.2689367244403, lng: -97.7425798832756 },
    { lat: 30.2679432693205, lng: -97.7425955021975 },
    { lat: 30.2958717765913, lng: -97.7425976071533 },
    { lat: 30.2790621977108, lng: -97.7426009949171 },
    { lat: 30.2788657821868, lng: -97.7426023735204 },
    { lat: 30.27587159129, lng: -97.7426111877838 },
    { lat: 30.275838242507, lng: -97.7426225198956 },
    { lat: 30.2958104836895, lng: -97.7426384690895 },
    { lat: 30.2789375288688, lng: -97.742645978524 },
    { lat: 30.2680807602188, lng: -97.7426546085985 },
    { lat: 30.2935480464902, lng: -97.7426750538186 },
    { lat: 30.2820247029079, lng: -97.7427310922732 },
    { lat: 30.2758299389511, lng: -97.7427355686446 },
    { lat: 30.2638493868731, lng: -97.7427436140465 },
    { lat: 30.2865607806838, lng: -97.742755373112 },
    { lat: 30.3016901037019, lng: -97.7427569628023 },
    { lat: 30.2450799327065, lng: -97.7427570274109 },
    { lat: 30.2956266049844, lng: -97.7427610548983 },
    { lat: 30.2682995432701, lng: -97.7427948663498 },
    { lat: 30.2379325790199, lng: -97.7427983836676 },
    { lat: 30.2922021000654, lng: -97.7428027133315 },
    { lat: 30.2866893353265, lng: -97.7428099477739 },
    { lat: 30.252887369892, lng: -97.742812932107 },
    { lat: 30.2680657843121, lng: -97.7428138739869 },
    { lat: 30.2866224917543, lng: -97.7428161831846 },
    { lat: 30.2954257789488, lng: -97.7428183726696 },
    { lat: 30.2906002856892, lng: -97.7428189004285 },
    { lat: 30.2682349618627, lng: -97.7428194072867 },
    { lat: 30.2638714397077, lng: -97.7428211232587 },
    { lat: 30.2851648565899, lng: -97.7428249750524 },
    { lat: 30.2955040191808, lng: -97.7428427787709 },
    { lat: 30.27214807055, lng: -97.7428449067077 },
    { lat: 30.2393909816339, lng: -97.7428458069744 },
    { lat: 30.2819373398048, lng: -97.7428511577172 },
    { lat: 30.2819373398048, lng: -97.7428511577172 },
    { lat: 30.2681380897518, lng: -97.7428562186921 },
    { lat: 30.2681380897518, lng: -97.7428562186921 },
    { lat: 30.2735410576626, lng: -97.742860723527 },
    { lat: 30.2865099705043, lng: -97.7428800866033 },
    { lat: 30.2955211262648, lng: -97.742886497127 },
    { lat: 30.2680279763553, lng: -97.7428968530626 },
    { lat: 30.2529599126334, lng: -97.7429119808365 },
    { lat: 30.2895463321322, lng: -97.7429133553423 },
    { lat: 30.2895209291055, lng: -97.7429156264748 },
    { lat: 30.2489011557071, lng: -97.7429207159303 },
    { lat: 30.2894193169994, lng: -97.7429247110053 },
    { lat: 30.2841318249574, lng: -97.7429273481731 },
    { lat: 30.2854523974269, lng: -97.7429300939488 },
    { lat: 30.2907502886301, lng: -97.7429380337383 },
    { lat: 30.2840014379978, lng: -97.7429402873427 },
    { lat: 30.2628865247128, lng: -97.742945267849 },
    { lat: 30.2680409208799, lng: -97.7429453120416 },
    { lat: 30.2733129736489, lng: -97.7429464831238 },
    { lat: 30.274565164947, lng: -97.7429466308028 },
    { lat: 30.2906376536122, lng: -97.7429485508683 },
    { lat: 30.2906094948577, lng: -97.7429511801509 },
    { lat: 30.2360196371092, lng: -97.7429572451526 },
    { lat: 30.2680473931421, lng: -97.7429695415312 },
    { lat: 30.277938782382, lng: -97.7429758734721 },
    { lat: 30.277938782382, lng: -97.7429758734721 },
    { lat: 30.2681698127104, lng: -97.7429787970282 },
    { lat: 30.2850366691191, lng: -97.7430092095323 },
    { lat: 30.2680603376665, lng: -97.7430180005102 },
    { lat: 30.2680603376665, lng: -97.7430180005102 },
    { lat: 30.2672770021897, lng: -97.7430408539224 },
    { lat: 30.2850398779681, lng: -97.7430464321637 },
    { lat: 30.2895297492635, lng: -97.7430479399143 },
    { lat: 30.2895043462369, lng: -97.743050211047 },
    { lat: 30.268189229497, lng: -97.7430514854968 },
    { lat: 30.2680732821909, lng: -97.7430664594893 },
    { lat: 30.284032953158, lng: -97.7430703462231 },
    { lat: 30.2892757189982, lng: -97.7430706512405 },
    { lat: 30.2840112219983, lng: -97.7430725027514 },
    { lat: 30.2892503159715, lng: -97.7430729223732 },
    { lat: 30.2839677596783, lng: -97.7430768158079 },
    { lat: 30.2671575712511, lng: -97.743085782708 },
    { lat: 30.2988097782954, lng: -97.7430928724382 },
    { lat: 30.2670419109239, lng: -97.7431018612952 },
    { lat: 30.2439933689657, lng: -97.7431256831398 },
    { lat: 30.2670487272733, lng: -97.7431264666493 },
    { lat: 30.2680926989775, lng: -97.7431391479578 },
    { lat: 30.2673806581904, lng: -97.743141364345 },
    { lat: 30.2440024948675, lng: -97.7431441793847 },
    { lat: 30.2838546533504, lng: -97.7431592930728 },
    { lat: 30.2680991712396, lng: -97.7431633774474 },
    { lat: 30.2712161950632, lng: -97.7431837677096 },
    { lat: 30.2712161950632, lng: -97.7431837677096 },
    { lat: 30.2878917129656, lng: -97.7432073811302 },
    { lat: 30.2671929810013, lng: -97.7432119667224 },
    { lat: 30.2521274721212, lng: -97.7432471227867 },
    { lat: 30.2670837100837, lng: -97.7432527947473 },
    { lat: 30.2769774648988, lng: -97.7432528103855 },
    { lat: 30.2878870761408, lng: -97.7432597922789 },
    { lat: 30.2662066598919, lng: -97.7432644595351 },
    { lat: 30.2822276959834, lng: -97.7432666887508 },
    { lat: 30.2801484552855, lng: -97.7432868869473 },
    { lat: 30.2801484552855, lng: -97.7432868869473 },
    { lat: 30.287887076142, lng: -97.7432908367347 },
    { lat: 30.2625808352881, lng: -97.7432935909582 },
    { lat: 30.2878870761426, lng: -97.7433063589626 },
    { lat: 30.2461903695181, lng: -97.7433246748529 },
    { lat: 30.2671036095804, lng: -97.7433246816648 },
    { lat: 30.2769397825911, lng: -97.7433358734306 },
    { lat: 30.274551257723, lng: -97.7433381733458 },
    { lat: 30.2461746251233, lng: -97.7433441246107 },
    { lat: 30.2771022896296, lng: -97.7433467961639 },
    { lat: 30.2770841348469, lng: -97.7433533384286 },
    { lat: 30.2345297683156, lng: -97.7433718525269 },
    { lat: 30.2770121751385, lng: -97.7433780221618 },
    { lat: 30.2661186331024, lng: -97.7433803011795 },
    { lat: 30.2408915858079, lng: -97.7433812181032 },
    { lat: 30.2626413384136, lng: -97.7434109020784 },
    { lat: 30.2702707839016, lng: -97.7434168737311 },
    { lat: 30.3006130526541, lng: -97.7434354495596 },
    { lat: 30.2703431757087, lng: -97.7434590212952 },
    { lat: 30.2703426765321, lng: -97.743460151426 },
    { lat: 30.3044939922124, lng: -97.7434709536525 },
    { lat: 30.2662837227557, lng: -97.7435410487488 },
    { lat: 30.2662835493943, lng: -97.7435414335348 },
    { lat: 30.2503414992829, lng: -97.7435439483493 },
    { lat: 30.2761149056069, lng: -97.743597980506 },
    { lat: 30.2661877745564, lng: -97.7436296359042 },
    { lat: 30.2896630724851, lng: -97.7436315286491 },
    { lat: 30.2702729555991, lng: -97.743635698383 },
    { lat: 30.273520993727, lng: -97.7436477817067 },
    { lat: 30.2503732067813, lng: -97.7436714241176 },
    { lat: 30.2429456410204, lng: -97.7436812008066 },
    { lat: 30.2503204806483, lng: -97.743688999497 },
    { lat: 30.2655187020682, lng: -97.7436896426195 },
    { lat: 30.2654842102676, lng: -97.7437026583944 },
    { lat: 30.2654497184671, lng: -97.7437156741693 },
    { lat: 30.2761191446921, lng: -97.7437367651241 },
    { lat: 30.2383747901695, lng: -97.7437498751785 },
    { lat: 30.2760857253862, lng: -97.7437502301854 },
    { lat: 30.2501687578053, lng: -97.743761344058 },
    { lat: 30.2693437840961, lng: -97.7437723736872 },
    { lat: 30.265294505365, lng: -97.7437742451565 },
    { lat: 30.2662276135434, lng: -97.7437750481784 },
    { lat: 30.2866147807104, lng: -97.7437768728554 },
    { lat: 30.2724847834813, lng: -97.7438038735284 },
    { lat: 30.2694436457427, lng: -97.7438039615286 },
    { lat: 30.2694271715188, lng: -97.7438102793141 },
    { lat: 30.2868563501274, lng: -97.7438201914281 },
    { lat: 30.2662408932058, lng: -97.7438235189365 },
    { lat: 30.2693079244117, lng: -97.7438565052287 },
    { lat: 30.285307282977, lng: -97.743861371575 },
    { lat: 30.251450343614, lng: -97.7438624910398 },
    { lat: 30.2852261008171, lng: -97.7438707582657 },
    { lat: 30.240063671412, lng: -97.7438865691461 },
    { lat: 30.2653472610242, lng: -97.7438938812097 },
    { lat: 30.2516042426552, lng: -97.7439300915412 },
    { lat: 30.2402231343333, lng: -97.7439385803705 },
    { lat: 30.2851747809987, lng: -97.7439433728833 },
    { lat: 30.2880557008594, lng: -97.7439484159926 },
    { lat: 30.2652028559861, lng: -97.7439503795407 },
    { lat: 30.2841849297645, lng: -97.7439605469097 },
    { lat: 30.2879375401021, lng: -97.7439622269952 },
    { lat: 30.2840943409764, lng: -97.743968206995 },
    { lat: 30.2840716937793, lng: -97.7439701220164 },
    { lat: 30.2954975446344, lng: -97.7440175238119 },
    { lat: 30.2653450522423, lng: -97.7440285322132 },
    { lat: 30.2953736733455, lng: -97.7440287386611 },
    { lat: 30.2716455920391, lng: -97.7440380742418 },
    { lat: 30.2684531594993, lng: -97.7440440402193 },
    { lat: 30.2952196866118, lng: -97.7440442786133 },
    { lat: 30.2839507812424, lng: -97.7440468729165 },
    { lat: 30.2937421512471, lng: -97.7440601719412 },
    { lat: 30.2715773776411, lng: -97.7440627613596 },
    { lat: 30.2444106795973, lng: -97.7440660213391 },
    { lat: 30.285244069669, lng: -97.7440736579196 },
    { lat: 30.2935913070998, lng: -97.7440753947516 },
    { lat: 30.2443623168211, lng: -97.7440822228708 },
    { lat: 30.2443300749703, lng: -97.7440930238918 },
    { lat: 30.2840573917919, lng: -97.7441043847221 },
    { lat: 30.2840120973978, lng: -97.7441082147647 },
    { lat: 30.2824668421497, lng: -97.7441125179714 },
    { lat: 30.2838996251427, lng: -97.7441196414303 },
    { lat: 30.2782266506029, lng: -97.7441218561641 },
    { lat: 30.2944497529426, lng: -97.7441219783747 },
    { lat: 30.2684167842907, lng: -97.7441278736433 },
    { lat: 30.2684167842907, lng: -97.7441278736433 },
    { lat: 30.2642424834087, lng: -97.7441284472093 },
    { lat: 30.2782323364218, lng: -97.7441415128493 },
    { lat: 30.2642494677086, lng: -97.7441530585471 },
    { lat: 30.2833261489619, lng: -97.7441690604611 },
    { lat: 30.2684888077547, lng: -97.7441708580838 },
    { lat: 30.2938338060072, lng: -97.7441841381838 },
    { lat: 30.2716110082795, lng: -97.7441906082768 },
    { lat: 30.2922900371918, lng: -97.7442015108523 },
    { lat: 30.2749061355414, lng: -97.7442016130573 },
    { lat: 30.2749061355414, lng: -97.7442016130573 },
    { lat: 30.2839076167576, lng: -97.7442099466367 },
    { lat: 30.2935258325395, lng: -97.7442152180883 },
    { lat: 30.2921002026004, lng: -97.7442198228791 },
    { lat: 30.293408271597, lng: -97.7442232368598 },
    { lat: 30.2964101427513, lng: -97.7442354987098 },
    { lat: 30.2715061774396, lng: -97.744247971628 },
    { lat: 30.2418217895113, lng: -97.7442493748892 },
    { lat: 30.2963855966571, lng: -97.7442498878003 },
    { lat: 30.2434540675137, lng: -97.74425393834 },
    { lat: 30.2434313701765, lng: -97.7442631488543 },
    { lat: 30.2685155527588, lng: -97.7442677073976 },
    { lat: 30.2823456787495, lng: -97.7442685555294 },
    { lat: 30.2915849372807, lng: -97.7442695269516 },
    { lat: 30.2417469426711, lng: -97.7442853056774 },
    { lat: 30.2715162350074, lng: -97.7442872285775 },
    { lat: 30.2716361521988, lng: -97.7442887506505 },
    { lat: 30.2632542735763, lng: -97.7442976928539 },
    { lat: 30.2492102479663, lng: -97.744298060093 },
    { lat: 30.2935252046082, lng: -97.7443093979901 },
    { lat: 30.2773821784554, lng: -97.7443176135507 },
    { lat: 30.2921639528581, lng: -97.7443468420326 },
    { lat: 30.2921368336309, lng: -97.7443494580364 },
    { lat: 30.2707540978569, lng: -97.7443555106929 },
    { lat: 30.2993335021263, lng: -97.7443694301048 },
    { lat: 30.2491160716201, lng: -97.7443796434579 },
    { lat: 30.2675350529509, lng: -97.7443804809205 },
    { lat: 30.2684331497757, lng: -97.7444049642722 },
    { lat: 30.2706147597707, lng: -97.7444049952516 },
    { lat: 30.2962624258902, lng: -97.7444326090407 },
    { lat: 30.2977997755157, lng: -97.7444528140925 },
    { lat: 30.2644534815365, lng: -97.7444566671146 },
    { lat: 30.2895231374942, lng: -97.7444595105379 },
    { lat: 30.2473180427917, lng: -97.7444676855252 },
    { lat: 30.2527315629774, lng: -97.7444726915151 },
    { lat: 30.2908079914906, lng: -97.7444776422233 },
    { lat: 30.3010649214136, lng: -97.7444805832346 },
    { lat: 30.2886571526887, lng: -97.7445207676077 },
    { lat: 30.2632367976729, lng: -97.7445504201593 },
    { lat: 30.2884021848653, lng: -97.7445518122619 },
    { lat: 30.2907307389273, lng: -97.7445522639244 },
    { lat: 30.2458677493604, lng: -97.7445570563499 },
    { lat: 30.2900773150055, lng: -97.7445581229665 },
    { lat: 30.2900523837481, lng: -97.7445609485101 },
    { lat: 30.2633857852932, lng: -97.7445688737752 },
    { lat: 30.2705390650113, lng: -97.744570908957 },
    { lat: 30.2527817510944, lng: -97.7445799480991 },
    { lat: 30.2893656180446, lng: -97.744600645306 },
    { lat: 30.2634755146329, lng: -97.7446032339125 },
    { lat: 30.2892535119925, lng: -97.7446090773359 },
    { lat: 30.2460157887015, lng: -97.7446093745986 },
    { lat: 30.2634586235623, lng: -97.7446099740997 },
    { lat: 30.2737809735664, lng: -97.744624983004 },
    { lat: 30.2888308253833, lng: -97.7446404069268 },
    { lat: 30.2872888162352, lng: -97.744668929138 },
    { lat: 30.2632674817543, lng: -97.744678261506 },
    { lat: 30.2899449544407, lng: -97.7447107757341 },
    { lat: 30.2893759668688, lng: -97.744711255639 },
    { lat: 30.3026977775924, lng: -97.7447128718478 },
    { lat: 30.2867398881832, lng: -97.7447140016606 },
    { lat: 30.2698103095244, lng: -97.7447184095282 },
    { lat: 30.2705820045802, lng: -97.7447309564088 },
    { lat: 30.2738238615415, lng: -97.7447711628418 },
    { lat: 30.2738238615415, lng: -97.7447711628418 },
    { lat: 30.2764584834435, lng: -97.7447894549763 },
    { lat: 30.2408134461612, lng: -97.7447910934716 },
    { lat: 30.2873450166915, lng: -97.7447972933868 },
    { lat: 30.2859519352975, lng: -97.7447985312986 },
    { lat: 30.2872970408531, lng: -97.7448012913753 },
    { lat: 30.3026758312518, lng: -97.7448032150966 },
    { lat: 30.279555226832, lng: -97.7448169128555 },
    { lat: 30.2857929339332, lng: -97.7448174225566 },
    { lat: 30.2696587840734, lng: -97.7448478734044 },
    { lat: 30.2666443139407, lng: -97.7448537015305 },
    { lat: 30.2826273850995, lng: -97.7448898396535 },
    { lat: 30.2729784744022, lng: -97.7448936506882 },
    { lat: 30.2862360278873, lng: -97.7448989453548 },
    { lat: 30.2422219411421, lng: -97.7449019691498 },
    { lat: 30.2871882119453, lng: -97.7449127687444 },
    { lat: 30.2871882119453, lng: -97.7449127687444 },
    { lat: 30.2696221141076, lng: -97.7449315356577 },
    { lat: 30.2728436433585, lng: -97.7449383782988 },
    { lat: 30.2852647810173, lng: -97.7449468726293 },
    { lat: 30.2852647810173, lng: -97.7449468726293 },
    { lat: 30.2696276327604, lng: -97.7449510944084 },
    { lat: 30.2956129267273, lng: -97.7449570304853 },
    { lat: 30.2955901094091, lng: -97.7449598564844 },
    { lat: 30.2529907873462, lng: -97.7449603741768 },
    { lat: 30.2955444747727, lng: -97.7449655084826 },
    { lat: 30.2856456279196, lng: -97.7449683226851 },
    { lat: 30.2697531146437, lng: -97.7449685670782 },
    { lat: 30.2665631681975, lng: -97.7449909206109 },
    { lat: 30.28409316937, lng: -97.7449922892311 },
    { lat: 30.28409316937, lng: -97.7449922892311 },
    { lat: 30.28409316937, lng: -97.7449922892311 },
    { lat: 30.3020822382351, lng: -97.7450087965478 },
    { lat: 30.296431482668, lng: -97.7450107255384 },
    { lat: 30.2666938317758, lng: -97.7450248293109 },
    { lat: 30.2946640831532, lng: -97.7450280768987 },
    { lat: 30.2727526700711, lng: -97.7450352145796 },
    { lat: 30.2665796741426, lng: -97.7450479632044 },
    { lat: 30.299925167536, lng: -97.745055190729 },
    { lat: 30.2656730698359, lng: -97.7450604498564 },
    { lat: 30.2665851761242, lng: -97.7450669774022 },
    { lat: 30.2520283500023, lng: -97.7450731982242 },
    { lat: 30.272763707335, lng: -97.7450743319338 },
    { lat: 30.2827679207725, lng: -97.7451013505111 },
    { lat: 30.2719944537637, lng: -97.745102448358 },
    { lat: 30.2827169028946, lng: -97.7451055186406 },
    { lat: 30.2853334476016, lng: -97.7451091047319 },
    { lat: 30.3021463184911, lng: -97.7451197985264 },
    { lat: 30.2841025961321, lng: -97.7451245396209 },
    { lat: 30.2719082767396, lng: -97.7451333420111 },
    { lat: 30.2657754868128, lng: -97.7451629480459 },
    { lat: 30.2967344476555, lng: -97.7451633482269 },
    { lat: 30.2826637815356, lng: -97.7451763726974 },
    { lat: 30.2841058168605, lng: -97.7451835192131 },
    { lat: 30.2689395346198, lng: -97.7451882016597 },
    { lat: 30.2689224730607, lng: -97.7451946200564 },
    { lat: 30.2656728064289, lng: -97.7451990366214 },
    { lat: 30.2689054115014, lng: -97.745201038453 },
    { lat: 30.2687137842714, lng: -97.7452033733615 },
    { lat: 30.2936947451239, lng: -97.7452248928166 },
    { lat: 30.2935512379436, lng: -97.7452343512508 },
    { lat: 30.2827249674266, lng: -97.7452378879215 },
    { lat: 30.2687863137722, lng: -97.745245201691 },
    { lat: 30.268785980587, lng: -97.7452459672296 },
    { lat: 30.268785980587, lng: -97.7452459672296 },
    { lat: 30.2841113643716, lng: -97.7452710185352 },
    { lat: 30.2686776583597, lng: -97.7452873515287 },
    { lat: 30.2909151967407, lng: -97.7452899602158 },
    { lat: 30.2908349353219, lng: -97.7452957118521 },
    { lat: 30.2686831603269, lng: -97.745306365676 },
    { lat: 30.268688662294, lng: -97.7453253798236 },
    { lat: 30.292233210384, lng: -97.745328472705 },
    { lat: 30.3015141491402, lng: -97.7453651696243 },
    { lat: 30.2356265588115, lng: -97.7453790603805 },
    { lat: 30.2356265588115, lng: -97.7453790603805 },
    { lat: 30.2910025352958, lng: -97.74541666446 },
    { lat: 30.2909757814895, lng: -97.7454185816721 },
    { lat: 30.2647345530465, lng: -97.7454191198165 },
    { lat: 30.2908955200707, lng: -97.7454243333082 },
    { lat: 30.2908420124581, lng: -97.7454281677324 },
    { lat: 30.2399476739696, lng: -97.7454325525986 },
    { lat: 30.2906751286345, lng: -97.7454412011239 },
    { lat: 30.2907908426649, lng: -97.7454526316652 },
    { lat: 30.268727176064, lng: -97.7454584788558 },
    { lat: 30.239910279402, lng: -97.7454749703185 },
    { lat: 30.2537296998228, lng: -97.7454837651577 },
    { lat: 30.2775697825459, lng: -97.7455003728619 },
    { lat: 30.2647872482062, lng: -97.7455386422306 },
    { lat: 30.3001532141961, lng: -97.7455427055724 },
    { lat: 30.2677957844638, lng: -97.7455453733205 },
    { lat: 30.2679008928253, lng: -97.7455759039567 },
    { lat: 30.267867673738, lng: -97.745588657587 },
    { lat: 30.2607763401378, lng: -97.7455942249877 },
    { lat: 30.2708737838622, lng: -97.7455948731602 },
    { lat: 30.2710314373977, lng: -97.7456046542792 },
    { lat: 30.2677584007521, lng: -97.7456286094239 },
    { lat: 30.2893302559433, lng: -97.7456609203137 },
    { lat: 30.2481937883125, lng: -97.7456623742322 },
    { lat: 30.2605324276153, lng: -97.7456842555343 },
    { lat: 30.267774471839, lng: -97.7456875367308 },
    { lat: 30.2767438088502, lng: -97.7457201541563 },
    { lat: 30.2677851858969, lng: -97.7457268216019 },
    { lat: 30.2508242976089, lng: -97.7457334798302 },
    { lat: 30.2600021626541, lng: -97.7457407336546 },
    { lat: 30.2558838414049, lng: -97.7457690921183 },
    { lat: 30.2669488794797, lng: -97.7457749287924 },
    { lat: 30.2939204080302, lng: -97.7457878198064 },
    { lat: 30.2709902588413, lng: -97.7457913116687 },
    { lat: 30.2657322200341, lng: -97.7457964511865 },
    { lat: 30.252905285313, lng: -97.745808581111 },
    { lat: 30.2919571272803, lng: -97.7458348972289 },
    { lat: 30.2668777846556, lng: -97.745869373284 },
    { lat: 30.2799855837901, lng: -97.7458731957991 },
    { lat: 30.2599795592306, lng: -97.7458883247734 },
    { lat: 30.2508348807182, lng: -97.7458886247071 },
    { lat: 30.292724318238, lng: -97.7458921142018 },
    { lat: 30.2927066533398, lng: -97.7458938636248 },
    { lat: 30.2638359957742, lng: -97.7458984330893 },
    { lat: 30.2595422133257, lng: -97.7459105055425 },
    { lat: 30.2669492971209, lng: -97.7459134857861 },
    { lat: 30.2598982550563, lng: -97.7459183349556 },
    { lat: 30.2798550990963, lng: -97.7459184132717 },
    { lat: 30.2913145764699, lng: -97.7459248751171 },
    { lat: 30.2699647840527, lng: -97.74593237312 },
    { lat: 30.2637270258785, lng: -97.7459394780443 },
    { lat: 30.2637270258785, lng: -97.7459394780443 },
    { lat: 30.26696099909, lng: -97.745951515645 },
    { lat: 30.2648736745102, lng: -97.7459579730025 },
    { lat: 30.2597519075429, lng: -97.7459723532835 },
    { lat: 30.2700365224451, lng: -97.7459759926418 },
    { lat: 30.2969960995221, lng: -97.7459855645941 },
    { lat: 30.2829624706824, lng: -97.7460083168664 },
    { lat: 30.273151267239, lng: -97.7460193092324 },
    { lat: 30.2907727799774, lng: -97.7460358720932 },
    { lat: 30.3004463642298, lng: -97.7460563545006 },
    { lat: 30.2647821854313, lng: -97.7460587346373 },
    { lat: 30.2516437640828, lng: -97.7460722891606 },
    { lat: 30.2475832919557, lng: -97.7460797262084 },
    { lat: 30.2790421838992, lng: -97.7460831290393 },
    { lat: 30.2908313136715, lng: -97.7461007613165 },
    { lat: 30.2661050783527, lng: -97.7461046796996 },
    { lat: 30.2515692822297, lng: -97.7461097670727 },
    { lat: 30.2789115689381, lng: -97.7461322713063 },
    { lat: 30.2591107861885, lng: -97.7461393735897 },
    { lat: 30.2659855707864, lng: -97.7461519136464 },
    { lat: 30.265968498277, lng: -97.7461586613531 },
    { lat: 30.2914446279093, lng: -97.7461597628477 },
    { lat: 30.3002790629821, lng: -97.7461613627354 },
    { lat: 30.2690581848918, lng: -97.7461782590033 },
    { lat: 30.2587942129337, lng: -97.7461875092006 },
    { lat: 30.2590416385414, lng: -97.7461882755939 },
    { lat: 30.2987612955977, lng: -97.7462228111099 },
    { lat: 30.2691934106719, lng: -97.7462697825105 },
    { lat: 30.2565969992232, lng: -97.7462731109478 },
    { lat: 30.2565969992232, lng: -97.7462731109478 },
    { lat: 30.2820129981944, lng: -97.7462820714677 },
    { lat: 30.2972907188412, lng: -97.7463062414697 },
    { lat: 30.2660149573665, lng: -97.7463246607514 },
    { lat: 30.2564715013907, lng: -97.7463256532325 },
    { lat: 30.2864778594078, lng: -97.7463433287416 },
    { lat: 30.2722996329747, lng: -97.7463469525466 },
    { lat: 30.2864203761645, lng: -97.7463494701162 },
    { lat: 30.2753918325822, lng: -97.7463719575967 },
    { lat: 30.2466537402193, lng: -97.7464294342252 },
    { lat: 30.2872779953376, lng: -97.7464498878409 },
    { lat: 30.2387528111511, lng: -97.746451246694 },
    { lat: 30.2466020851677, lng: -97.7464536475321 },
    { lat: 30.2580607348542, lng: -97.7464604702456 },
    { lat: 30.2780037551798, lng: -97.74646796383 },
    { lat: 30.2864631286649, lng: -97.7464781692618 },
    { lat: 30.2863350978049, lng: -97.746491847778 },
    { lat: 30.286220096912, lng: -97.7465031648317 },
    { lat: 30.2872823688196, lng: -97.7465085933943 },
    { lat: 30.2902035806202, lng: -97.74651647445 },
    { lat: 30.2901667782728, lng: -97.7465209504126 },
    { lat: 30.2457410281379, lng: -97.7465349417287 },
    { lat: 30.2844503021433, lng: -97.7465380547229 },
    { lat: 30.2842035340859, lng: -97.7465546287079 },
    { lat: 30.2388080837307, lng: -97.7465675247842 },
    { lat: 30.2569392193904, lng: -97.7465972208789 },
    { lat: 30.2567653313835, lng: -97.7466039337897 },
    { lat: 30.2650851036312, lng: -97.7466223235294 },
    { lat: 30.2567879336907, lng: -97.7466253399864 },
    { lat: 30.2779477804446, lng: -97.7466279183131 },
    { lat: 30.2680927844451, lng: -97.7466343730353 },
    { lat: 30.2680927844451, lng: -97.7466343730353 },
    { lat: 30.2848580762333, lng: -97.7466383685523 },
    { lat: 30.2848054270001, lng: -97.7466443856097 },
    { lat: 30.2902032815325, lng: -97.7466499490133 },
    { lat: 30.2846910480915, lng: -97.7466570922767 },
    { lat: 30.2900616109023, lng: -97.7466636588677 },
    { lat: 30.2649587749638, lng: -97.7466677257639 },
    { lat: 30.2681645226338, lng: -97.746677992175 },
    { lat: 30.264963967821, lng: -97.7466873612515 },
    { lat: 30.2895190404163, lng: -97.7466971050179 },
    { lat: 30.3016460611382, lng: -97.7466985209382 },
    { lat: 30.2848098542695, lng: -97.7467033064396 },
    { lat: 30.271268345621, lng: -97.7467048231036 },
    { lat: 30.2743027832305, lng: -97.7467153727167 },
    { lat: 30.3024907229387, lng: -97.7467322734864 },
    { lat: 30.2299133447958, lng: -97.7467360987011 },
    { lat: 30.268190434329, lng: -97.7467743512727 },
    { lat: 30.2895136338389, lng: -97.7467760688042 },
    { lat: 30.2895133133804, lng: -97.7467945753132 },
    { lat: 30.2847028204349, lng: -97.7468034056153 },
    { lat: 30.2712796960478, lng: -97.7468050108155 },
    { lat: 30.2883244073042, lng: -97.7468196220904 },
    { lat: 30.2651202736854, lng: -97.7468252363251 },
    { lat: 30.2641893918436, lng: -97.7468284551116 },
    { lat: 30.2774348498254, lng: -97.7468306591503 },
    { lat: 30.2570672537762, lng: -97.7468310582472 },
    { lat: 30.2881697698298, lng: -97.7468357933362 },
    { lat: 30.2769519926501, lng: -97.7468582630737 },
    { lat: 30.2847075293725, lng: -97.7468619309506 },
    { lat: 30.2682163460245, lng: -97.7468707103703 },
    { lat: 30.2571397866016, lng: -97.7468728735019 },
    { lat: 30.2571397866016, lng: -97.7468728735019 },
    { lat: 30.2571397866016, lng: -97.7468728735019 },
    { lat: 30.2672214069807, lng: -97.7468797817779 },
    { lat: 30.2972830126477, lng: -97.7468960106379 },
    { lat: 30.2703236299246, lng: -97.7469080363244 },
    { lat: 30.2681069350151, lng: -97.7469101526531 },
    { lat: 30.2588906011097, lng: -97.746912115455 },
    { lat: 30.2674513727848, lng: -97.746935665364 },
    { lat: 30.2674350541546, lng: -97.7469415626912 },
    { lat: 30.2671837846353, lng: -97.7469628729972 },
    { lat: 30.2958167221141, lng: -97.7469961784023 },
    { lat: 30.2957240687999, lng: -97.747004601435 },
    { lat: 30.2672555492231, lng: -97.7470064332914 },
    { lat: 30.2956314154855, lng: -97.7470130244676 },
    { lat: 30.2703246277693, lng: -97.7470467592443 },
    { lat: 30.2564644341521, lng: -97.7470649522813 },
    { lat: 30.2874419055135, lng: -97.7471061827356 },
    { lat: 30.2957686718528, lng: -97.7471336706152 },
    { lat: 30.2671730430112, lng: -97.7471451273763 },
    { lat: 30.2565390299132, lng: -97.7471759251101 },
    { lat: 30.2663700572408, lng: -97.7471829729815 },
    { lat: 30.2671837675861, lng: -97.747184775797 },
    { lat: 30.2791911089932, lng: -97.7471990924042 },
    { lat: 30.275953360071, lng: -97.7472213616941 },
    { lat: 30.2757801813251, lng: -97.7472846385495 },
    { lat: 30.2757801813251, lng: -97.7472846385495 },
    { lat: 30.2441620651946, lng: -97.7473030528964 },
    { lat: 30.25581695012, lng: -97.7473145060272 },
    { lat: 30.2397072578346, lng: -97.7473516367924 },
    { lat: 30.2693167842314, lng: -97.7473633727951 },
    { lat: 30.2759445486133, lng: -97.7473637195684 },
    { lat: 30.2555688832955, lng: -97.7474034121908 },
    { lat: 30.2725886027972, lng: -97.7474115883877 },
    { lat: 30.2558391044668, lng: -97.747445439449 },
    { lat: 30.2692812053645, lng: -97.7474476639984 },
    { lat: 30.2469788712422, lng: -97.7474596618357 },
    { lat: 30.2434412286039, lng: -97.7474615887431 },
    { lat: 30.2557804360109, lng: -97.7474664659903 },
    { lat: 30.2364233850634, lng: -97.7474718578615 },
    { lat: 30.2364261537301, lng: -97.7474843937668 },
    { lat: 30.2692925433004, lng: -97.7474858890323 },
    { lat: 30.2663404172678, lng: -97.7474915877995 },
    { lat: 30.2364303067304, lng: -97.7475031976247 },
    { lat: 30.269423597453, lng: -97.7475192430844 },
    { lat: 30.2364344597306, lng: -97.7475220014826 },
    { lat: 30.2782983095133, lng: -97.7475382395081 },
    { lat: 30.2908717800124, lng: -97.7475388717146 },
    { lat: 30.2364386127309, lng: -97.7475408053405 },
    { lat: 30.2364399970642, lng: -97.7475470732931 },
    { lat: 30.2364427657311, lng: -97.7475596091984 },
    { lat: 30.2900292361541, lng: -97.7475703432026 },
    { lat: 30.2684856686271, lng: -97.7475974852796 },
    { lat: 30.269332226076, lng: -97.7476196766508 },
    { lat: 30.2684181812294, lng: -97.7476221441387 },
    { lat: 30.2683090462387, lng: -97.7476617536153 },
    { lat: 30.2552289525256, lng: -97.7476641154787 },
    { lat: 30.2654427503301, lng: -97.7476818673185 },
    { lat: 30.2782807053609, lng: -97.7476846227902 },
    { lat: 30.2747332296789, lng: -97.747692212087 },
    { lat: 30.2636467853555, lng: -97.7477008729836 },
    { lat: 30.2900075231493, lng: -97.7477062508685 },
    { lat: 30.2908251415676, lng: -97.7477265138456 },
    { lat: 30.2364815270666, lng: -97.747735111872 },
    { lat: 30.2684864105056, lng: -97.7477363430064 },
    { lat: 30.2684528078872, lng: -97.7477483571886 },
    { lat: 30.2588328262031, lng: -97.7477539687593 },
    { lat: 30.2652485657767, lng: -97.7477577942282 },
    { lat: 30.2716271804943, lng: -97.7477599672196 },
    { lat: 30.2364898330669, lng: -97.7477727195879 },
    { lat: 30.2545219128621, lng: -97.747778642231 },
    { lat: 30.2715585372761, lng: -97.7477826773994 },
    { lat: 30.2364926017339, lng: -97.7477852554931 },
    { lat: 30.2544983667303, lng: -97.7477870810711 },
    { lat: 30.2683436598964, lng: -97.7477887647209 },
    { lat: 30.2683597068993, lng: -97.7478468743117 },
    { lat: 30.239354218409, lng: -97.7478571300072 },
    { lat: 30.288392110673, lng: -97.7478698367754 },
    { lat: 30.2652815947116, lng: -97.7478724240396 },
    { lat: 30.2882495737237, lng: -97.7478874029549 },
    { lat: 30.2461705889838, lng: -97.7479015100997 },
    { lat: 30.2652926043567, lng: -97.7479106339768 },
    { lat: 30.2685009488968, lng: -97.7479226859615 },
    { lat: 30.2814215283032, lng: -97.747942478788 },
    { lat: 30.2519882475226, lng: -97.7479513627027 },
    { lat: 30.2875039458906, lng: -97.7479514176079 },
    { lat: 30.2706962663173, lng: -97.7479533715706 },
    { lat: 30.2873932185898, lng: -97.747964107284 },
    { lat: 30.297212765092, lng: -97.7479764039798 },
    { lat: 30.2416696072456, lng: -97.7479801110753 },
    { lat: 30.2706146867053, lng: -97.7479824494549 },
    { lat: 30.2813179426542, lng: -97.7479941818985 },
    { lat: 30.2814425348759, lng: -97.7480045775939 },
    { lat: 30.2385986782803, lng: -97.7480066097635 },
    { lat: 30.2538678536409, lng: -97.7480130544551 },
    { lat: 30.2978029833739, lng: -97.7480144008128 },
    { lat: 30.2644879459628, lng: -97.748028449324 },
    { lat: 30.2964848940127, lng: -97.7480329327381 },
    { lat: 30.2964036574933, lng: -97.7480417062857 },
    { lat: 30.2644380856018, lng: -97.7480469422831 },
    { lat: 30.2644380856018, lng: -97.7480469422831 },
    { lat: 30.287157640907, lng: -97.7481109365836 },
    { lat: 30.245180402144, lng: -97.7481332466625 },
    { lat: 30.253462336924, lng: -97.7481583900341 },
    { lat: 30.2584595033535, lng: -97.7481615469995 },
    { lat: 30.2738437659809, lng: -97.7481621180703 },
    { lat: 30.2705455474568, lng: -97.7481686333796 },
    { lat: 30.2644797500286, lng: -97.7482083919038 },
    { lat: 30.2881416672785, lng: -97.748210456323 },
    { lat: 30.2881416672785, lng: -97.748210456323 },
    { lat: 30.2685811839128, lng: -97.7482132339161 },
    { lat: 30.25199178766, lng: -97.7482228734131 },
    { lat: 30.2848180576385, lng: -97.748224044935 },
    { lat: 30.2655058981731, lng: -97.7482316420074 },
    { lat: 30.2532033294725, lng: -97.748251217275 },
    { lat: 30.2619814816806, lng: -97.7482578744249 },
    { lat: 30.2737653202984, lng: -97.7482751526922 },
    { lat: 30.2705786756159, lng: -97.7482865306291 },
    { lat: 30.2705786756159, lng: -97.7482865306291 },
    { lat: 30.2736508151043, lng: -97.7482958137108 },
    { lat: 30.2697024315098, lng: -97.748328620439 },
    { lat: 30.2729013474927, lng: -97.7483483705287 },
    { lat: 30.2620931602451, lng: -97.7483516512954 },
    { lat: 30.2804340017506, lng: -97.7483519372457 },
    { lat: 30.2399720427118, lng: -97.7483663753842 },
    { lat: 30.2462621413184, lng: -97.748367046239 },
    { lat: 30.2836417335072, lng: -97.7483730997545 },
    { lat: 30.2666608785573, lng: -97.7484556998415 },
    { lat: 30.2697042203601, lng: -97.7484674175472 },
    { lat: 30.2697041166073, lng: -97.7484676561304 },
    { lat: 30.2666076425251, lng: -97.7484777265627 },
    { lat: 30.2428269902423, lng: -97.7484783925244 },
    { lat: 30.2981347786238, lng: -97.7484973711261 },
    { lat: 30.269595471477, lng: -97.7485092433834 },
    { lat: 30.2728335692393, lng: -97.7485096825837 },
    { lat: 30.2494877458559, lng: -97.7485145372773 },
    { lat: 30.2728168948423, lng: -97.7485153486414 },
    { lat: 30.2814773446583, lng: -97.7485202084138 },
    { lat: 30.2696011628353, lng: -97.7485290818286 },
    { lat: 30.2666276087788, lng: -97.7485511508355 },
    { lat: 30.2585509523416, lng: -97.7485859833536 },
    { lat: 30.2696182369102, lng: -97.748588597164 },
    { lat: 30.2657837394099, lng: -97.7486046950242 },
    { lat: 30.240257787615, lng: -97.748620450465 },
    { lat: 30.2586590915084, lng: -97.7486397278236 },
    { lat: 30.2656305819419, lng: -97.7486541521285 },
    { lat: 30.266538272989, lng: -97.748664419697 },
    { lat: 30.2697611339431, lng: -97.7486658019988 },
    { lat: 30.2520905001993, lng: -97.748666494474 },
    { lat: 30.2696410023433, lng: -97.7486679509447 },
    { lat: 30.2687665013936, lng: -97.7486781219901 },
    { lat: 30.2687147716854, lng: -97.7486973986284 },
    { lat: 30.2719068507804, lng: -97.7487005652911 },
    { lat: 30.2609347023159, lng: -97.7487040110512 },
    { lat: 30.2523463697693, lng: -97.7487076861452 },
    { lat: 30.2589024460179, lng: -97.748707702891 },
    { lat: 30.2518996624807, lng: -97.7487388812012 },
    { lat: 30.2688878242547, lng: -97.7487722937494 },
    { lat: 30.265662185024, lng: -97.7487802282557 },
    { lat: 30.2521261801292, lng: -97.7487925570823 },
    { lat: 30.2361658642965, lng: -97.748799987915 },
    { lat: 30.2688016080747, lng: -97.7488044214796 },
    { lat: 30.2520880125854, lng: -97.7488070344278 },
    { lat: 30.2687506172611, lng: -97.7488219880851 },
    { lat: 30.2361762729678, lng: -97.7488296526234 },
    { lat: 30.2718894727096, lng: -97.7488456236577 },
    { lat: 30.2510383846041, lng: -97.7488463760434 },
    { lat: 30.2718724217119, lng: -97.7488517133004 },
    { lat: 30.2750591261935, lng: -97.7488663477535 },
    { lat: 30.2686481245978, lng: -97.7488848662276 },
    { lat: 30.2337554763377, lng: -97.7488917410303 },
    { lat: 30.274977678632, lng: -97.7488961456446 },
    { lat: 30.2687740195054, lng: -97.7489006456141 },
    { lat: 30.2607743528178, lng: -97.7489137260901 },
    { lat: 30.2687798700666, lng: -97.7489203099963 },
    { lat: 30.2718939676746, lng: -97.7489315697373 },
    { lat: 30.2652129409468, lng: -97.7489606279267 },
    { lat: 30.2475466426554, lng: -97.748995479581 },
    { lat: 30.2335749914036, lng: -97.7490035761706 },
    { lat: 30.2750456123134, lng: -97.7490137433666 },
    { lat: 30.2761081284594, lng: -97.7490217610216 },
    { lat: 30.2686890785255, lng: -97.7490225169032 },
    { lat: 30.2709690341967, lng: -97.7490520398658 },
    { lat: 30.2392967108951, lng: -97.7490574694455 },
    { lat: 30.2510469443947, lng: -97.7490614948835 },
    { lat: 30.2938783384066, lng: -97.7490631136191 },
    { lat: 30.2709195004567, lng: -97.7490707364261 },
    { lat: 30.2444505723623, lng: -97.7490736347066 },
    { lat: 30.2337050378357, lng: -97.7490767959664 },
    { lat: 30.2509225748075, lng: -97.7491067654174 },
    { lat: 30.2740482357044, lng: -97.7491137131561 },
    { lat: 30.2509018465431, lng: -97.7491143105064 },
    { lat: 30.2648031516079, lng: -97.7491280899295 },
    { lat: 30.2647277711847, lng: -97.7491591208742 },
    { lat: 30.2678666652249, lng: -97.7491713579265 },
    { lat: 30.2710210612297, lng: -97.74917195657 },
    { lat: 30.2678505112258, lng: -97.7491777387566 },
    { lat: 30.2709880387363, lng: -97.7491844209436 },
    { lat: 30.2937876566075, lng: -97.7492023721397 },
    { lat: 30.2708461142894, lng: -97.7492380357269 },
    { lat: 30.2509154904129, lng: -97.7492484028912 },
    { lat: 30.2598365269555, lng: -97.7492494220214 },
    { lat: 30.2883595358586, lng: -97.7492541474018 },
    { lat: 30.2740351175558, lng: -97.7492547148427 },
    { lat: 30.2749955124945, lng: -97.7492568491217 },
    { lat: 30.2897497371257, lng: -97.7492620764109 },
    { lat: 30.2584455162509, lng: -97.7492732008614 },
    { lat: 30.2597587861749, lng: -97.7492758727784 },
    { lat: 30.2598870617404, lng: -97.7492813508125 },
    { lat: 30.2750150051109, lng: -97.7493237442244 },
    { lat: 30.2674255454728, lng: -97.749324623344 },
    { lat: 30.28830678058, lng: -97.7493253713936 },
    { lat: 30.2873771514385, lng: -97.7493653034359 },
    { lat: 30.2677920110222, lng: -97.7493697085971 },
    { lat: 30.2884861132736, lng: -97.7493760202004 },
    { lat: 30.2739595094382, lng: -97.7493942845866 },
    { lat: 30.2598725956209, lng: -97.749423348433 },
    { lat: 30.2699825551949, lng: -97.7494391896742 },
    { lat: 30.266814784798, lng: -97.7494603723937 },
    { lat: 30.2741125579503, lng: -97.7494818152862 },
    { lat: 30.2669308480784, lng: -97.7494919474797 },
    { lat: 30.2669308480784, lng: -97.7494919474797 },
    { lat: 30.2701714834422, lng: -97.7495048693794 },
    { lat: 30.2699467841862, lng: -97.7495233722274 },
    { lat: 30.2699467841862, lng: -97.7495233722274 },
    { lat: 30.2669034804218, lng: -97.7495482395772 },
    { lat: 30.2732049798122, lng: -97.749556182301 },
    { lat: 30.2700207734113, lng: -97.7495616203969 },
    { lat: 30.2352478367532, lng: -97.7495677852226 },
    { lat: 30.2731234200804, lng: -97.7495852170897 },
    { lat: 30.2498227881347, lng: -97.7495908731768 },
    { lat: 30.2667958362881, lng: -97.7495937342964 },
    { lat: 30.2431090468025, lng: -97.7496032420451 },
    { lat: 30.2699137507695, lng: -97.7496090416103 },
    { lat: 30.2495888563881, lng: -97.7496110380243 },
    { lat: 30.2700397557068, lng: -97.7496190539989 },
    { lat: 30.264867775513, lng: -97.7496231351324 },
    { lat: 30.2500914411715, lng: -97.7496398059863 },
    { lat: 30.2700524105706, lng: -97.7496573430671 },
    { lat: 30.2497865018607, lng: -97.7496747422764 },
    { lat: 30.2856589855001, lng: -97.7496793638806 },
    { lat: 30.2699390604969, lng: -97.7496856197464 },
    { lat: 30.2648873761187, lng: -97.7496880971286 },
    { lat: 30.2700650654343, lng: -97.7496956321351 },
    { lat: 30.2497057606056, lng: -97.7497058276623 },
    { lat: 30.2458078916869, lng: -97.7497075419741 },
    { lat: 30.2700713928662, lng: -97.7497147766691 },
    { lat: 30.2588266967605, lng: -97.7497379080932 },
    { lat: 30.2699580427925, lng: -97.7497430533484 },
    { lat: 30.2947561263358, lng: -97.7497478693825 },
    { lat: 30.2700840477298, lng: -97.7497530657371 },
    { lat: 30.2691243900922, lng: -97.7497553298154 },
    { lat: 30.2691073338611, lng: -97.7497615837674 },
    { lat: 30.2699706976562, lng: -97.7497813424164 },
    { lat: 30.2742209745031, lng: -97.7497997559071 },
    { lat: 30.2321797967538, lng: -97.749807586688 },
    { lat: 30.2936259680715, lng: -97.7498111136604 },
    { lat: 30.2948495080303, lng: -97.7498207157404 },
    { lat: 30.2321534723266, lng: -97.7498227566977 },
    { lat: 30.2660099053949, lng: -97.7498253357616 },
    { lat: 30.2660099053949, lng: -97.7498253357616 },
    { lat: 30.2659475544072, lng: -97.749863135629 },
    { lat: 30.2690017843841, lng: -97.7498698721867 },
    { lat: 30.2691760416927, lng: -97.7498755647751 },
    { lat: 30.2691589854616, lng: -97.7498818187272 },
    { lat: 30.2691419292303, lng: -97.7498880726792 },
    { lat: 30.2488516855984, lng: -97.7498923898607 },
    { lat: 30.2766200361339, lng: -97.7498969882012 },
    { lat: 30.269074040271, lng: -97.7499123307383 },
    { lat: 30.2722049210887, lng: -97.7499429585532 },
    { lat: 30.2339184013766, lng: -97.7499741228974 },
    { lat: 30.2691016335567, lng: -97.7500101245704 },
    { lat: 30.2488839297496, lng: -97.7500196827235 },
    { lat: 30.2486883904953, lng: -97.7500860584403 },
    { lat: 30.2584565026346, lng: -97.7501041735323 },
    { lat: 30.2381495406907, lng: -97.7501234165086 },
    { lat: 30.2583568643188, lng: -97.7501571337339 },
    { lat: 30.237061437968, lng: -97.7501577023832 },
    { lat: 30.2776675057173, lng: -97.7501977578167 },
    { lat: 30.2765658530442, lng: -97.7501991890778 },
    { lat: 30.237070905066, lng: -97.7502014877023 },
    { lat: 30.2478692755914, lng: -97.7502285155337 },
    { lat: 30.2478527586921, lng: -97.7502349117843 },
    { lat: 30.2612025371364, lng: -97.7502349369162 },
    { lat: 30.271390928187, lng: -97.7502362342058 },
    { lat: 30.2612353845575, lng: -97.7502801795843 },
    { lat: 30.2765973907958, lng: -97.7502942035287 },
    { lat: 30.2369820727687, lng: -97.7503369683337 },
    { lat: 30.2480276132146, lng: -97.7503530598207 },
    { lat: 30.2479056297207, lng: -97.7503543143316 },
    { lat: 30.2478891128216, lng: -97.7503607105824 },
    { lat: 30.2732115240204, lng: -97.7503662421388 },
    { lat: 30.2478560790231, lng: -97.7503735030838 },
    { lat: 30.2478230452245, lng: -97.7503862955854 },
    { lat: 30.2478065283253, lng: -97.7503926918361 },
    { lat: 30.276519526848, lng: -97.7504315412661 },
    { lat: 30.2765268798805, lng: -97.750454213112 },
    { lat: 30.2765268798805, lng: -97.750454213112 },
    { lat: 30.2704456233228, lng: -97.750457896239 },
    { lat: 30.2411368760396, lng: -97.7504839251083 },
    { lat: 30.2800089706205, lng: -97.7504894882789 },
    { lat: 30.2703498227091, lng: -97.7504951520364 },
    { lat: 30.2703338559402, lng: -97.7505013613359 },
    { lat: 30.2884242619277, lng: -97.7505112418292 },
    { lat: 30.2755748389519, lng: -97.7505155621414 },
    { lat: 30.2743467114118, lng: -97.7505297868043 },
    { lat: 30.2469518002351, lng: -97.750582819882 },
    { lat: 30.2702977841557, lng: -97.7505853719465 },
    { lat: 30.2469184198541, lng: -97.7505951349752 },
    { lat: 30.2704501743508, lng: -97.7505960917809 },
    { lat: 30.2703703405061, lng: -97.7506271382787 },
    { lat: 30.2897825748131, lng: -97.7506692374101 },
    { lat: 30.2702669431685, lng: -97.750672130418 },
    { lat: 30.2871648973531, lng: -97.7506805656839 },
    { lat: 30.2469865967366, lng: -97.7507092064025 },
    { lat: 30.2794198592426, lng: -97.7507356721358 },
    { lat: 30.2468864555935, lng: -97.750746151682 },
    { lat: 30.250421187421, lng: -97.7507518706831 },
    { lat: 30.246869765403, lng: -97.7507523092285 },
    { lat: 30.2470114837455, lng: -97.7507951626507 },
    { lat: 30.2897228735246, lng: -97.7508591638617 },
    { lat: 30.2693447669373, lng: -97.7508708947262 },
    { lat: 30.2461339809001, lng: -97.7508845396641 },
    { lat: 30.2885513474541, lng: -97.7509007320368 },
    { lat: 30.2885045853643, lng: -97.7509044062031 },
    { lat: 30.2568053732172, lng: -97.7509340800945 },
    { lat: 30.2459390847261, lng: -97.7509543119524 },
    { lat: 30.2693762433154, lng: -97.7510045616966 },
    { lat: 30.2461694330516, lng: -97.7510094242511 },
    { lat: 30.2460070421253, lng: -97.751068975702 },
    { lat: 30.2458691789408, lng: -97.7511177480597 },
    { lat: 30.2772876929419, lng: -97.7511306137794 },
    { lat: 30.2704327026721, lng: -97.7511325734051 },
    { lat: 30.2564086351686, lng: -97.7511830292801 },
    { lat: 30.2491965458028, lng: -97.7511915729058 },
    { lat: 30.2563927656465, lng: -97.7511929872475 },
    { lat: 30.2686855175525, lng: -97.7512410848003 },
    { lat: 30.2686855175525, lng: -97.7512410848003 },
    { lat: 30.2857848530508, lng: -97.751245539919 },
    { lat: 30.2450710632858, lng: -97.7512617371432 },
    { lat: 30.2414868128802, lng: -97.7512822247401 },
    { lat: 30.2653795324932, lng: -97.7512894844278 },
    { lat: 30.2653795324932, lng: -97.7512894844278 },
    { lat: 30.2778851419715, lng: -97.7513261697242 },
    { lat: 30.2503055590245, lng: -97.7513686376237 },
    { lat: 30.2693318338023, lng: -97.7513810786966 },
    { lat: 30.2563293545893, lng: -97.7513836737329 },
    { lat: 30.2451050930708, lng: -97.7513884006841 },
    { lat: 30.2481861664079, lng: -97.7513992632224 },
    { lat: 30.2617070545384, lng: -97.7514224699841 },
    { lat: 30.2450082876089, lng: -97.7514232506538 },
    { lat: 30.2589246794306, lng: -97.7514300919168 },
    { lat: 30.2769681789828, lng: -97.7514311831969 },
    { lat: 30.2449437506343, lng: -97.7514464839667 },
    { lat: 30.2677374543707, lng: -97.7514577639894 },
    { lat: 30.2677374543707, lng: -97.7514577639894 },
    { lat: 30.2677220465414, lng: -97.751463541926 },
    { lat: 30.258818607952, lng: -97.7514802919182 },
    { lat: 30.2885034560257, lng: -97.7515237306749 },
    { lat: 30.2588342138453, lng: -97.7515244891977 },
    { lat: 30.2677881703679, lng: -97.7515782089211 },
    { lat: 30.2677459187692, lng: -97.751584984724 },
    { lat: 30.2482756929207, lng: -97.7515876278069 },
    { lat: 30.267757354709, lng: -97.7515897647941 },
    { lat: 30.2411873748968, lng: -97.7515910670278 },
    { lat: 30.2677419468797, lng: -97.7515955427306 },
    { lat: 30.2748369396127, lng: -97.7516024164821 },
    { lat: 30.2618049987714, lng: -97.7516392579177 },
    { lat: 30.2671718062351, lng: -97.7516537214052 },
    { lat: 30.273564783556, lng: -97.7517103715096 },
    { lat: 30.2350397291131, lng: -97.7517253958229 },
    { lat: 30.2736398466474, lng: -97.7517457046329 },
    { lat: 30.2410056215333, lng: -97.7517536064697 },
    { lat: 30.2672159269298, lng: -97.7517774723907 },
    { lat: 30.2554564638516, lng: -97.7517805073255 },
    { lat: 30.2671610799221, lng: -97.7517941835902 },
    { lat: 30.2735342688729, lng: -97.7517972874856 },
    { lat: 30.284740518369, lng: -97.7518262875082 },
    { lat: 30.2439012405556, lng: -97.7518339129181 },
    { lat: 30.2858234347191, lng: -97.7518629332199 },
    { lat: 30.2760334961175, lng: -97.7518636864936 },
    { lat: 30.2873532912461, lng: -97.751865460525 },
    { lat: 30.2472840625686, lng: -97.7518933486167 },
    { lat: 30.2472673931545, lng: -97.7518988511424 },
    { lat: 30.2554782117783, lng: -97.7519177573941 },
    { lat: 30.2737027459124, lng: -97.7519183118893 },
    { lat: 30.2737027459124, lng: -97.7519183118893 },
    { lat: 30.2874509836656, lng: -97.7519348227816 },
    { lat: 30.2708052902883, lng: -97.7519379142818 },
    { lat: 30.270704856734, lng: -97.7520030845357 },
    { lat: 30.2679178781789, lng: -97.7520122777339 },
    { lat: 30.2416803622167, lng: -97.7520153011793 },
    { lat: 30.2434315075267, lng: -97.7520224826778 },
    { lat: 30.2708375008392, lng: -97.7520227766802 },
    { lat: 30.2760900030666, lng: -97.7520237894889 },
    { lat: 30.2656096093577, lng: -97.7520620063984 },
    { lat: 30.246324605921, lng: -97.7520739819548 },
    { lat: 30.2836973521494, lng: -97.7520976516308 },
    { lat: 30.2707451199225, lng: -97.7521091625338 },
    { lat: 30.2707585409852, lng: -97.7521445218665 },
    { lat: 30.2656347945277, lng: -97.7521467201361 },
    { lat: 30.2707809094233, lng: -97.7522034540875 },
    { lat: 30.2948857793952, lng: -97.7522098703592 },
    { lat: 30.2465117529997, lng: -97.7522145702586 },
    { lat: 30.2680038578839, lng: -97.7522259242387 },
    { lat: 30.2773388695687, lng: -97.7522406382802 },
    { lat: 30.275208424921, lng: -97.752256419933 },
    { lat: 30.2425987900008, lng: -97.75232414799 },
    { lat: 30.2454937890868, lng: -97.752434872677 },
    { lat: 30.2926153045556, lng: -97.7524425674333 },
    { lat: 30.2591619376026, lng: -97.7524526320669 },
    { lat: 30.2357467909994, lng: -97.7524528731395 },
    { lat: 30.2591697405491, lng: -97.7524747307067 },
    { lat: 30.2593070238141, lng: -97.7525129252641 },
    { lat: 30.2454083827627, lng: -97.7525395107243 },
    { lat: 30.2592009523355, lng: -97.7525631252655 },
    { lat: 30.241171696146, lng: -97.752601556432 },
    { lat: 30.2741200675655, lng: -97.7526224171284 },
    { lat: 30.2740615554722, lng: -97.7526496897162 },
    { lat: 30.2795302542082, lng: -97.7526948988083 },
    { lat: 30.2763728464337, lng: -97.7527180736074 },
    { lat: 30.2405720283565, lng: -97.7527237226981 },
    { lat: 30.2712817896909, lng: -97.7527921623475 },
    { lat: 30.2340009964768, lng: -97.7527939841772 },
    { lat: 30.2712583050267, lng: -97.7528040351506 },
    { lat: 30.2740263299392, lng: -97.7528091416251 },
    { lat: 30.2712113356986, lng: -97.7528277807568 },
    { lat: 30.2384174811406, lng: -97.7528480210896 },
    { lat: 30.244644477332, lng: -97.7528707709397 },
    { lat: 30.2396785817898, lng: -97.7528849411684 },
    { lat: 30.2421342803779, lng: -97.7529576629713 },
    { lat: 30.2712102195162, lng: -97.752973123977 },
    { lat: 30.2699280357846, lng: -97.7529957625674 },
    { lat: 30.2712252987174, lng: -97.7530085833431 },
    { lat: 30.2701585081504, lng: -97.7530196402547 },
    { lat: 30.2698594775047, lng: -97.7530320581294 },
    { lat: 30.2150277491846, lng: -97.7530673370423 },
    { lat: 30.2497394650798, lng: -97.753075159997 },
    { lat: 30.2860581949972, lng: -97.7531191361419 },
    { lat: 30.2712690576744, lng: -97.7531194393493 },
    { lat: 30.2711471005362, lng: -97.7531296647084 },
    { lat: 30.2711471005362, lng: -97.7531296647084 },
    { lat: 30.2549925355982, lng: -97.7531445674822 },
    { lat: 30.2777137934547, lng: -97.7531991301526 },
    { lat: 30.2418400827529, lng: -97.7532015766131 },
    { lat: 30.2698154712381, lng: -97.7532018716484 },
    { lat: 30.2466637888852, lng: -97.7532088724284 },
    { lat: 30.294623448412, lng: -97.7532165238947 },
    { lat: 30.2418167786922, lng: -97.7532221590325 },
    { lat: 30.2775660030511, lng: -97.7532667806941 },
    { lat: 30.2778298153779, lng: -97.7532887045734 },
    { lat: 30.2466266455033, lng: -97.7532922345178 },
    { lat: 30.2465496207217, lng: -97.7533207176428 },
    { lat: 30.2697382950447, lng: -97.753327110128 },
    { lat: 30.2699071579236, lng: -97.7534394234761 },
    { lat: 30.2775164222014, lng: -97.7534708084393 },
    { lat: 30.2775280908692, lng: -97.7535011469711 },
    { lat: 30.2596621053286, lng: -97.7535207491473 },
    { lat: 30.2698206259459, lng: -97.7535404219733 },
    { lat: 30.2796026478726, lng: -97.7535645247866 },
    { lat: 30.2342639765693, lng: -97.7535819282482 },
    { lat: 30.2577555878156, lng: -97.7535907340212 },
    { lat: 30.2458815054932, lng: -97.7536074241975 },
    { lat: 30.2788846989658, lng: -97.7536097914364 },
    { lat: 30.2595699444968, lng: -97.7536118206953 },
    { lat: 30.2488265199116, lng: -97.753654401169 },
    { lat: 30.2458962059081, lng: -97.7536611794354 },
    { lat: 30.2541185282193, lng: -97.7536701996471 },
    { lat: 30.2685902524822, lng: -97.7536794768349 },
    { lat: 30.2526346052987, lng: -97.7536981644744 },
    { lat: 30.2808128659011, lng: -97.7537084179887 },
    { lat: 30.2686471137417, lng: -97.7537954831917 },
    { lat: 30.2686362647339, lng: -97.7538009905126 },
    { lat: 30.2596349376204, lng: -97.7538026515354 },
    { lat: 30.2742847834907, lng: -97.7538028709544 },
    { lat: 30.280324127973, lng: -97.7538331638069 },
    { lat: 30.271816224891, lng: -97.7539003342493 },
    { lat: 30.2718007442675, lng: -97.7539079879162 },
    { lat: 30.2717697830204, lng: -97.7539232952502 },
    { lat: 30.2715397840347, lng: -97.7539648710461 },
    { lat: 30.2535886122945, lng: -97.7539902897073 },
    { lat: 30.2716159146336, lng: -97.753996995552 },
    { lat: 30.2716149767846, lng: -97.7539998319201 },
    { lat: 30.2716304870968, lng: -97.7540332185258 },
    { lat: 30.2744384660043, lng: -97.7540558638259 },
    { lat: 30.2715193260634, lng: -97.7540711327636 },
    { lat: 30.2437075506347, lng: -97.7541035779748 },
    { lat: 30.2716596320229, lng: -97.7541056644734 },
    { lat: 30.2678992683465, lng: -97.7541081709569 },
    { lat: 30.2678992683465, lng: -97.7541081709569 },
    { lat: 30.2716669182544, lng: -97.7541237759602 },
    { lat: 30.271541184758, lng: -97.7541254672243 },
    { lat: 30.2971414266118, lng: -97.7541356952126 },
    { lat: 30.258754733349, lng: -97.7541361131386 },
    { lat: 30.271674204486, lng: -97.7541418874471 },
    { lat: 30.2715484709895, lng: -97.7541435787112 },
    { lat: 30.267875023492, lng: -97.7541533545512 },
    { lat: 30.2779264351623, lng: -97.7541986722525 },
    { lat: 30.2469177651736, lng: -97.7542509007447 },
    { lat: 30.2688250880738, lng: -97.7543145096934 },
    { lat: 30.2778684059563, lng: -97.7543452055306 },
    { lat: 30.2687198682598, lng: -97.7543670611495 },
    { lat: 30.2771934551256, lng: -97.7543714695985 },
    { lat: 30.2687227660126, lng: -97.7543748393266 },
    { lat: 30.2449335588147, lng: -97.7543761124976 },
    { lat: 30.2687256637654, lng: -97.7543826175039 },
    { lat: 30.2732632118016, lng: -97.7543875343258 },
    { lat: 30.2515121704892, lng: -97.7544205831158 },
    { lat: 30.2394352652181, lng: -97.7544364316796 },
    { lat: 30.2663398725079, lng: -97.7544437498965 },
    { lat: 30.2512368680333, lng: -97.7544598879276 },
    { lat: 30.2706448104134, lng: -97.7544879212564 },
    { lat: 30.2663475498767, lng: -97.7545036333535 },
    { lat: 30.2663501089997, lng: -97.7545235945059 },
    { lat: 30.2771463931364, lng: -97.7545317016617 },
    { lat: 30.2394919144209, lng: -97.7545322203179 },
    { lat: 30.2733257675554, lng: -97.7545387107067 },
    { lat: 30.2822563174209, lng: -97.7545394767569 },
    { lat: 30.2600231782375, lng: -97.7545809204809 },
    { lat: 30.2509428584143, lng: -97.7545820625861 },
    { lat: 30.2704577218954, lng: -97.7545821385014 },
    { lat: 30.2702887843028, lng: -97.7545948709495 },
    { lat: 30.2450217100569, lng: -97.7545985919312 },
    { lat: 30.2703641776363, lng: -97.754629247124 },
    { lat: 30.2636730987874, lng: -97.754645434368 },
    { lat: 30.2963399446202, lng: -97.7546505001387 },
    { lat: 30.2599237959476, lng: -97.7546507886023 },
    { lat: 30.2702626194723, lng: -97.754683665055 },
    { lat: 30.2702626194723, lng: -97.754683665055 },
    { lat: 30.2450480277012, lng: -97.7546931993063 },
    { lat: 30.2962380588712, lng: -97.7547112406265 },
    { lat: 30.2505885743326, lng: -97.7547310512083 },
    { lat: 30.2460782435249, lng: -97.7547663631135 },
    { lat: 30.2794645688247, lng: -97.754798965356 },
    { lat: 30.2697448719641, lng: -97.7548308116274 },
    { lat: 30.2697448719641, lng: -97.7548308116274 },
    { lat: 30.2793565655209, lng: -97.7548471034824 },
    { lat: 30.2761782681587, lng: -97.7548710789067 },
    { lat: 30.2600030769469, lng: -97.7548840169193 },
    { lat: 30.2412673497813, lng: -97.7549450881542 },
    { lat: 30.2638526463417, lng: -97.7549558261808 },
    { lat: 30.2113783183881, lng: -97.7550593309999 },
    { lat: 30.2691329279208, lng: -97.7551117495938 },
    { lat: 30.2440304332967, lng: -97.7551399393706 },
    { lat: 30.2492369441264, lng: -97.7551464307976 },
    { lat: 30.2720688748069, lng: -97.7551544924113 },
    { lat: 30.2495886149228, lng: -97.7551602874823 },
    { lat: 30.2440457543506, lng: -97.7551704704519 },
    { lat: 30.2407847552948, lng: -97.7551780317012 },
    { lat: 30.249479284217, lng: -97.7552000197941 },
    { lat: 30.2541116739222, lng: -97.7552305411169 },
    { lat: 30.2805019158003, lng: -97.7552309567356 },
    { lat: 30.2691751646943, lng: -97.7552350881455 },
    { lat: 30.2804767388292, lng: -97.755244636224 },
    { lat: 30.2689796583934, lng: -97.7553253482008 },
    { lat: 30.2491032828194, lng: -97.7553300548983 },
    { lat: 30.2836275546721, lng: -97.7554298991864 },
    { lat: 30.2483889455161, lng: -97.7554343559362 },
    { lat: 30.2483705107637, lng: -97.7554406151783 },
    { lat: 30.2482783370018, lng: -97.7554719113891 },
    { lat: 30.2720691010105, lng: -97.7554746176786 },
    { lat: 30.2722006590843, lng: -97.7554896625299 },
    { lat: 30.2681671258306, lng: -97.7555353466694 },
    { lat: 30.2722277522613, lng: -97.7555592427224 },
    { lat: 30.2484211969805, lng: -97.7555616468575 },
    { lat: 30.2482368494566, lng: -97.755624239279 },
    { lat: 30.2722683920264, lng: -97.7556636130113 },
    { lat: 30.2722954852031, lng: -97.7557331932038 },
    { lat: 30.2723090317915, lng: -97.7557679833001 },
    { lat: 30.2712083328251, lng: -97.7557805657797 },
    { lat: 30.2602990638238, lng: -97.7557845985821 },
    { lat: 30.2099746124181, lng: -97.755809716141 },
    { lat: 30.2746414740479, lng: -97.7558260837271 },
    { lat: 30.2713386390359, lng: -97.7558578804147 },
    { lat: 30.2604288923046, lng: -97.7558579452475 },
    { lat: 30.2603130938197, lng: -97.7558644359188 },
    { lat: 30.2603166013187, lng: -97.7558843952529 },
    { lat: 30.2474215862475, lng: -97.7559125173601 },
    { lat: 30.2752967825373, lng: -97.7559210158403 },
    { lat: 30.2820793404273, lng: -97.7559436044042 },
    { lat: 30.2095110785931, lng: -97.7560994247951 },
    { lat: 30.2820220575392, lng: -97.7561376483259 },
    { lat: 30.277154870614, lng: -97.7561634160724 },
    { lat: 30.2789342325886, lng: -97.7562019724966 },
    { lat: 30.2551876349058, lng: -97.7562048897217 },
    { lat: 30.2551622976806, lng: -97.7562151556672 },
    { lat: 30.252439487442, lng: -97.7562225299216 },
    { lat: 30.2743875911954, lng: -97.7562523227609 },
    { lat: 30.27558390014, lng: -97.7562590505753 },
    { lat: 30.2707297783115, lng: -97.7562630940381 },
    { lat: 30.27704011659, lng: -97.7563018075057 },
    { lat: 30.2576742399211, lng: -97.75630884866 },
    { lat: 30.2603937662966, lng: -97.7563235006045 },
    { lat: 30.2585291900267, lng: -97.7563241432846 },
    { lat: 30.2464562585515, lng: -97.7563356932352 },
    { lat: 30.2884949070515, lng: -97.7563649121722 },
    { lat: 30.2754989735661, lng: -97.7563670284072 },
    { lat: 30.2465431006087, lng: -97.7564312464684 },
    { lat: 30.2702032916509, lng: -97.7564383241783 },
    { lat: 30.2576499263932, lng: -97.756451262999 },
    { lat: 30.2701762158417, lng: -97.7564516181575 },
    { lat: 30.2466680367733, lng: -97.7564910199408 },
    { lat: 30.2918448094502, lng: -97.7565089001025 },
    { lat: 30.2466757514065, lng: -97.7565180211515 },
    { lat: 30.2545232471138, lng: -97.7565213972195 },
    { lat: 30.2725077612159, lng: -97.7566043908231 },
    { lat: 30.2809048355434, lng: -97.7566096979612 },
    { lat: 30.2086855149691, lng: -97.7566187269247 },
    { lat: 30.241442426749, lng: -97.7566338814672 },
    { lat: 30.2546227080399, lng: -97.7566470307375 },
    { lat: 30.278183788054, lng: -97.7566809851971 },
    { lat: 30.2517467222995, lng: -97.7567463409639 },
    { lat: 30.2517584504418, lng: -97.7567709700591 },
    { lat: 30.2757731653987, lng: -97.756775890233 },
    { lat: 30.2829108971685, lng: -97.7567856870931 },
    { lat: 30.2828063969644, lng: -97.7568160774623 },
    { lat: 30.282771563563, lng: -97.7568262075853 },
    { lat: 30.2791537826479, lng: -97.7568853699535 },
    { lat: 30.2562685313879, lng: -97.7568864877708 },
    { lat: 30.2758189869795, lng: -97.7568947628605 },
    { lat: 30.2539261337017, lng: -97.7568954453188 },
    { lat: 30.2455296874104, lng: -97.756935145148 },
    { lat: 30.2488178657511, lng: -97.756939949821 },
    { lat: 30.2537997743482, lng: -97.7569437487268 },
    { lat: 30.2539492297514, lng: -97.7569441478515 },
    { lat: 30.2538487803212, lng: -97.7570079897016 },
    { lat: 30.2729552672864, lng: -97.7570647155391 },
    { lat: 30.2984947788687, lng: -97.7571868689472 },
    { lat: 30.2984947788687, lng: -97.7571868689472 },
    { lat: 30.2645228757344, lng: -97.7571994352559 },
    { lat: 30.2669784039781, lng: -97.7572022763178 },
    { lat: 30.2727355331639, lng: -97.7572069154362 },
    { lat: 30.2727426482767, lng: -97.7572274236989 },
    { lat: 30.264455890673, lng: -97.7572664203195 },
    { lat: 30.2842477816649, lng: -97.7573083696031 },
    { lat: 30.2873092268525, lng: -97.7573453754198 },
    { lat: 30.2714252906947, lng: -97.7573963731355 },
    { lat: 30.2713033382373, lng: -97.7574047770361 },
    { lat: 30.2478834433398, lng: -97.7574201594574 },
    { lat: 30.253022152639, lng: -97.7574344402224 },
    { lat: 30.2714479810799, lng: -97.7574562505306 },
    { lat: 30.2713554552156, lng: -97.7575423079281 },
    { lat: 30.2532020587755, lng: -97.757543066477 },
    { lat: 30.2874010822669, lng: -97.7575455640976 },
    { lat: 30.2761084869881, lng: -97.7576004709307 },
    { lat: 30.2071439487751, lng: -97.75765210328 },
    { lat: 30.2443024246955, lng: -97.7577087944102 },
    { lat: 30.2442587478288, lng: -97.7577371843748 },
    { lat: 30.2778634492698, lng: -97.7577491172199 },
    { lat: 30.2606720242514, lng: -97.7577744331958 },
    { lat: 30.2445656201884, lng: -97.757797874829 },
    { lat: 30.2829824415323, lng: -97.7578135179863 },
    { lat: 30.2470530117592, lng: -97.7578274992369 },
    { lat: 30.2778153173294, lng: -97.7579248494099 },
    { lat: 30.2677395480318, lng: -97.7579456800057 },
    { lat: 30.2677395480318, lng: -97.7579456800057 },
    { lat: 30.2731383493958, lng: -97.7579610054163 },
    { lat: 30.2627368524742, lng: -97.75796477201 },
    { lat: 30.2607200249638, lng: -97.7579704053559 },
    { lat: 30.2501743841015, lng: -97.7579942586741 },
    { lat: 30.2730306245794, lng: -97.7580209517304 },
    { lat: 30.2778679505368, lng: -97.7580373948678 },
    { lat: 30.2731689873607, lng: -97.7580581578587 },
    { lat: 30.2899766797248, lng: -97.7580806103555 },
    { lat: 30.2796697052378, lng: -97.7581112778323 },
    { lat: 30.2677777532543, lng: -97.7581863807171 },
    { lat: 30.2731013320949, lng: -97.7582124512191 },
    { lat: 30.2753072200332, lng: -97.7583684602516 },
    { lat: 30.2062261048262, lng: -97.7583719948816 },
    { lat: 30.271669535526, lng: -97.7583761634211 },
    { lat: 30.2680452635022, lng: -97.7583910897287 },
    { lat: 30.2814061428327, lng: -97.7584391437664 },
    { lat: 30.282869640391, lng: -97.7584626417493 },
    { lat: 30.2624109224878, lng: -97.7584901007917 },
    { lat: 30.2459865354454, lng: -97.7585136198986 },
    { lat: 30.2925588435047, lng: -97.7585299142806 },
    { lat: 30.2937058132775, lng: -97.7585631761735 },
    { lat: 30.2608986701953, lng: -97.7585879613312 },
    { lat: 30.2765788886932, lng: -97.7585956041454 },
    { lat: 30.2484284608079, lng: -97.7586323577356 },
    { lat: 30.2863283424983, lng: -97.7586397629686 },
    { lat: 30.2864257812887, lng: -97.7587078691501 },
    { lat: 30.2781892814503, lng: -97.7587179393633 },
    { lat: 30.2552399487535, lng: -97.7588050249716 },
    { lat: 30.2508094361441, lng: -97.7588136650267 },
    { lat: 30.2551368475699, lng: -97.7588701415117 },
    { lat: 30.2742905814505, lng: -97.7588725492358 },
    { lat: 30.2830070772204, lng: -97.7590265368559 },
    { lat: 30.205343212484, lng: -97.7590970145643 },
    { lat: 30.2449087894429, lng: -97.759112871044 },
    { lat: 30.2447662611431, lng: -97.7591264660648 },
    { lat: 30.2793755057421, lng: -97.7591321024122 },
    { lat: 30.2803103555416, lng: -97.759142821849 },
    { lat: 30.2825506087393, lng: -97.7591440061119 },
    { lat: 30.2756392309899, lng: -97.7591533469432 },
    { lat: 30.2496666621852, lng: -97.7591543193097 },
    { lat: 30.2734548696721, lng: -97.7591699486624 },
    { lat: 30.2448855438429, lng: -97.7592027240816 },
    { lat: 30.2825287820716, lng: -97.7592343692068 },
    { lat: 30.2448206378094, lng: -97.759243227461 },
    { lat: 30.2447990024648, lng: -97.7592567285875 },
    { lat: 30.2684577844883, lng: -97.7592704106438 },
    { lat: 30.2612850564753, lng: -97.7592800445321 },
    { lat: 30.2447557317759, lng: -97.7592837308404 },
    { lat: 30.2798540108055, lng: -97.7593031711951 },
    { lat: 30.2496544692449, lng: -97.7593099338729 },
    { lat: 30.2475600030585, lng: -97.7593498448547 },
    { lat: 30.2802755705358, lng: -97.7593514795418 },
    { lat: 30.2449250973105, lng: -97.7593558224019 },
    { lat: 30.2737737699451, lng: -97.7593691771064 },
    { lat: 30.2802932976711, lng: -97.7593861593208 },
    { lat: 30.2804450903784, lng: -97.7594226068282 },
    { lat: 30.2810715698382, lng: -97.7594461223016 },
    { lat: 30.2474679163817, lng: -97.7594469032446 },
    { lat: 30.2735694693791, lng: -97.7594829033281 },
    { lat: 30.2735722174855, lng: -97.7594906753154 },
    { lat: 30.2847317013465, lng: -97.7595125346376 },
    { lat: 30.2737154484439, lng: -97.7595455605178 },
    { lat: 30.2846573518737, lng: -97.7595936971301 },
    { lat: 30.2542114147247, lng: -97.7596129335969 },
    { lat: 30.2538353787652, lng: -97.7597036357001 },
    { lat: 30.2578253784564, lng: -97.7597214213581 },
    { lat: 30.2795576831011, lng: -97.759747490923 },
    { lat: 30.2520501158309, lng: -97.7597789265615 },
    { lat: 30.2736793936371, lng: -97.7597937828167 },
    { lat: 30.2795852398916, lng: -97.7597960778883 },
    { lat: 30.2695810803286, lng: -97.7597995985011 },
    { lat: 30.2520662333917, lng: -97.7598129346097 },
    { lat: 30.2501055290174, lng: -97.7598450960543 },
    { lat: 30.2736986303822, lng: -97.7598481867273 },
    { lat: 30.2737013784887, lng: -97.7598559587145 },
    { lat: 30.2771534201178, lng: -97.7598599966505 },
    { lat: 30.250038468866, lng: -97.7598854329895 },
    { lat: 30.2465914337497, lng: -97.759890363122 },
    { lat: 30.2704953407384, lng: -97.7598947897742 },
    { lat: 30.2464476606062, lng: -97.759907119143 },
    { lat: 30.2880882041427, lng: -97.7599276648528 },
    { lat: 30.2437061964565, lng: -97.7599432575394 },
    { lat: 30.2499267019467, lng: -97.7599526612148 },
    { lat: 30.243728692216, lng: -97.7599751574279 },
    { lat: 30.2466242906596, lng: -97.7599969804222 },
    { lat: 30.2881422355112, lng: -97.7600407150835 },
    { lat: 30.2740278970895, lng: -97.7600447701316 },
    { lat: 30.2465245754269, lng: -97.7600682050942 },
    { lat: 30.20410583999, lng: -97.7600722319048 },
    { lat: 30.2640942687575, lng: -97.7600778139389 },
    { lat: 30.2791255156931, lng: -97.7600881983729 },
    { lat: 30.2614887447008, lng: -97.7601025014306 },
    { lat: 30.2791416565626, lng: -97.7601222830762 },
    { lat: 30.2871187812043, lng: -97.7601253687643 },
    { lat: 30.2687346646146, lng: -97.7601541091015 },
    { lat: 30.2764146745082, lng: -97.7601662144647 },
    { lat: 30.2755534862328, lng: -97.7601813133938 },
    { lat: 30.2514214156508, lng: -97.7601837620055 },
    { lat: 30.2738887838004, lng: -97.7602108693796 },
    { lat: 30.2791900791713, lng: -97.7602245371864 },
    { lat: 30.2739635214296, lng: -97.7602471156772 },
    { lat: 30.2513090642098, lng: -97.7602569244937 },
    { lat: 30.2784008571368, lng: -97.7602706537026 },
    { lat: 30.251341273891, lng: -97.7602911972922 },
    { lat: 30.2738574805776, lng: -97.760297408787 },
    { lat: 30.2762933790018, lng: -97.7603873868963 },
    { lat: 30.2602622273884, lng: -97.7604651466114 },
    { lat: 30.2740588042156, lng: -97.7605164727367 },
    { lat: 30.2780144239469, lng: -97.7605196441713 },
    { lat: 30.2901140341961, lng: -97.7605197266419 },
    { lat: 30.290083020623, lng: -97.76053643784 },
    { lat: 30.2757242333996, lng: -97.7605725380993 },
    { lat: 30.2556966533219, lng: -97.7606103219161 },
    { lat: 30.281653805595, lng: -97.7606456139765 },
    { lat: 30.2479518679833, lng: -97.7606473877444 },
    { lat: 30.2741064456084, lng: -97.7606511512666 },
    { lat: 30.2504334980812, lng: -97.7606836581457 },
    { lat: 30.2454732432304, lng: -97.7606955411344 },
    { lat: 30.2727953747182, lng: -97.7607177382576 },
    { lat: 30.2489668070679, lng: -97.7607420982297 },
    { lat: 30.2690232323963, lng: -97.7607820111227 },
    { lat: 30.2741227837785, lng: -97.7608723692038 },
    { lat: 30.2741992994648, lng: -97.7609032434841 },
    { lat: 30.2567934540267, lng: -97.7609378046217 },
    { lat: 30.2875237811549, lng: -97.76094886854 },
    { lat: 30.2740961200298, lng: -97.7609609676514 },
    { lat: 30.2726943527414, lng: -97.7609722402332 },
    { lat: 30.2741055043869, lng: -97.7609834577449 },
    { lat: 30.2028915780769, lng: -97.7610360575034 },
    { lat: 30.2774423290597, lng: -97.7610570354968 },
    { lat: 30.2808328601742, lng: -97.7612073098491 },
    { lat: 30.2828152008219, lng: -97.7612742169126 },
    { lat: 30.250948200038, lng: -97.7613053929741 },
    { lat: 30.2855417742521, lng: -97.7613072700157 },
    { lat: 30.2545150145085, lng: -97.7613633454382 },
    { lat: 30.2470470766745, lng: -97.7613752760925 },
    { lat: 30.2768047832711, lng: -97.7613763689494 },
    { lat: 30.2730743350978, lng: -97.7613837752927 },
    { lat: 30.2820798791386, lng: -97.7613985078873 },
    { lat: 30.2867476307752, lng: -97.7614078033424 },
    { lat: 30.2559736885639, lng: -97.7614600119803 },
    { lat: 30.2492287886809, lng: -97.7614708702504 },
    { lat: 30.27438378375, lng: -97.7614978690356 },
    { lat: 30.282113310014, lng: -97.7615098660674 },
    { lat: 30.2493021092843, lng: -97.7615107989186 },
    { lat: 30.2492065512826, lng: -97.7615610685504 },
    { lat: 30.2491900442359, lng: -97.7615716918979 },
    { lat: 30.2768283369787, lng: -97.7615778281929 },
    { lat: 30.2560258603675, lng: -97.7615781271075 },
    { lat: 30.279983604455, lng: -97.7615835162064 },
    { lat: 30.2437123695086, lng: -97.7616165853629 },
    { lat: 30.2880162124795, lng: -97.7616259913257 },
    { lat: 30.2810316809723, lng: -97.7616318678569 },
    { lat: 30.2622181918857, lng: -97.761670348923 },
    { lat: 30.2879790197002, lng: -97.7616742287347 },
    { lat: 30.2799457826666, lng: -97.7616823687222 },
    { lat: 30.2799457826666, lng: -97.7616823687222 },
    { lat: 30.2694428333082, lng: -97.7616927871013 },
    { lat: 30.2694558551694, lng: -97.7617203150393 },
    { lat: 30.2694631780245, lng: -97.7617356930329 },
    { lat: 30.2694650087384, lng: -97.7617395375313 },
    { lat: 30.2694650087384, lng: -97.7617395375313 },
    { lat: 30.2694668394521, lng: -97.7617433820296 },
    { lat: 30.2694705008797, lng: -97.7617510710264 },
    { lat: 30.2546853731819, lng: -97.7617524600746 },
    { lat: 30.2554363688876, lng: -97.7617781052445 },
    { lat: 30.2554157027462, lng: -97.7617903396009 },
    { lat: 30.2510786978443, lng: -97.7618155748592 },
    { lat: 30.2650425132633, lng: -97.7618213493544 },
    { lat: 30.2510837068068, lng: -97.7618250918866 },
    { lat: 30.2509495408461, lng: -97.7618618999518 },
    { lat: 30.278791194279, lng: -97.7618738935604 },
    { lat: 30.2847588665708, lng: -97.7618827971182 },
    { lat: 30.2555092068325, lng: -97.7618839860155 },
    { lat: 30.2801068955372, lng: -97.7618904978845 },
    { lat: 30.2778635573772, lng: -97.761933691939 },
    { lat: 30.2744969153352, lng: -97.7619464224287 },
    { lat: 30.274648329906, lng: -97.7620132937374 },
    { lat: 30.2726548470878, lng: -97.7620235350505 },
    { lat: 30.2746526164046, lng: -97.7620245457947 },
    { lat: 30.2745526301204, lng: -97.7620908495362 },
    { lat: 30.2745572023856, lng: -97.7621028517307 },
    { lat: 30.2871896401378, lng: -97.7621427118851 },
    { lat: 30.2502673882161, lng: -97.7621661283033 },
    { lat: 30.2458306253218, lng: -97.7622254216391 },
    { lat: 30.2548898467451, lng: -97.7622530560472 },
    { lat: 30.2564176080109, lng: -97.7623402211284 },
    { lat: 30.2851856331767, lng: -97.7624868933317 },
    { lat: 30.2735694483087, lng: -97.7625896805328 },
    { lat: 30.27499755795, lng: -97.7626062945204 },
    { lat: 30.274824783705, lng: -97.7626408687302 },
    { lat: 30.2576833935046, lng: -97.7626499371692 },
    { lat: 30.2864534588682, lng: -97.7626568742714 },
    { lat: 30.2851594900874, lng: -97.7626714193198 },
    { lat: 30.2735260986085, lng: -97.7626785709179 },
    { lat: 30.2576370339022, lng: -97.762681057014 },
    { lat: 30.263096524715, lng: -97.7627226089023 },
    { lat: 30.2720184774485, lng: -97.7627704133536 },
    { lat: 30.2826079738596, lng: -97.7628219397443 },
    { lat: 30.2748496456825, lng: -97.7628274817313 },
    { lat: 30.2496019567402, lng: -97.7628709122389 },
    { lat: 30.2748808013798, lng: -97.7628916624582 },
    { lat: 30.2648869175274, lng: -97.7629291973423 },
    { lat: 30.2468030621657, lng: -97.7631602355261 },
    { lat: 30.2816909716812, lng: -97.7633144842055 },
    { lat: 30.2738335728926, lng: -97.763380329369 },
    { lat: 30.2738775145072, lng: -97.7634385176709 },
    { lat: 30.2739214561217, lng: -97.7634967059729 },
    { lat: 30.273943426929, lng: -97.7635258001238 },
    { lat: 30.2740532809654, lng: -97.7636712708788 },
    { lat: 30.2808144088907, lng: -97.7637080691782 },
    { lat: 30.2478035008385, lng: -97.7637968692411 },
    { lat: 30.247790294621, lng: -97.7638054612384 },
    { lat: 30.2454229607873, lng: -97.763817721297 },
    { lat: 30.2757780640301, lng: -97.7641270342972 },
    { lat: 30.264213633361, lng: -97.7641501355878 },
    { lat: 30.254922806258, lng: -97.7642333905563 },
    { lat: 30.2642826413243, lng: -97.7642414982311 },
    { lat: 30.275881799658, lng: -97.7643069512943 },
    { lat: 30.2440303912773, lng: -97.7644008598006 },
    { lat: 30.2721993153188, lng: -97.7644317149618 },
    { lat: 30.2517335335728, lng: -97.7645904023334 },
    { lat: 30.2725246383414, lng: -97.7647237977713 },
    { lat: 30.2725246383414, lng: -97.7647237977713 },
    { lat: 30.2725296369221, lng: -97.7647283137299 },
    { lat: 30.2725296369221, lng: -97.7647283137299 },
    { lat: 30.2484551766241, lng: -97.7647504979645 },
    { lat: 30.2725796227293, lng: -97.7647734733148 },
    { lat: 30.2514454448243, lng: -97.7648407651829 },
    { lat: 30.2748607837776, lng: -97.7648413681811 },
    { lat: 30.2727345787313, lng: -97.7649134680284 },
    { lat: 30.2748626720559, lng: -97.7649463264356 },
    { lat: 30.2815038890382, lng: -97.7649651392698 },
    { lat: 30.2751516257015, lng: -97.7651348482026 },
    { lat: 30.2729945049285, lng: -97.7651482978704 },
    { lat: 30.2794918409633, lng: -97.7651823416462 },
    { lat: 30.2443638484695, lng: -97.7652144244114 },
    { lat: 30.2731494609304, lng: -97.765288292584 },
    { lat: 30.2492794022263, lng: -97.76535103865 },
    { lat: 30.2469964424129, lng: -97.7653841638926 },
    { lat: 30.2732594297062, lng: -97.765387643671 },
    { lat: 30.2459442805619, lng: -97.7654336057777 },
    { lat: 30.2459634242299, lng: -97.7654429041292 },
    { lat: 30.2785895455926, lng: -97.7654876586678 },
    { lat: 30.2464302468733, lng: -97.7658306649885 },
    { lat: 30.2776776333112, lng: -97.7658372237224 },
    { lat: 30.2798523526345, lng: -97.7659619887456 },
    { lat: 30.2459118759919, lng: -97.7663716457517 },
    { lat: 30.2791131625904, lng: -97.7663935812694 },
    { lat: 30.2524658790212, lng: -97.7665448981316 },
    { lat: 30.2492524727409, lng: -97.7667390797319 },
    { lat: 30.2541639512463, lng: -97.7669573006577 },
    { lat: 30.2541639512463, lng: -97.7669573006577 },
    { lat: 30.2492333734982, lng: -97.7674021577811 },
  ];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  const mapRef = useRef();
  const previousHeading = useRef(null);

  // Fetch user's current location on component mount
  // Fetch user's current location and heading on component mount

  useEffect(() => {
    // Watch user's position with high frequency
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude, heading: newHeading } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });

        // Update heading only if significant change detected
        if (
          newHeading !== null &&
          Math.abs(newHeading - previousHeading.current) > 2
        ) {
          setHeading(newHeading);
          previousHeading.current = newHeading;
        }
      },
      (error) => console.error("Error getting user location:", error),
      { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 } // Low latency settings
    );

    // Continuously update heading with device orientation
    const handleDeviceOrientation = (event) => {
      const { alpha } = event;
      if (alpha !== null) setHeading(alpha); // Keep heading updated smoothly
    };
    window.addEventListener("deviceorientation", handleDeviceOrientation);

    return () => {
      navigator.geolocation.clearWatch(watchId);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, []);

  const handlePlaceSelect = (details, type) => {
    if (type === "origin") {
      setOrigin(details); // Only set origin when a location is selected
    } else if (type === "destination") {
      setDestination(details);
    }

    if (origin && destination) {
      calculateRoute();
    }
  };

  const calculateRoute = () => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.WALKING,
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
          const route = result.routes[0].legs[0];
          setDistance(route.distance.text);
          setDuration(route.duration.text);
        }
      }
    );
  };

  const openGoogleMaps = () => {
    if (origin && destination) {
      const originQuery = `${origin.lat},${origin.lng}`;
      const destinationQuery = `${destination.lat},${destination.lng}`;
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${originQuery}&destination=${destinationQuery}&travelmode=driving`;
      window.open(googleMapsUrl, "_blank"); // Open Google Maps in a new tab
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div className="map-container">
      {/* Add a title at the top */}
      <h1 className="map-title">Let's get you from A to B the safest!</h1>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={15}
        ref={mapRef}
      >
        {coordinates.map((coordinate, index) => (
          <CircleF
            key={index}
            center={coordinate}
            radius={30} // Radius in meters, adjust as needed
            options={{
              fillColor: "#FFD700", // Circle color
              fillOpacity: 0.4,
              strokeColor: "#FFD700",
              strokeOpacity: 0.9,
              strokeWeight: 1,
            }}
          />
        ))}
        {/* Show a marker at the user's current location */}
        {center && (
          <MarkerF
            position={currentLocation}
            icon={{
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Arrow to show direction
              scale: 5,
              rotation: heading, // Rotate to match heading
              fillColor: "blue",
              fillOpacity: 0.5,
              strokeWeight: 2,
              strokeColor: "blue",
            }}
          />
        )}

        {origin && <MarkerF position={origin} />}
        {destination && <MarkerF position={destination} />}
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>

      <div className="search-container">
        <InputAutocomplete
          label="Origin"
          placeholder="Current Location"
          defaultLocation={currentLocation} // Pass the origin as a default location prop
          onPlaceSelected={(details) => handlePlaceSelect(details, "origin")}
        />
        <InputAutocomplete
          label="Destination"
          placeholder="enter destination"
          onPlaceSelected={(details) =>
            handlePlaceSelect(details, "destination")
          }
        />
        <button onClick={calculateRoute} className="map-button">
          Get Directions
        </button>
        {distance && duration && (
          <div>
            <p>Distance: {distance}</p>
            <p>Duration: {duration}</p>
          </div>
        )}

        {/* Add the Start Navigation button */}
        {directions && (
          <div>
            <button onClick={openGoogleMaps} className="navigation-button">
              Start Navigation
            </button>
            <p style={{ fontSize: "12px", color: "#555", marginTop: "5px" }}>
              Clicking "Start Navigation" will redirect you to Google Maps for navigation for now :)
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Map;

// src/LogoutConfirmation.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import "./LogoutConfirmation.css";

function LogoutConfirmation() {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login"); // Redirect to login page after logout
    });
  };

  const cancelLogout = () => {
    navigate("/"); // Redirect back to home or previous page
  };

  return (
    <div className="logout-confirmation">
      <h1>Are you sure you want to log out?</h1>
      <div className="logout-buttons">
        <button onClick={handleLogout}>Yes, Log Out</button>
        <button onClick={cancelLogout}>Cancel</button>
      </div>
    </div>
  );
}

export default LogoutConfirmation;
